import React from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Input, Select } from 'antd';
import styles from './StoreForm.module.css';

const { Option } = Select;

function StoreForm({ form, data = {} }) {
  const {
    name = null,
    address = null,
    city = null,
    state = null,
    zip = null,
    phone_1 = null,
    phone_2 = null
  } = data;
  const { getFieldDecorator } = form;

  return (
    <Form className={styles.form} layout="vertical">
      <Row>
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: name || ''
          })(<Input placeholder="Name" />)}
        </Form.Item>

        <Form.Item label="Address">
          {getFieldDecorator('address', {
            initialValue: address || ''
          })(<Input placeholder="Address" />)}
        </Form.Item>

        <Form.Item label="City">
          {getFieldDecorator('city', {
            initialValue: city || ''
          })(<Input placeholder="City" />)}
        </Form.Item>

        <Form.Item label="State">
          {getFieldDecorator('state', {
            initialValue: state || ''
          })(<Input placeholder="State" />)}
        </Form.Item>

        <Form.Item label="Zip">
          {getFieldDecorator('zip', {
            initialValue: zip || ''
          })(<Input placeholder="Zip" />)}
        </Form.Item>

        <Form.Item label="Phone 1">
          {getFieldDecorator('phone_1', {
            initialValue: phone_1 || ''
          })(<Input placeholder="Phone 1" />)}
        </Form.Item>

        <Form.Item label="Phone 2">
          {getFieldDecorator('phone_2', {
            initialValue: phone_2 || ''
          })(<Input placeholder="Phone 2" />)}
        </Form.Item>
      </Row>
    </Form>
  );
}

StoreForm.propTypes = {
  form: PropTypes.object,
  data: PropTypes.object
};

const WrappedStoreForm = Form.create({ name: 'StoreForm' })(StoreForm);
export default WrappedStoreForm;
