import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Form, DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import styles from './DispatchForm.module.css';

const { Option } = Select;
const { TextArea } = Input;

function DispatchForm({ form, data = {}, stores = [], trucks = [], drivers = [] }) {
  const { date = null, truck_id = null, driver_id = null, store_id = null } = data;
  const { getFieldDecorator } = form;
  const dateFormat = 'YYYY-MM-DD';
  const momentDate = date ? moment(date) : moment().add(1, 'day');
  const [storeId, setStoreId] = useState();

  // When editing we need to set storeId
  useEffect(() => {
    setStoreId(store_id);
  }, [store_id]);

  return (
    <Form className={styles.form} layout="vertical">
      <Row>
        <Form.Item label="Store">
          {getFieldDecorator('store_id', {
            initialValue: store_id || '',
            rules: [{ required: true }]
          })(
            <Select placeholder="Select Store" onChange={setStoreId}>
              {stores.map((s) => (
                <Option key={s.id} value={s.id}>
                  {s.id} - {s.city}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        {storeId && (
          <React.Fragment>
            <Form.Item label="Date">
              {getFieldDecorator('date', {
                initialValue: momentDate,
                rules: [{ required: true }]
              })(<DatePicker format={dateFormat} style={{ width: '100%' }} />)}
            </Form.Item>
          </React.Fragment>
        )}
      </Row>
    </Form>
  );
}

DispatchForm.propTypes = {
  form: PropTypes.object,
  data: PropTypes.object
};

const WrappedDispatchForm = Form.create({ name: 'DispatchForm' })(DispatchForm);
export default WrappedDispatchForm;
