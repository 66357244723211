import React from 'react';
import { Modal, Button } from 'antd';
import styles from './ResourceModal.module.css';

export default function ResourceModal({ title, visible, toggle, handleSubmit, children, width }) {
  return (
    <Modal title={title} visible={visible} onCancel={toggle} footer={null} width={width}>
      {children}
    </Modal>
  );
}
