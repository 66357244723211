import React from 'react';
import { Layout } from 'antd';
import Loader from '../Loader/Loader';
import styles from './Content.module.css';

const { Content } = Layout;

function ContentWrapper(props) {
  const { className, loading = false, loadingMessage, breadcrumb } = props;
  return (
    <Layout className={className}>
      <Loader tip={loadingMessage} display={loading} />
      <Content className={styles.content}>
        <div className={styles.breadcrumb}>{breadcrumb}</div>
        {props.children}
      </Content>
    </Layout>
  );
}

export default ContentWrapper;
