import React, { useState, useEffect } from 'react';
import {
  Input,
  Button,
  DatePicker,
  Select,
  Layout,
  PageHeader,
  Tooltip,
  Table,
  message
} from 'antd';
import { EditOutlined, CheckOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import axios from 'axios';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';

import ResourceModal from '../../components/ResourceModal/ResourceModal';
import TicketEditContainer from '../TicketEditModal/TicketEditContainer';

import { getTicketStatusTag } from '../../utils/constants';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Content } = Layout;

export default function InventoryListContainer(props) {
  const { handleOnClick = () => {} } = props;

  let name = 'Inventory';
  const path = '/inventories';
  const [state, attrs] = useResourceList(
    path,
    name,
    {},
    { columnKey: 'id', order: 'descend', active: 'Y' },
    { pageSize: 25 }
  );

  const { filters, setFilters } = attrs;
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [ticketStatuses, setTicketStatuses] = useState([]);
  const [classes, setClasses] = useState([]);

  // Load Statuses && Products
  useEffect(() => {
    async function fetchClasses() {
      const res = await attrs.fetch(`/inventories/classes`, {
        pageSize: 0,
        columnKey: 'id',
        order: 'ascend'
      });
      setClasses(res.data.records);
    }
    fetchClasses();
  }, []);

  const columns = [
    {
      title: 'Profit Center',
      dataIndex: 'profit_center',
      key: 'profit_center',
      filters: [
        {
          text: '0',
          value: 0
        },
        {
          text: '102',
          value: 102
        },
        {
          text: '104',
          value: 104
        },
        {
          text: '118',
          value: 118
        },
        {
          text: '119',
          value: 119
        },
        {
          text: '509',
          value: 509
        }
      ],
      filteredValue: attrs.filters.profit_center || null,
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'profit_center' && attrs.sortInfo.order
    },
    {
      title: 'Product',
      dataIndex: 'product.product',
      key: 'product',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Product"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('product', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('product', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('product', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.product || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'product' && attrs.sortInfo.order
    },
    {
      title: 'Description',
      dataIndex: 'product.description',
      key: 'description',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Description"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('description', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('description', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('description', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.description || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'description' && attrs.sortInfo.order
    },
    {
      title: 'Qty on Hand',
      dataIndex: 'qty_on_hand',
      key: 'qty_on_hand',
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'qty_on_hand' && attrs.sortInfo.order
    },
    {
      title: 'WAC',
      dataIndex: 'wac',
      key: 'wac',
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'wac' && attrs.sortInfo.order,
      render: (value) => Number(value).toFixed(2)
    },

    {
      title: 'Value On Hand',
      dataIndex: 'value_on_hand',
      key: 'value_on_hand',
      // sorter: true,
      // sortOrder: attrs.sortInfo.columnKey === 'value_on_hand' && attrs.sortInfo.order,
      render: (value) => Number(value).toFixed(2)
    },

    {
      title: 'Class',
      dataIndex: 'product.class',
      key: 'class',
      filters: classes.map((item) => ({ text: item.code, value: item.code })),
      filteredValue: attrs.filters.class || null,
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'class' && attrs.sortInfo.order
    },
    {
      title: 'Active',
      dataIndex: 'product.active',
      key: 'active',
      filters: [
        {
          text: 'Yes',
          value: 'Y'
        },
        {
          text: 'No',
          value: 'N'
        }
      ],
      filteredValue: attrs.filters.active || ['Y'],
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'active' && attrs.sortInfo.order,
      render: (value) => (value === 'Y' ? 'Yes' : 'No')
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  // Load Routes
  useEffect(() => {
    async function fetchRoutes() {
      const res = await attrs.fetch(`/routes`, {
        pageSize: 0,
        columnKey: 'name',
        order: 'ascend',
        store_id: attrs.filters.region || null
      });
      setRoutes(res.data.records);
    }
    fetchRoutes();
  }, [attrs.filters.region]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList({
      pagination: attrs.pagination,
      sorter: attrs.sortInfo,
      filters: attrs.filters
    });
  };

  const handleSearch = (key, selectedKeys, confirm) => {
    setFilters({ ...filters, [key]: selectedKeys });
    console.log('keys', selectedKeys);
    confirm();
  };

  const handleReset = (key, clearFilters) => {
    setFilters({ ...filters, [key]: null });
    clearFilters();
  };

  const [locationData, setLocationData] = useState({});

  const handleExpand = (expanded, row) => {
    async function fetchLocations() {
      const res = await attrs.fetch(`/inventories/locations`, {
        pageSize: 0,
        columnKey: 'id',
        order: 'ascend',
        profit_center: row.profit_center,
        product_link: row.product.link
      });
      setLocationData({ ...locationData, [row.id]: res.data.records });
    }

    fetchLocations();
  };

  const expandedRowRender = (row, index, indent, expanded) => {
    const columns = [
      { title: 'Location', dataIndex: 'location', key: 'location' },
      { title: 'Qty on Hand', dataIndex: 'qty', key: 'qty' }
    ];

    return (
      <Table
        style={{ margin: 15 }}
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={locationData[row.id]}
        pagination={false}
      />
    );
  };

  const data = state.data ? state.data : [];

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
        width="95%"
      >
        <TicketEditContainer
          handleCancel={toggleModal}
          id={resourceId}
          onSave={onModalSave}
          ticketStatuses={ticketStatuses}
        />
      </ResourceModal>

      <ResourceList
        columns={attrs.columns}
        setColumns={(value) => attrs.setColumns(value)}
        data={data}
        expandedRowRender={expandedRowRender}
        onExpand={handleExpand}
        onClick={handleOnClick}
        {...attrs}
      />
    </React.Fragment>
  );
}
