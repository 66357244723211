import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Input, InputNumber, Select, DatePicker } from 'antd';
import moment from 'moment';
import styles from './PurchaseOrderForm.module.css';

const { Option } = Select;
const { TextArea } = Input;

function PurchaseOrderForm({
  form,
  data = {},
  profitCenters = [],
  purchaseOrderStatuses = [],
  stores = []
}) {
  const {
    profit_center_id = 5,
    account_number = null,
    tank_id = null,
    customer_name = null,
    address_line_1 = null,
    address_line_2 = null,
    phone = null,
    email = null,
    notes = null,
    estimate_date = null,
    invoice_date = null,
    purchase_order_status_id = 1,
    store_id = null
  } = data;

  const { getFieldDecorator } = form;

  const dateFormat = 'YYYY-MM-DD';
  const estimateDate = estimate_date ? moment(estimate_date) : moment();
  const invoiceDate = estimate_date ? moment(estimate_date) : null;

  return (
    <Form className={styles.form} layout="vertical">
      <Row
        gutter={[16, 0]}
        style={{ marginBottom: 32, paddingBottom: 12, borderBottom: '1px solid #e8e8e8' }}
      >
        <Col span={12}>
          <Form.Item label="Profit Center">
            {getFieldDecorator('profit_center_id', {
              initialValue: profit_center_id || '',
              rules: [{ required: true }]
            })(
              <Select placeholder="Select Profit Center">
                {profitCenters.map((s) => (
                  <Option key={s.id} value={s.id}>
                    {s.code} - {s.title}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Customer Name">
            {getFieldDecorator('customer_name', {
              initialValue: customer_name || '',
              rules: [{ required: true }]
            })(<Input placeholder="Customer Name" />)}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Store">
            {getFieldDecorator('store_id', {
              initialValue: store_id || '',
              rules: [{ required: true }]
            })(
              <Select placeholder="Select Store">
                {stores.map((s) => (
                  <Option key={s.id} value={s.id}>
                    {s.id} - {s.city}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Status">
            {getFieldDecorator('purchase_order_status_id', {
              initialValue: purchase_order_status_id || '',
              rules: [{ required: true }]
            })(
              <Select placeholder="Select Status">
                {purchaseOrderStatuses.map((s) => (
                  <Option key={s.id} value={s.id}>
                    {s.title}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row
        gutter={[16, 0]}
        style={{ marginBottom: 32, paddingBottom: 12, borderBottom: '1px solid #e8e8e8' }}
      >
        <Col span={12}>
          <Form.Item label="Account Number">
            {getFieldDecorator('account_number', {
              initialValue: account_number || null,
              rules: [{ type: 'number', required: false }]
            })(<InputNumber style={{ width: '100%' }} placeholder="Account Number" />)}
          </Form.Item>

          <Form.Item label="Address Line 1">
            {getFieldDecorator('address_line_1', {
              initialValue: address_line_1 || '',
              rules: [{ required: false }]
            })(<Input placeholder="Address Line 1" />)}
          </Form.Item>

          <Form.Item label="Phone">
            {getFieldDecorator('phone', {
              initialValue: phone || '',
              rules: [{ required: false }]
            })(<Input placeholder="Phone" />)}
          </Form.Item>

          <Form.Item label="Estimate Date">
            {getFieldDecorator('estimate_date', {
              initialValue: estimateDate,
              rules: [{ required: false }]
            })(<DatePicker format={dateFormat} style={{ width: '100%' }} />)}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Site ID">
            {getFieldDecorator('tank_id', {
              initialValue: tank_id || null,
              rules: [{ required: false, type: 'integer' }]
            })(<InputNumber style={{ width: '100%' }} placeholder="Site ID" />)}
          </Form.Item>

          <Form.Item label="Address Line 2">
            {getFieldDecorator('address_line_2', {
              initialValue: address_line_2 || '',
              rules: [{ required: false }]
            })(<Input placeholder="Address Line 2" />)}
          </Form.Item>

          <Form.Item label="E-mail">
            {getFieldDecorator('email', {
              initialValue: email || '',
              rules: [{ required: false, type: 'email' }]
            })(<Input placeholder="E-mail" />)}
          </Form.Item>

          <Form.Item label="Invoice Date">
            {getFieldDecorator('invoice_date', {
              initialValue: invoiceDate,
              rules: [{ required: false }]
            })(<DatePicker format={dateFormat} style={{ width: '100%' }} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form.Item label="Notes">
          {getFieldDecorator('notes', {
            initialValue: notes || ''
          })(<TextArea rows={3} placeholder="Notes" />)}
        </Form.Item>
      </Row>
    </Form>
  );
}

PurchaseOrderForm.propTypes = {
  form: PropTypes.object,
  data: PropTypes.object
};

const WrappedPurchaseOrderForm = Form.create({ name: 'PurchaseOrderForm' })(PurchaseOrderForm);
export default WrappedPurchaseOrderForm;
