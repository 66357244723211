import React from 'react';
import { Page, View, Text } from '@react-pdf/renderer';
import moment from 'moment';
import { secondsToHoursMinutes } from '../../utils/units';
import { calculateTotalGallonsByProduct } from '../TripEdit/store/TripEditStore';
import styles from './TripPDFStyles';

const handleTotalGallonsByProduct = (items = [], products = []) => {
  const totalGallonsByProduct = calculateTotalGallonsByProduct(items);
  let stats = [];
  for (let [key, value] of Object.entries(totalGallonsByProduct)) {
    const product = products.find((product) => product.id == key);
    if (product) {
      stats.push(
        <React.Fragment key={key}>
          <Text style={[styles.h4, styles.paddingTop]}>{product.title}</Text>
          <Text>{value && value.toFixed(2)}</Text>
        </React.Fragment>
      );
    }
  }
  return stats;
};

const handleLocationGallonsByProduct = (totalGallonsByProduct = [], products = []) => {
  let stats = [];
  for (let [key, value] of Object.entries(totalGallonsByProduct)) {
    const product = products.find((product) => product.id == key);
    if (product) {
      stats.push(
        <View key={key} style={{ marginLeft: 10 }}>
          <Text style={[styles.bold]}>{product.title}</Text>
          <Text>{value && value.toFixed(2)}</Text>
        </View>
      );
    }
  }
  return stats;
};

const renderTickets = (items = [], products = []) => {
  return items.map((item, index) => {
    const { ticket = {}, location = {}, totalGallonsByProduct } = item;
    if (ticket) {
      const { site = {}, delivery_instructions_1, delivery_instructions_2 } = ticket;
      const { customer = {}, delivery_detail = {}, ar_customer = {} } = site;

      return (
        <View
          key={index}
          style={{
            flexDirection: 'row',
            paddingTop: 7,
            paddingBottom: 7,
            borderBottom: '1px solid #eee'
          }}
        >
          <Text style={[styles.padding, { width: '10%' }]}>{ticket && ticket.ticket_number}</Text>
          <Text style={[styles.padding, { width: '10%' }]}>
            {ticket ? ticket.account_number : location.title}
          </Text>
          <Text style={[styles.padding, { width: '5%' }]}>{ticket && ticket.tank_id}</Text>
          <View style={[styles.padding, { width: '25%', flexDirection: 'column' }]}>
            <Text>{customer ? `${customer.last_name}, ${customer.first_name}` : ''}</Text>
            <Text style={{ marginTop: 2 }}>
              {ar_customer &&
                `${ar_customer.terms} | ${Number(ar_customer.current_balance).toFixed(2)}`}{' '}
            </Text>
          </View>
          <View style={[styles.padding, { width: '30%', flexDirection: 'column' }]}>
            <Text>
              {delivery_detail &&
                `${delivery_detail.address_line_1}, ${delivery_detail.address_line_2}`}
            </Text>
            <Text style={{ marginTop: 2 }}>{delivery_instructions_1}</Text>
            <Text>{delivery_instructions_2}</Text>
          </View>
          <Text style={[styles.padding, { width: '10%' }]}>
            {ticket && ticket.delivery_gallons}
          </Text>
          <Text style={[styles.padding, { width: '5%' }]}>{ticket && ticket.fill && 'Fill'}</Text>
          <Text style={[styles.padding, { width: '10%' }]}>{ticket && ticket.site.route}</Text>
        </View>
      );
    } else {
      return (
        <View
          key={index}
          style={{
            flexDirection: 'row',
            paddingTop: 7,
            paddingBottom: 7,
            backgroundColor: '#eee'
          }}
        >
          <Text style={[styles.padding, { width: '5%' }]}></Text>
          <Text style={[styles.padding, styles.location, { width: '15%' }]}>{location.title}</Text>
          <View style={[styles.padding, { flexDirection: 'row', width: '80%' }]}>
            {handleLocationGallonsByProduct(totalGallonsByProduct, products)}
          </View>
        </View>
      );
    }
  });
};

export default function PDFDispatchOverview(props) {
  const { trip, products } = props;

  const {
    start_at,
    title,
    total_distance_in_miles,
    total_time_in_seconds,
    total_tickets,
    total_gallons,
    truck = {},
    driver = {},
    items = []
  } = trip;

  let legTicketItems = [];

  // Inject items with gallon totals
  const itemsWithGallons = items.map((item) => {
    if (item.type === 'location') {
      item.totalGallonsByProduct = calculateTotalGallonsByProduct(legTicketItems);
      legTicketItems = [];
    } else {
      legTicketItems.push(item);
    }
    return item;
  });

  const gallonsByProduct = handleTotalGallonsByProduct(items, products);

  return (
    <Page size="LETTER">
      <View
        style={{
          flexDirection: 'row'
        }}
      >
        <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
          <Text style={styles.h4}>Estimated Start</Text>
          <Text>{moment.unix(start_at).format('lll')}</Text>

          <Text style={[styles.h4, styles.paddingTop]}>Total Time</Text>
          <Text>-</Text>
          {/* <Text>{secondsToHoursMinutes(total_time_in_seconds)}</Text> */}

          {gallonsByProduct[0] && gallonsByProduct[0]}
        </View>

        <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
          <Text style={styles.h4}>Estimated End</Text>
          <Text>
            {moment
              .unix(start_at)
              .add(total_time_in_seconds, 'seconds')
              .format('lll')}
          </Text>

          <Text style={[styles.h4, styles.paddingTop]}>Total Distance</Text>
          <Text>
            {total_distance_in_miles} <Text style={styles.subtitle}> miles</Text>
          </Text>

          {gallonsByProduct[1] && gallonsByProduct[1]}
        </View>

        <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
          <Text style={styles.h4}>Truck</Text>
          <Text>
            {truck.description}
            <Text style={styles.subtitle}> {truck.code}</Text>
          </Text>

          <Text style={[styles.h4, styles.paddingTop]}>Total Tickets</Text>
          <Text>{total_tickets}</Text>

          {gallonsByProduct[2] && gallonsByProduct[2]}
        </View>

        <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
          <Text style={styles.h4}>Driver</Text>
          <Text>
            {driver.full_name}
            <Text style={styles.subtitle}> {driver.code}</Text>
          </Text>

          <Text style={[styles.h4, styles.paddingTop]}>Total Gallons</Text>
          <Text>{total_gallons}</Text>
          {gallonsByProduct[3] && gallonsByProduct[3]}
        </View>
      </View>

      <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
        <Text style={styles.h4}>Tickets</Text>
        <View
          style={{
            flexDirection: 'row',
            marginTop: 10
          }}
        >
          <Text style={[styles.h5, { width: '10%' }]}>Ticket</Text>
          <Text style={[styles.h5, { width: '10%' }]}>Account #</Text>
          <Text style={[styles.h5, { width: '5%' }]}>Tank</Text>
          <Text style={[styles.h5, { width: '25%' }]}>Name</Text>
          <Text style={[styles.h5, { width: '30%' }]}>Address</Text>
          <Text style={[styles.h5, { width: '10%' }]}>Gallons</Text>
          <Text style={[styles.h5, { width: '5%' }]}>Fill?</Text>
          <Text style={[styles.h5, { width: '10%' }]}>Route</Text>
        </View>

        {renderTickets(itemsWithGallons, products)}
      </View>
    </Page>
  );
}
