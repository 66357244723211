import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { handleError } from '../../utils/handleError';

import { Document, PDFViewer, Font, StyleSheet } from '@react-pdf/renderer';

import PurchaseOrderPDFInvoice from './PurchaseOrderPDFInvoice';

// Register font
Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf'
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600
    }
  ]
});

// Create styles
const styles = StyleSheet.create({
  document: {
    fontFamily: 'Open Sans',
    flexDirection: 'column',
    fontSize: 8
  }
});

export default function TripPDFContainer(props) {
  const purchaseOrderId = props.match.params.purchase_order_id;
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPurchaseOrder(purchaseOrderId);
  }, [purchaseOrderId]);

  const fetchPurchaseOrder = (id) => {
    setLoading(true);
    axios
      .get(`/purchase_orders/${id}`)
      .then((res) => {
        setPurchaseOrder(res.data);
        axios.get('/stores').then((res) => {
          setStores(res.data.records);
          setLoading(false);
        });
      })
      .catch((err) => handleError(err));
  };

  const store = stores.find((store) => store.id === Number(purchaseOrder.store_id));

  if (loading) return <h4 style={{ textAlign: 'center', marginTop: 20 }}>Generating PDF...</h4>;
  return (
    <PDFViewer
      width="100%"
      height="100%"
      style={{ bottom: 0, top: 0, left: 0, right: 0, position: 'absolute' }}
    >
      <Document style={styles.document}>
        <PurchaseOrderPDFInvoice purchaseOrder={purchaseOrder} store={store} />
        {/* <TripPDFOverviewPage trip={trip} products={products} />
        {items.map((item, index) => {
          if (item.ticket) {
            const { product, site } = item.ticket;
            const store = stores.find((store) => store.id === Number(site.region));

            if (product.code === '100') {
              return <TripPDFPropaneTicketPage key={index} item={item} store={store} />;
            } else {
              return <TripPDFTicketPage key={index} item={item} store={store} />;
            }
          }
        })} */}
      </Document>
    </PDFViewer>
  );
}
