import React, { cloneElement, useState, useEffect } from 'react';
import { Layout, PageHeader, Table, Checkbox, Button } from 'antd';
import pluralize from 'pluralize';
import Loader from '../Loader/Loader';
import styles from './ResourceList.module.css';
const { Content } = Layout;

export default function ResourceList({
  data = [],
  showHeader = true,
  name,
  extra,
  columns,
  setColumns,
  columnInfo,
  setColumnInfo,
  handleTableChange,
  handleClearAll,
  pagination,
  loading,
  hideColumns = true,
  hidePagination = false,
  expandedRowRender = null,
  onExpand = () => {},
  onClick = () => {},
  components
}) {
  const pluralName = name ? pluralize(name) : '';
  const [showHideColumns, setShowHideColumns] = useState(false);

  if (loading) return <Loader tip="Loading" />;

  const toggleShowHideColumns = () => {
    setShowHideColumns(!showHideColumns);
  };

  return (
    <Layout>
      <Content className={styles.content}>
        <PageHeader
          title={pluralName}
          subTitle={
            <>
              <span style={{ paddingRight: 7 }}>({pagination.total} records)</span>
              {hideColumns && setColumns && columns && (
                <Button size="small" onClick={() => toggleShowHideColumns()}>
                  Manage Columns
                </Button>
              )}
              {handleClearAll && (
                <Button
                  size="small"
                  style={{ marginLeft: 7 }}
                  onClick={() => {
                    handleClearAll();
                  }}
                >
                  Reset
                </Button>
              )}
            </>
          }
          extra={extra}
        />

        {showHideColumns && hideColumns && setColumns && columns && (
          <div
            style={{
              marginLeft: 17,
              marginBottom: 20,
              borderBottom: '1px solid #e8e8e8',
              backgroundColor: '#fafafa',
              padding: 10,
              marginRight: 15
            }}
          >
            {columnInfo.map((col, index) => {
              return (
                <Checkbox
                  style={{
                    paddingBottom: 7,
                    paddingTop: 7,
                    paddingLeft: 0,
                    marginLeft: 0,
                    marginRight: 12
                  }}
                  checked={col.visible !== false}
                  onChange={(e) => {
                    columnInfo[index].visible = e.target.checked;
                    // // setColumnState([...columnState]);
                    setColumnInfo([...columnInfo]);
                  }}
                >
                  {col.title}
                </Checkbox>
              );
            })}
          </div>
        )}

        <div className={styles.main}>
          <Table
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => {
            //       console.log('click', record);
            //       onClick(record);
            //     }, // click row
            //     onDoubleClick: (event) => {
            //       console.log('doubleclick', record);
            //     }, // double click row
            //     onContextMenu: (event) => {
            //       event.preventDefault();
            //       console.log('rightclick', record);
            //     } // right button click row
            //   };
            // }}
            scroll={{ x: true }}
            size="default"
            showHeader={showHeader}
            columns={
              columnInfo
                ? columns.filter(
                    (col) =>
                      columnInfo.find((colInfo) => colInfo.title === col.title).visible !== false
                  )
                : columns // hande trip show use case with no columnInfo
            }
            dataSource={data}
            rowKey="id"
            onChange={handleTableChange}
            pagination={hidePagination ? false : pagination}
            // expandedRowRender={expandedRowRender}
            onExpand={onExpand}
            components={components}
          />
        </div>
      </Content>
    </Layout>
  );
}
