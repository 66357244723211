import React, { useContext } from 'react';
import { Layout } from 'antd';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { setAuthorization } from '../../utils/setHeaders';
import UserContext from '../../UserContext';
import LoginForm from './LoginForm';
import './Login.css';

const { Content } = Layout;

const Login = (props) => {
  const { user, setUser } = useContext(UserContext);
  // data = { email, password }
  const handleLogin = (data, redirect = 'dispatches') => {
    return axios
      .post('/users/login', data)
      .then((res) => {
        const token = res.data.token;
        localStorage.setItem('jwtToken', token);
        setAuthorization(token);
        setUser(jwtDecode(token)); // Decode the token to get user data
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.log('Error', err, user);
        return Promise.reject(err);
      });
  };

  return (
    <Layout className="Login">
      <Layout style={{ padding: '0 0 0 0' }}>
        <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 100
          }}
        >
          <div style={{ margin: '0 auto', width: '300px' }}>
            <LoginForm login={handleLogin} history={props.history} />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Login;
