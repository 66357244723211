import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { message, Row, Col, Form, Input, Button, Select, Switch, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import DivIcon from '../../components/DivIcon/DivIcon';
import Body from '../../components/Body/Body';
import Content from '../../components/Content/Content';

import Loader from '../../components/Loader/Loader';
import useResourceEdit from '../../hooks/useResourceEdit';
import ResourceFormActions from '../../components/ResourceFormActions/ResourceFormActions';
import StoreForm from '../../components/forms/StoreForm/StoreForm';
import ReactMap from '../../components/ReactMap/ReactMap';

import Ticket from '../../components/Ticket/Ticket';
import { SET_TRIP_ID } from '../TripEdit/store/TripEditStore';

const { TextArea } = Input;
const { Option } = Select;

export default function TicketEditContainer({
  id,
  handleCancel = () => {},
  onSave = () => {},
  ticketStatuses = []
}) {
  const [state, attrs] = useResourceEdit({ path: '/tickets', name: 'Ticket', id });
  const { loading, data = {} } = state;
  const { handleSubmit, setForm } = attrs;
  const {
    account_number,
    ticket_number,
    delivery_date,
    ticket_status_id,
    site = {},
    estimated_gallons,
    fill,
    trip_id
  } = data;

  const [ticketStatusId, setTicketStatusId] = useState();
  const [validated, setValidated] = useState(false);
  const [notes, setNotes] = useState();
  const [estimatedGallons, setEstimatedGallons] = useState();
  const [deliveryDate, setDeliveryDate] = useState();
  const [removeFromTrip, setRemoveFromTrip] = useState(false);

  useEffect(() => {
    ticket_status_id && setTicketStatusId(ticket_status_id);
    if (data) {
      setValidated(data.validated);
      setNotes(data.notes);
      setEstimatedGallons(null);
      setDeliveryDate(null);
      setRemoveFromTrip(false);
    }
  }, [data]);

  const handleSave = async () => {
    const data = {
      ticket_status_id: ticketStatusId,
      validated,
      notes
    };

    if (removeFromTrip === true) {
      await axios.patch(`/trips/${trip_id}/tickets/${id}/remove`, data);
    }

    if (estimatedGallons) {
      data.estimated_gallons = estimatedGallons;
    }

    if (deliveryDate) {
      data.factor_delv_date = deliveryDate;
    }

    axios.patch(`/tickets/${id}`, data).then((res) => {
      message.success(`Ticket successfully updated`);
      onSave();
    });
  };

  if (loading) {
    return <Loader display={true} />;
  }

  return (
    <React.Fragment>
      <Body>
        <Ticket data={data} style={{}} />
        <Row style={{ marginTop: 22 }} gutter={[64, 16]}>
          <Col span={12}>
            <Form.Item label="Ticket Status">
              <Select
                defaultValue={ticket_status_id}
                style={{ width: '100%' }}
                onChange={setTicketStatusId}
              >
                {ticketStatuses.map((status) => (
                  <Option key={status.id} value={status.id}>
                    {status.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Validated">
              <Select defaultValue={validated} style={{ width: '100%' }} onChange={setValidated}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>

            {trip_id && (
              <Form.Item>
                <Switch onChange={setRemoveFromTrip} /> Remove ticket from trip{' '}
                <Link target="_blank" to={`/dispatches/0/trips/${trip_id}/edit`}>
                  [{trip_id}]
                </Link>
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Delivery Date -{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {delivery_date
                      ? moment.unix(delivery_date).format('dddd, MMMM Do YYYY ')
                      : 'Not Set'}
                  </span>
                </span>
              }
            >
              <DatePicker
                style={{ width: '100%' }}
                onChange={(value) => setDeliveryDate(moment(value).format('MM/DD/YYYY'))}
              />
            </Form.Item>

            <Form.Item label="Ticket Notes">
              <TextArea
                defaultValue={notes}
                rows={3}
                style={{ width: '100%' }}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Item>

            {estimated_gallons && fill && (
              <Form.Item
                label={
                  <span>
                    Estimated Gallons -{' '}
                    <span style={{ fontWeight: 'bold' }}>{estimated_gallons}</span>
                  </span>
                }
              >
                <Input
                  defaultValue={estimatedGallons}
                  style={{ width: '100%' }}
                  onChange={(e) => setEstimatedGallons(e.target.value)}
                />
              </Form.Item>
            )}

            {/* <Form.Item label="Latitude">
            <Input
              placeholder="Latitude"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Longitude">
            <Input
              placeholder="Longitude"
              value={longitude}
              onChange={(e) => setLatitude(e.target.value)}
            />
          </Form.Item> */}

            {/* <Button>Use My Location</Button> */}
          </Col>
        </Row>
        <ResourceFormActions id={id} handleCancel={handleCancel} handleSubmit={handleSave} />
      </Body>
    </React.Fragment>
  );
}
