import React, { useState, useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, CarOutlined, ProfileOutlined } from '@ant-design/icons';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import TruckCompartmentEditContainer from '../TruckCompartmentEdit/TruckCompartmentEditContainer';

export default function TruckCompartmentListContainer({ truckId }) {
  let name = 'Compartment';
  const path = `/truck_compartments`;
  const parentParams = { truck_id: truckId };
  const [state, attrs] = useResourceList(path, name, parentParams);
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);

  const columns = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
      sorter: (a, b) => a.number - b.number,
      sortOrder: attrs.sortInfo.columnKey === 'number' && attrs.sortInfo.order
    },
    {
      title: 'Capacity In Gallons',
      dataIndex: 'capacity_in_gallons',
      key: 'capacity_in_gallons',
      sorter: (a, b) => a.capacity_in_gallons - b.capacity_in_gallons,
      sortOrder: attrs.sortInfo.columnKey === 'capacity_in_gallons' && attrs.sortInfo.order
    },
    {
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList();
  };

  const data = state.data
    ? state.data.map((item) => ({
        ...item,
        actions: (
          <div>
            <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
              <EditOutlined />
            </a>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={attrs.removeResource.bind(this, item.id)}
            >
              <a href="#!">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </div>
        )
      }))
    : [];

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
      >
        <TruckCompartmentEditContainer
          handleCancel={toggleModal}
          id={resourceId}
          onSave={onModalSave}
          truckId={truckId}
        />
      </ResourceModal>

      <ResourceList
        data={data}
        extra={[
          <Button key="1" type="primary" onClick={toggleModal}>
            Create {name}
          </Button>
        ]}
        {...attrs}
      />
    </React.Fragment>
  );
}
