import React, { useState } from 'react';
import { Layout } from 'antd';
import jwtDecode from 'jwt-decode';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import UserContext from './UserContext';
import { setAuthorization } from './utils/setHeaders';

import requireAuth from './utils/require-auth';
import disableAuth from './utils/disable-auth';

// Components
import Header from './components/Header';

// Containers
import Login from './containers/Login/Login';
import DashboardContainer from './containers/Dashboard/DashboardContainer';
import DriverListContainer from './containers/DriverList/DriverListContainer';
import StoreListContainer from './containers/StoreList/StoreListContainer';
import FuelDiscountListContainer from './containers/FuelDiscountList/FuelDiscountListContainer';
import FuelDiscountRedemptionListContainer from './containers/FuelDiscountRedemptionList/FuelDiscountRedemptionListContainer';

import PurchaseOrderListContainer from './containers/PurchaseOrderList/PurchaseOrderListContainer';
import PurchaseOrderShowContainer from './containers/PurchaseOrderShow/PurchaseOrderShowContainer';
import PurchaseOrderPDF from './containers/PurchaseOrderPDF/PurchaseOrderPDFContainer';

import TruckListContainer from './containers/TruckList/TruckListContainer';
import TruckShowContainer from './containers/TruckShow/TruckShowContainer';

import SiteListContainer from './containers/SiteList/SiteListContainer';
import SiteEditContainer from './containers/SiteEdit/SiteEditContainer';

import TicketListContainer from './containers/TicketList/TicketListContainer';
import InventoryListContainer from './containers/InventoryList/InventoryListContainer';
import CustomerListContainer from './containers/CustomerList/CustomerListContainer';

import TripEditContainer from './containers/TripEdit/TripEditContainer';
import TripShowContainer from './containers/TripShow/TripShowContainer';
import TripPDFContainer from './containers/TripPDF/TripPDFContainer';
import TripListContainer from './containers/TripList/TripListContainer';

import DispatchListContainer from './containers/DispatchList/DispatchListContainer';
import DispatchShowContainer from './containers/DispatchShow/DispatchShowContainer';
import DispatchShowMapContainer from './containers/DispatchShowMap/DispatchShowMapContainer';

import SalesSiteListContainer from './containers/SalesSiteList/SalesSiteListContainer';

import EquipmentListContainer from './containers/EquipmentList/EquipmentListContainer';
import EquipmentShowContainer from './containers/EquipmentShow/EquipmentShowContainer';

import EquipmentLeaseListContainer from './containers/EquipmentLeaseList/EquipmentLeaseListContainer';
import EquipmentLeaseShowContainer from './containers/EquipmentLeaseShow/EquipmentLeaseShowContainer';

import ManufacturerListContainer from './containers/ManufacturerList/ManufacturerListContainer';

import styles from './App.module.css';

function App() {
  const [user, setUser] = useState(null);

  // Init Authorization based on jwt
  if (localStorage.jwtToken && !user) {
    setAuthorization(localStorage.jwtToken);
    setUser(jwtDecode(localStorage.jwtToken));
  }

  return (
    <Router>
      <div className={styles.app}>
        <Layout>
          <UserContext.Provider value={{ user, setUser }}>
            <Header />
            <Switch>
              <Route exact path="/login" component={disableAuth(user, Login)} />
              <Route exact path="/drivers" component={requireAuth(user, DriverListContainer)} />
              <Route exact path="/stores" component={requireAuth(user, StoreListContainer)} />
              <Route exact path="/trucks" component={requireAuth(user, TruckListContainer)} />
              <Route
                exact
                path="/purchase_orders"
                component={requireAuth(user, PurchaseOrderListContainer)}
              />
              <Route
                exact
                path="/purchase_orders/:purchase_order_id"
                component={requireAuth(user, PurchaseOrderShowContainer)}
              />
              <Route
                path="/purchase_orders/:purchase_order_id/pdf"
                component={requireAuth(user, PurchaseOrderPDF)}
              />
              <Route
                exact
                path="/trucks/:truck_id"
                component={requireAuth(user, TruckShowContainer)}
              />
              <Route
                exact
                path="/fuel_discounts"
                component={requireAuth(user, FuelDiscountListContainer)}
              />
              <Route
                exact
                path="/fuel_discount_redemptions"
                component={requireAuth(user, FuelDiscountRedemptionListContainer)}
              />
              <Route exact path="/tickets" component={requireAuth(user, TicketListContainer)} />
              <Route
                exact
                path="/tickets/:ticket_id/edit"
                component={requireAuth(user, TicketListContainer)}
              />
              <Route
                exact
                path="/dispatches"
                component={requireAuth(user, DispatchListContainer)}
              />
              <Route
                exact
                path="/dispatches/:dispatch_id"
                component={requireAuth(user, DispatchShowContainer)}
              />
              <Route
                exact
                path="/dispatches/:dispatch_id/map"
                component={requireAuth(user, DispatchShowMapContainer)}
              />
              <Route
                path="/dispatches/:dispatch_id/trips/:trip_id/edit"
                component={requireAuth(user, TripEditContainer)}
              />
              <Route
                path="/dispatches/:dispatch_id/trips/new"
                component={requireAuth(user, TripEditContainer)}
              />
              <Route
                path="/dispatches/:dispatch_id/trips/:trip_id/pdf"
                component={requireAuth(user, TripPDFContainer)}
              />
              <Route
                path="/dispatches/:dispatch_id/trips/:trip_id"
                component={requireAuth(user, TripShowContainer)}
              />
              <Route path="/sites/:site_id/edit" component={requireAuth(user, SiteEditContainer)} />
              <Route path="/sites" component={requireAuth(user, SiteListContainer)} />
              <Route path="/inventories" component={requireAuth(user, InventoryListContainer)} />
              <Route path="/customers" component={requireAuth(user, CustomerListContainer)} />
              <Route path="/sales_sites" component={requireAuth(user, SalesSiteListContainer)} />

              <Route
                exact
                path="/equipments/:equipment_id"
                component={requireAuth(user, EquipmentShowContainer)}
              />
              <Route path="/equipments" component={requireAuth(user, EquipmentListContainer)} />

              <Route
                exact
                path="/equipment_leases/:equipment_lease_id"
                component={requireAuth(user, EquipmentLeaseShowContainer)}
              />

              <Route
                path="/equipment_leases"
                component={requireAuth(user, EquipmentLeaseListContainer)}
              />

              <Route
                exact
                path="/manufacturers"
                component={requireAuth(user, ManufacturerListContainer)}
              />

              <Route default component={requireAuth(user, DashboardContainer)} />
            </Switch>
          </UserContext.Provider>
        </Layout>
      </div>
    </Router>
  );
}

export default App;
