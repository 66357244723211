import React from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Input, DatePicker } from 'antd';
import styles from './SalesSiteForm.module.css';
import moment from 'moment';

function SalesSiteForm({ form, data = {} }) {
  const { salesman = 'vstraub', account_number = null, tank_id = null, start_at = null } = data;
  const { getFieldDecorator } = form;
  const dateFormat = 'YYYY-MM-DD';

  return (
    <Form className={styles.form} layout="vertical">
      <Row>
        <Form.Item label="Salesman">
          {getFieldDecorator('salesman', {
            initialValue: salesman || '',
            rules: [{ required: true }]
          })(<Input placeholder="Salesman" />)}
        </Form.Item>

        <Form.Item label="Account Number">
          {getFieldDecorator('account_number', {
            initialValue: account_number || '',
            rules: [{ required: true }]
          })(<Input placeholder="Account Number" />)}
        </Form.Item>

        <Form.Item label="Site ID">
          {getFieldDecorator('tank_id', {
            initialValue: tank_id || '',
            rules: [{ required: true }]
          })(<Input placeholder="Site ID" />)}
        </Form.Item>

        <Form.Item label="Start At">
          {getFieldDecorator('start_at', {
            initialValue: start_at ? moment(start_at) : null,
            rules: [{ required: false }]
          })(<DatePicker format={dateFormat} style={{ width: '100%' }} />)}
        </Form.Item>
      </Row>
    </Form>
  );
}

SalesSiteForm.propTypes = {
  drivers: PropTypes.array,
  trucks: PropTypes.array,
  dispatch: PropTypes.object
};

const WrappedSalesSiteForm = Form.create({ name: 'SalesSiteForm' })(SalesSiteForm);
export default WrappedSalesSiteForm;
