import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Body from '../../components/Body/Body';
import Content from '../../components/Content/Content';
import Stat from '../../components/Stat/Stat';
import { PageHeader, Breadcrumb, Row, Col, Statistic, Card, Button } from 'antd';
import {
  ProfileOutlined,
  EditOutlined,
  DeleteOutlined,
  PrinterOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { handleError } from '../../utils/handleError';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import EquipmentLeaseListContainer from '../EquipmentLeaseList/EquipmentLeaseListContainer';
import EquipmentEditContainer from '../EquipmentEdit/EquipmentEditContainer';

import styles from './EquipmentShowContainer.module.css';

export default function EquipmentShowContainer(props) {
  let name = 'Equipment';
  const equipmentId = props.match.params.equipment_id;
  const [equipment, setEquipment] = useState({});
  const [displayModal, setDisplayModal] = useState(false);
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [loading, setLoading] = useState(false);
  const {
    serial,
    model,
    description,
    manufacturer_id,
    purchased_at,
    purchase_cost,
    capacity,
    inspection_next_at,
    inspection_interval,
    profit_center_code,
    location_id,
    manufactured_at,
    is_invoice_processed,
    invoice_reference,
    leases
  } = equipment;

  useEffect(() => {
    fetchEquipment(equipmentId);
  }, [equipmentId]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    fetchEquipment(equipmentId);
  };

  const fetchEquipment = (id) => {
    setLoading(true);
    axios
      .get(`/equipments/${id}`)
      .then((res) => {
        setEquipment(res.data);
        setLoading(false);
      })
      .catch((err) => handleError(err));
  };

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/equipments">Equipment</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Equipment #{equipmentId}</Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
        width="75%"
      >
        <EquipmentEditContainer handleCancel={toggleModal} id={resourceId} onSave={onModalSave} />
      </ResourceModal>

      <Content className={styles.header} breadcrumb={breadcrumb}>
        <PageHeader />
        <Body>
          <Row gutter={[64]}>
            <Col span={10}>
              <Card title={`Equipment #${equipmentId}`} bordered={false}>
                <h4>Serial:</h4>
                <p>{serial}</p>

                <h4>Model:</h4>
                <p>{model}</p>

                <h4>Description:</h4>
                <p>{description}</p>

                <h4>Capacity:</h4>
                <p>{capacity}</p>
              </Card>
            </Col>
            <Col span={10}>
              <Card title="Purchase Details" bordered={false}>
                <h4>Profit Center Code:</h4>
                <p>{profit_center_code}</p>

                <h4>Purchased On:</h4>
                <p>{moment(purchased_at).format('YYYY-MM-DD')}</p>

                <h4>Purchase Cost:</h4>
                <p>{purchase_cost}</p>
              </Card>
            </Col>
            <Col span={4}>
              <Card title="Actions" bordered={false}>
                <p>
                  <Button>
                    <a href="#!" onClick={toggleModal.bind(this, null, equipmentId)}>
                      <EditOutlined /> Edit
                    </a>
                  </Button>
                </p>

                <p>
                  <Button>
                    <Link to={`/equipments`}>
                      <ArrowLeftOutlined /> Back
                    </Link>
                  </Button>
                </p>
              </Card>
            </Col>
          </Row>

          <Row gutter={[64]}>
            <Col span={10}>
              <Card title={`Inspection Details`} bordered={false}>
                <h4>Manufacturer:</h4>
                <p>{manufacturer_id}</p>

                <h4>Manufactured On:</h4>
                <p>{manufactured_at && moment(manufactured_at).format('YYYY-MM-DD')}</p>

                <h4>Inspection Interval:</h4>
                <p>{inspection_interval}</p>

                <h4>Next Inspection Due On:</h4>
                <p>{inspection_next_at && moment(inspection_next_at).format('YYYY-MM-DD')}</p>
              </Card>
            </Col>
            <Col span={10}>
              <Card title={`Inventory`} bordered={false}>
                <h4>Invoice Processed:</h4>
                <p>{is_invoice_processed}</p>

                <h4>Invoice Reference:</h4>
                <p>{invoice_reference}</p>
              </Card>
            </Col>
            <Col span={4}></Col>
          </Row>
        </Body>
      </Content>
      <EquipmentLeaseListContainer equipment={equipment} equipmentId={equipmentId} />
    </React.Fragment>
  );
}
