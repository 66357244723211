import React, { useRef, useEffect } from 'react';
import { Map, Marker, Popup, TileLayer, LayersControl } from 'react-leaflet';
import '@geoman-io/leaflet-geoman-free';
import mapLayers, { googleStreet } from './mapLayers';
import { reducer } from '../../containers/TripEdit/store/TripEditStore';

function ReactMap(props) {
  const { center, zoom = 12, defaultTile = googleStreet, height = 200 } = props;
  const map = useRef(null);

  useEffect(() => {
    const leafletMap = map.current.leafletElement;
    leafletMap.pm.addControls({
      position: 'topleft',
      drawMarker: false,
      drawCircle: false,
      drawCircleMarker: true,
      drawPolyline: false,
      editMode: false,
      dragMode: false,
      drawRectangle: false,
      drawPolygon: false,
      cutPolygon: false,
      removalMode: false
    });
    leafletMap.on('pm:create', (e) => {
      let newFeature = e.layer.toGeoJSON();
      console.log('new', newFeature);
    });
  }, []);

  return (
    <Map
      ref={(ref) => {
        map.current = ref;
      }}
      style={{ width: '100%', height }}
      center={center}
      zoom={zoom}
    >
      <TileLayer url={defaultTile.url} subdomains={defaultTile.subdomains} />
      <LayersControl position="bottomright">
        {mapLayers.map((layer) => {
          return (
            <LayersControl.BaseLayer name={layer.name} key={layer.name}>
              <TileLayer url={layer.url} subdomains={layer.subdomains} />
            </LayersControl.BaseLayer>
          );
        })}
      </LayersControl>
      {props.children}
    </Map>
  );
}

export default ReactMap;
