import {
  DISPATCH_LIST_SET_DISPATCHES,
  DISPATCH_LIST_SET_PAGINATION,
  DISPATCH_LIST_REMOVE_DISPATCH
} from './types';

const initialState = {
  loading: false,
  dispatches: [],
  pagination: {
    total: 0,
    current: 1,
    pageSize: 10
  }
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DISPATCH_LIST_SET_DISPATCHES:
      console.log('DISPATCH_LIST_SET_DISPATCHES', state, payload);
      return {
        ...state,
        dispatches: payload
      };
    case DISPATCH_LIST_REMOVE_DISPATCH:
      return {
        ...state,
        dispatches: state.dispatches.filter((d) => d !== payload)
      };
    case DISPATCH_LIST_SET_PAGINATION:
      return {
        ...state,
        pagination: payload
      };
    default:
      return state;
  }
};
