import React from 'react';
import moment from 'moment';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import styles from './PurchaseOrderPDFStyles';
import logo from './SAC-Energy-Outlined-white-rotated-dark.png';

const renderItems = (items) => {
  // Ensure we have an array for reduce functions
  if (items.length > 0) {
    const groupTotal = items.reduce((total, item) => {
      if (item.group_as_misc) {
        return total + Number(item.price * item.qty);
      } else {
        return total + 0;
      }
    }, 0);

    const lineItems = items.filter((item) => !item.group_as_misc);

    // If group total exists then add Misc line item
    if (groupTotal > 0) {
      lineItems.push({
        inv_product: 'MISC',
        description: 'MISC HARDWARE',
        qty: 1,
        price: groupTotal
      });
    }

    return lineItems.map((item, index) => {
      return (
        <View
          key={index}
          style={{
            flexDirection: 'row',
            marginTop: 5
          }}
        >
          <Text style={[{ flexGrow: 1, width: '20%' }]}>{item.inv_product}</Text>
          <Text style={[{ flexGrow: 1, width: '40%' }]}>{item.description}</Text>
          <Text style={[{ flexGrow: 1, width: '5%' }]}>{item.qty}</Text>
          <Text style={[{ flexGrow: 1, width: '15%', textAlign: 'right' }]}>
            {Number(item.price).toFixed(2)}
          </Text>
          <Text style={[{ flexGrow: 1, width: '20%', textAlign: 'right' }]}>
            {(Number(item.price) * Number(item.qty)).toFixed(2)}
          </Text>
        </View>
      );
    });
  }
};

function TripPDFPropaneTicketPage({ purchaseOrder, store = {} }) {
  if (!purchaseOrder) return <React.Fragment></React.Fragment>;

  const {
    id,
    profit_center,
    account_number,
    tank_id,
    customer_name,
    address_line_1,
    address_line_2,
    phone,
    email,
    notes,
    status,
    estimate_date,
    invoice_date,
    total_price,
    total_tax,
    items = []
  } = purchaseOrder;

  const { name, address, city, state, zip, phone_1 } = store;

  const statusId = status && status.id === 1 ? 1 : 2;

  const subtotal = items.reduce((total, item) => Number(item.price * item.qty) + total, 0);
  const taxTotal = items.reduce((total, item) => {
    return total + (item.taxable ? Number(item.price * item.qty) * 0.06 : 0);
  }, 0);

  return (
    <Page size="LETTER" style={styles.page}>
      <View
        style={{
          flexDirection: 'row',
          marginLeft: 10,
          marginRight: 10
        }}
      >
        <View style={styles.section}>
          <Image style={styles.logo} src={logo} />
        </View>
        <View style={[styles.section]}>
          <View style={styles.right}>
            <Text style={styles.right}>
              <Text style={styles.bold}>{name}</Text> | {address} | {city}, {state} {zip}
            </Text>
            <Text style={styles.right}>contact@sacenergy.com | {phone_1}</Text>
            <Text style={styles.right}>sacenergy.com</Text>
            <Text style={[styles.h2, styles.right, { marginTop: 25 }]}>
              {statusId === 1 ? 'Estimate' : 'Invoice'} #{id}
            </Text>
            <Text style={[styles.h3, styles.right, { marginTop: 0 }]}>
              Date:{' '}
              {statusId === 1
                ? moment(estimate_date).format('MM/DD/YYYY')
                : moment(invoice_date).format('MM/DD/YYYY')}
            </Text>

            <Text style={styles.right}>We appreciate your business.</Text>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row'
        }}
      >
        <View style={styles.section}></View>
        <View style={[styles.section]}></View>
      </View>

      <View
        style={{
          flexDirection: 'row'
        }}
      >
        <View style={[styles.section, { flex: 1 }]}>
          <Text style={styles.h4}>Bill To</Text>
          <Text>
            {account_number && `${account_number}`}
            {tank_id && `_ ${tank_id}`}
          </Text>
          <Text>{customer_name && customer_name}</Text>
          <Text>{address_line_1 && address_line_1}</Text>
          <Text>{address_line_2 && address_line_2}</Text>
          <Text>{phone && phone}</Text>
          <Text>{email && email}</Text>
        </View>

        <View style={[styles.section, { flex: 2 }]}>
          <Text style={styles.h4}>Notes</Text>
          <Text>{notes}</Text>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row'
        }}
      >
        <View style={[styles.section, { borderTop: '1px solid #eee', width: '100%' }]}>
          <Text style={styles.h4}>Items</Text>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 10
            }}
          >
            <Text style={[styles.h5, { flexGrow: 1, width: '20%' }]}>Product</Text>
            <Text style={[styles.h5, { flexGrow: 1, width: '40%' }]}>Description</Text>
            <Text style={[styles.h5, { flexGrow: 1, width: '5%' }]}>Qty</Text>
            <Text style={[styles.h5, { flexGrow: 1, width: '15%', textAlign: 'right' }]}>
              Price
            </Text>
            <Text style={[styles.h5, { flexGrow: 1, width: '20%', textAlign: 'right' }]}>
              Subtotal
            </Text>
          </View>
          {renderItems(items)}
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          borderTop: '1px solid #eee'
        }}
      >
        <View style={[styles.section]}></View>
        <View style={[styles.section]}></View>
        <View style={[styles.section]}></View>

        <View style={[styles.section]}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-end'
            }}
          >
            <View style={[styles.section]}>
              <Text style={[styles.h5, { marginTop: 4, marginBottom: 4 }]}>Subtotal</Text>
              <Text style={[styles.h5, { marginTop: 8, marginBottom: 4 }]}>Tax (6%)</Text>
              <Text style={[styles.h2, { marginTop: 12, marginBottom: 4 }]}>Total</Text>
            </View>

            <View style={[styles.section, { marginLeft: 0, alignItems: 'flex-end' }]}>
              <Text
                style={[
                  styles.h4,
                  {
                    marginBottom: 4
                  }
                ]}
              >
                {Number(subtotal).toFixed(2)}
              </Text>
              <Text
                style={[
                  styles.h4,
                  {
                    marginTop: 4,
                    marginBottom: 4
                  }
                ]}
              >
                {Number(taxTotal).toFixed(2)}
              </Text>

              <Text
                style={[
                  styles.h3,
                  {
                    marginTop: 18,
                    marginBottom: 4
                  }
                ]}
              >
                {Number(subtotal + taxTotal).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View
        style={{
          // position: 'absolute',
          width: '100%',
          bottom: 0,
          marginTop: 15,
          borderTop: '1px solid #eee',
          flexDirection: 'row'
        }}
      >
        <View style={[styles.section]}>
          <Text style={styles.h3}>
            {statusId === 1 ? 'How Long Is This Estimate Good For?' : 'Terms'}
          </Text>
          {statusId === 1 && (
            <>
              <Text style={[styles.extraSmall]}>
                Due to changes in parts and availability, our estimates are only valid for 14 days.
                If it is over 14 days, we cannot guarantee the availability of the requested parts
                and/or prices. A team member will notify you of any necessary changes.
              </Text>
            </>
          )}

          {statusId === 2 && (
            <>
              <Text style={[styles.extraSmall]}>
                • Invoices are due within 15 days of the statement date.
              </Text>
              {}
              <Text style={[styles.extraSmall]}>
                • A finance charge of 1.5% per month will be added to all accounts over 30 days.
                Annual percentage of 18%.
              </Text>
            </>
          )}
        </View>
      </View>
    </Page>
  );
}

export default TripPDFPropaneTicketPage;
