import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Divider, message } from 'antd';
import Loader from '../../components/Loader/Loader';
import useResourceEdit from '../../hooks/useResourceEdit';
import ResourceFormActions from '../../components/ResourceFormActions/ResourceFormActions';
import SalesSiteForm from '../../components/forms/SalesSiteForm/SalesSiteForm';

export default function SalesSiteEditContainer({ id, handleCancel = () => {}, onSave = () => {} }) {
  const [state, attrs] = useResourceEdit({ path: '/sales_sites', name: 'Sales Site', id, onSave });
  const { loading, data } = state;
  const { handleSubmit, setForm } = attrs;

  if (loading) {
    return <Loader display={true} />;
  }

  return (
    <React.Fragment>
      <SalesSiteForm data={data} ref={(ref) => setForm(ref)} />
      <ResourceFormActions id={id} handleCancel={handleCancel} handleSubmit={handleSubmit} />
    </React.Fragment>
  );
}
