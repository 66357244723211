import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Popconfirm, Input } from 'antd';
import {
  ProfileOutlined,
  EditOutlined,
  DeleteOutlined,
  PrinterOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import PurchaseOrderEditContainer from '../PurchaseOrderEdit/PurchaseOrderEditContainer';

export default function PurchaseOrderListContainer() {
  let name = 'Purchase Order';
  const path = '/purchase_orders';
  const [state, attrs] = useResourceList(path, name);
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [stores, setStores] = useState([]);
  const [profitCenters, setProfitCenters] = useState([]);
  const { filters, setFilters } = attrs;

  const handleSearch = (key, selectedKeys, confirm) => {
    setFilters({ ...filters, [key]: selectedKeys });
    console.log('keys', selectedKeys);
    confirm();
  };

  const handleReset = (key, clearFilters) => {
    setFilters({ ...filters, [key]: null });
    clearFilters();
  };

  // Load Statuses && Products
  useEffect(() => {
    async function fetchStatuses() {
      const res = await attrs.fetch(`/purchase_orders/statuses`, {
        pageSize: 0,
        columnKey: 'id',
        order: 'ascend'
      });
      setStatuses(res.data.records);
    }
    fetchStatuses();
    axios.get(`/stores?pageSize=10000`).then((res) => setStores(res.data.records));
    axios.get(`/profit_centers?pageSize=10000`).then((res) => setProfitCenters(res.data.records));
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: attrs.sortInfo.columnKey === 'id' && attrs.sortInfo.order
    },
    {
      title: 'Store',
      dataIndex: 'store_id',
      key: 'store_id',
      sortOrder: attrs.sortInfo.columnKey === 'store_id' && attrs.sortInfo.order,
      filters: stores.map((store) => ({ text: store.city, value: store.id })),
      filteredValue: attrs.filters.store_id || null,
      render: (data) =>
        stores && data && data.city && stores.find((store) => store.id === Number(data)).city
    },

    {
      title: 'Profit Center',
      dataIndex: 'profit_center.code',
      key: 'profit_center_id',
      filters: profitCenters.map((profitCenter) => ({
        text: profitCenter.code,
        value: Number(profitCenter.id)
      })),
      filteredValue: attrs.filters.profit_center_id || null,
      sorter: (a, b) => a.profit_center_id - b.profit_center_id,
      sortOrder: attrs.sortInfo.columnKey === 'profit_center_id' && attrs.sortInfo.order,
      render: (data) => data
    },

    {
      title: 'Account Number',
      dataIndex: 'account_number',
      key: 'account_number',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Account Number"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('account_number', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('account_number', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('account_number', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.account_number || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'account_number' && attrs.sortInfo.order
    },

    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Customer"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('customer_name', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('customer_name', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('customer_name', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.customer_name || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'customer_name' && attrs.sortInfo.order
    },

    {
      title: 'Address Line 1',
      dataIndex: 'address_line_1',
      key: 'address_line_1',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Customer"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('address_line_1', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('address_line_1', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('address_line_1', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.address_line_1 || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'address_line_1' && attrs.sortInfo.order
    },

    {
      title: 'Address Line 2',
      dataIndex: 'address_line_2',
      key: 'address_line_2',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Customer"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('address_line_2', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('address_line_2', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('address_line_2', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.address_line_2 || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'address_line_2' && attrs.sortInfo.order
    },

    {
      title: 'Status',
      dataIndex: 'status.title',
      key: 'purchase_order_status_id',
      filters: statuses.map((item) => ({ text: item.title, value: item.id })),
      filteredValue: attrs.filters.purchase_order_status_id || null,
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'purchase_order_status_id' && attrs.sortInfo.order
    },

    {
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList();
  };

  const data = state.data
    ? state.data.map((item) => ({
        ...item,
        actions: (
          <div>
            <Link to={`/purchase_orders/${item.id}`}>
              <ProfileOutlined />
            </Link>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>

            <Link to={`/purchase_orders/${item.id}/pdf`}>
              <PrinterOutlined />
            </Link>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>

            <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
              <EditOutlined />
            </a>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={attrs.removeResource.bind(this, item.id)}
            >
              <a href="#!">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </div>
        )
      }))
    : [];

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
      >
        <PurchaseOrderEditContainer
          handleCancel={toggleModal}
          id={resourceId}
          onSave={onModalSave}
        />
      </ResourceModal>

      <ResourceList
        {...attrs}
        data={data}
        extra={[
          <Button key="1" type="primary" onClick={toggleModal}>
            Create {name}
          </Button>
        ]}
      />
    </React.Fragment>
  );
}
