import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loader from '../../components/Loader/Loader';
import useResourceEdit from '../../hooks/useResourceEdit';
import ResourceFormActions from '../../components/ResourceFormActions/ResourceFormActions';
import TruckCompartmentForm from '../../components/forms/TruckCompartmentForm/TruckCompartmentForm';

export default function TruckEditContainer({
  id,
  handleCancel = () => {},
  onSave = () => {},
  truckId
}) {
  const [state, attrs] = useResourceEdit({
    path: '/truck_compartments',
    name: 'Truck Compartment',
    id,
    onSave
  });
  const { loading, data } = state;
  const { handleSubmit, setForm } = attrs;

  if (loading) {
    return <Loader display={true} />;
  }

  return (
    <React.Fragment>
      <TruckCompartmentForm ref={(form) => setForm(form)} data={data} truckId={truckId} />
      <ResourceFormActions id={id} handleCancel={handleCancel} handleSubmit={handleSubmit} />
    </React.Fragment>
  );
}
