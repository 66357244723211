import React, { useEffect, useContext } from 'react';
import { Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, ProfileOutlined, PrinterOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import UserContext from '../../UserContext';
import { secondsToHoursMinutes } from '../../utils/units';

export default function TripListContainer({ dispatchId }) {
  let name = 'Trip';
  const path = `/trips`;
  const parentParams = { dispatch_id: dispatchId };
  const defaultParams = { columnKey: 'start_at', order: 'ascend' };
  const [state, attrs] = useResourceList(path, name, parentParams, defaultParams);
  const history = useHistory();
  const { user } = useContext(UserContext);

  const dispatchEditRoles = [1, 2, 4, 6];

  const columns = [
    {
      title: 'Start At',
      dataIndex: 'start_at',
      key: 'start_at',
      sorter: (a, b) => a.start_at - b.start_at,
      sortOrder: attrs.sortInfo.columnKey === 'start_at' && attrs.sortInfo.order,
      render: (data) => moment.unix(data).format('LT')
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title - b.title,
      sortOrder: attrs.sortInfo.columnKey === 'title' && attrs.sortInfo.order
    },
    {
      title: 'Truck',
      dataIndex: 'truck.code',
      key: 'truck_id',
      sorter: (a, b) => a.truck_id - b.truck_id,
      sortOrder: attrs.sortInfo.columnKey === 'truck_id' && attrs.sortInfo.order
    },
    {
      title: 'Driver',
      dataIndex: 'driver.full_name',
      key: 'driver_id',
      sorter: (a, b) => a.driver_id - b.driver_id,
      sortOrder: attrs.sortInfo.columnKey === 'driver_id' && attrs.sortInfo.order
    },
    {
      title: 'Total Tickets',
      dataIndex: 'total_tickets',
      key: 'total_tickets',
      sorter: (a, b) => a.total_tickets - b.total_tickets,
      sortOrder: attrs.sortInfo.columnKey === 'total_tickets' && attrs.sortInfo.order
    },
    {
      title: 'Total Gallons',
      dataIndex: 'total_gallons',
      key: 'total_gallons',
      sorter: (a, b) => a.total_gallons - b.total_gallons,
      sortOrder: attrs.sortInfo.columnKey === 'total_gallons' && attrs.sortInfo.order
    },
    {
      visible: false,
      title: 'Total Distance',
      dataIndex: 'total_distance_in_miles',
      key: 'total_distance_in_miles',
      sorter: (a, b) => a.total_distance_in_miles - b.total_distance_in_miles,
      sortOrder: attrs.sortInfo.columnKey === 'total_distance_in_miles' && attrs.sortInfo.order
    },
    {
      visible: false,
      title: 'Total Time',
      dataIndex: 'total_time_in_seconds',
      key: 'total_time_in_seconds',
      sorter: (a, b) => a.total_time_in_seconds - b.total_time_in_seconds,
      sortOrder: attrs.sortInfo.columnKey === 'total_time_in_seconds' && attrs.sortInfo.order,
      render: (data) => secondsToHoursMinutes(data)
    },
    {
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  const createTrip = (e) => {
    e && e.preventDefault();
    history.push(`/dispatches/${dispatchId}/trips/new`);
  };

  const loadDispatchMap = (e) => {
    e && e.preventDefault();
    history.push(`/dispatches/${dispatchId}/map`);
  };

  const data = state.data
    ? state.data.map((item) => ({
        ...item,
        actions: (
          <div>
            <Link target="_blank" to={`/dispatches/${dispatchId}/trips/${item.id}/pdf`}>
              <PrinterOutlined />
            </Link>

            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>

            <Link to={`/dispatches/${dispatchId}/trips/${item.id}`}>
              <ProfileOutlined />
            </Link>

            {user && dispatchEditRoles.includes(user.role_id) && (
              <React.Fragment>
                <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>

                <Link to={`/dispatches/${dispatchId}/trips/${item.id}/edit`}>
                  <EditOutlined />
                </Link>

                <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>

                <Popconfirm
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={attrs.removeResource.bind(this, item.id)}
                >
                  <a href="#!">
                    <DeleteOutlined />
                  </a>
                </Popconfirm>
              </React.Fragment>
            )}
          </div>
        )
      }))
    : [];

  return (
    <React.Fragment>
      <ResourceList
        data={data}
        extra={
          <>
            <Button key="1" type="primary" onClick={loadDispatchMap}>
              View Map
            </Button>
            {user &&
              dispatchEditRoles.includes(user.role_id) && [
                <Button key="1" type="primary" onClick={createTrip}>
                  Create {name}
                </Button>
              ]}
          </>
        }
        {...attrs}
      />
    </React.Fragment>
  );
}
