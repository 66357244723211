import React, { useState, useEffect, useContext } from 'react';
import {
  Input,
  Button,
  DatePicker,
  Select,
  Layout,
  PageHeader,
  Tooltip,
  Table,
  message,
  Popconfirm
} from 'antd';
import { EditOutlined, CheckOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import axios from 'axios';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';

import UserContext from '../../UserContext';

import ResourceModal from '../../components/ResourceModal/ResourceModal';
import SalesSiteEditContainer from '../SalesSiteEdit/SalesSiteEditContainer';
import SalesSiteCommissionReport from './SalesSiteCommissionReport';

import { getTicketStatusTag } from '../../utils/constants';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Content } = Layout;

const salesCommissionRoles = [1, 2];

export default function SalesSiteListContainer(props) {
  const { user, setUser } = useContext(UserContext);
  const { handleOnClick = () => {} } = props;

  let name = 'Sales Sites';
  const path = '/sales_sites';
  const [state, attrs] = useResourceList(
    path,
    name,
    {},
    { columnKey: 'id', order: 'descend', active: 'Y' },
    { pageSize: 25 }
  );

  const { filters, setFilters } = attrs;
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);
  const [displayCommissionModal, setDisplayCommissionModal] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [ticketStatuses, setTicketStatuses] = useState([]);
  const [classes, setClasses] = useState([]);

  // Load Statuses && Products
  useEffect(() => {
    async function fetchClasses() {
      const res = await attrs.fetch(`/inventories/classes`, {
        pageSize: 0,
        columnKey: 'id',
        order: 'ascend'
      });
      setClasses(res.data.records);
    }
    fetchClasses();
  }, []);

  const columns = [
    {
      title: 'Salesman',
      dataIndex: 'salesman',
      key: 'salesman',
      filters: [
        {
          text: 'vstraub',
          value: 'vstraub'
        }
      ],
      filteredValue: attrs.filters.salesman || null,
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'salesman' && attrs.sortInfo.order
    },
    {
      title: 'Account Number',
      dataIndex: 'account_number',
      key: 'account_number',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Account Number"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('account_number', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('account_number', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('account_number', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.account_number || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'account_number' && attrs.sortInfo.order
    },

    {
      title: 'Site ID',
      dataIndex: 'tank_id',
      key: 'tank_id',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Site ID"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('tank_id', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('tank_id', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('tank_id', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.tank_id || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'tank_id' && attrs.sortInfo.order
    },

    {
      title: 'Customer Name',
      dataIndex: 'customer.full_name',
      key: 'customer.full_name'
    },

    {
      title: 'Product',
      dataIndex: 'site.product.title',
      key: 'site.product.title'
    },

    {
      title: 'Start At',
      dataIndex: 'start_at',
      key: 'start_at',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <RangePicker
              defaultValue={selectedKeys && [moment(selectedKeys[0]), moment(selectedKeys[1])]}
              onChange={(dateArray) => {
                // dateArray = [moment, moment]
                // We need to convert to a value that is ready for our api
                setSelectedKeys(
                  dateArray
                    ? [
                        moment(dateArray[0]).format('YYYY-MM-DD'),
                        moment(dateArray[1]).format('YYYY-MM-DD')
                      ]
                    : []
                );
              }}
              style={{ marginBottom: 8, display: 'block' }}
            />

            <Button
              type="link"
              onClick={() => handleSearch('start_at', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('start_at', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.start_at || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: true, // Just show the button, do not sort locally
      sortOrder: attrs.sortInfo.columnKey === 'start_at' && attrs.sortInfo.order,
      render: (data) => data && moment.unix(data).format('MM-DD-YYYY')
    },
    {
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  // Load Routes
  useEffect(() => {
    async function fetchRoutes() {
      const res = await attrs.fetch(`/routes`, {
        pageSize: 0,
        columnKey: 'name',
        order: 'ascend',
        store_id: attrs.filters.region || null
      });
      setRoutes(res.data.records);
    }
    fetchRoutes();
  }, [attrs.filters.region]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const toggleCommissionModal = (e) => {
    e && e.preventDefault();
    setDisplayCommissionModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList({
      pagination: attrs.pagination,
      sorter: attrs.sortInfo,
      filters: attrs.filters
    });
  };

  const handleSearch = (key, selectedKeys, confirm) => {
    setFilters({ ...filters, [key]: selectedKeys });
    console.log('keys', selectedKeys);
    confirm();
  };

  const handleReset = (key, clearFilters) => {
    setFilters({ ...filters, [key]: null });
    clearFilters();
  };

  const [locationData, setLocationData] = useState({});

  const data = state.data
    ? state.data.map((item) => ({
        ...item,
        actions: (
          <div>
            <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
              <EditOutlined />
            </a>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={attrs.removeResource.bind(this, item.id)}
            >
              <a href="#!">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </div>
        )
      }))
    : [];

  if (!user || !salesCommissionRoles.includes(user.role_id)) return 'No Access';

  return (
    <React.Fragment>
      <ResourceModal
        title={`Generate Commission Report`}
        visible={displayCommissionModal}
        toggle={toggleCommissionModal}
        width="50%"
      >
        <SalesSiteCommissionReport handleCancel={toggleCommissionModal} />
        {/* <SalesSiteEditContainer handleCancel={toggleModal} id={resourceId} onSave={onModalSave} /> */}
      </ResourceModal>

      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
        width="50%"
      >
        <SalesSiteEditContainer handleCancel={toggleModal} id={resourceId} onSave={onModalSave} />
      </ResourceModal>

      <ResourceList
        columns={attrs.columns}
        setColumns={(value) => attrs.setColumns(value)}
        data={data}
        onClick={handleOnClick}
        extra={[
          <Button key="2" onClick={toggleCommissionModal}>
            Generate Commission Report
          </Button>,
          <Button key="1" type="primary" onClick={toggleModal}>
            Create {name}
          </Button>
        ]}
        {...attrs}
      />
    </React.Fragment>
  );
}
