import React, { Component } from 'react'; // eslint-disable-line no-unused-vars

export default function(user, ComposedComponent) {
  class Authenticate extends Component {
    componentDidMount() {
      if (!user) {
        this.props.history.push('/login');
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  return Authenticate;
}
