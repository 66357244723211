import React from 'react';
import { Marker } from 'react-leaflet';
import FullscreenControl from 'react-leaflet-fullscreen';
import DivIcon from '../../components/DivIcon/DivIcon';
import { googleStreet } from '../../components/ReactMap/mapLayers';

import ReactMap from '../../components/ReactMap/ReactMap';

const position = [40.0599152, -78.5138748];
export default function SiteListMap(props) {
  const { sites = [] } = props;

  return (
    <ReactMap height={500} center={position} zoom={10} defaultTile={googleStreet}>
      <FullscreenControl position="topleft" forceSeparateButton={true} />
      {sites.map((site, index) => {
        const { delivery_detail = {} } = site;

        if (delivery_detail) {
          const { latitude, longitude } = delivery_detail;
          if (latitude && longitude) {
            return (
              <Marker
                key={index}
                position={[latitude, longitude]}
                icon={DivIcon({ text: index + 1 })}
              />
            );
          }
        }
      })}
    </ReactMap>
  );
}
