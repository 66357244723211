import React, { useState } from 'react';
import { Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import FuelDiscountEditContainer from '../FuelDiscountEdit/FuelDiscountEditContainer';

export default function FuelDiscountListContainer() {
  let name = 'Fuel Discount';
  const path = '/fuel_discounts';
  const [state, attrs] = useResourceList(path, name);
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList();
  };

  const data = state.data
    ? state.data.map((item) => ({
        ...item,
        actions: (
          <div>
            <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
              <EditOutlined />
            </a>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={attrs.removeResource.bind(this, item.id)}
            >
              <a href="#!">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </div>
        )
      }))
    : [];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: attrs.sortInfo.columnKey === 'name' && attrs.sortInfo.order
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      sortOrder: attrs.sortInfo.columnKey === 'code' && attrs.sortInfo.order
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      sorter: (a, b) => a.discount - b.discount,
      sortOrder: attrs.sortInfo.columnKey === 'discount' && attrs.sortInfo.order
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      sorter: (a, b) => a.active - b.active,
      sortOrder: attrs.sortInfo.columnKey === 'active' && attrs.sortInfo.order,
      render: (i) => (i ? 'True' : 'False')
    },
    {
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
      >
        <FuelDiscountEditContainer
          handleCancel={toggleModal}
          id={resourceId}
          onSave={onModalSave}
        />
      </ResourceModal>

      <ResourceList
        columns={columns}
        data={data}
        extra={[
          <Button key="1" type="primary" onClick={toggleModal}>
            Create {name}
          </Button>
        ]}
        {...attrs}
      />
    </React.Fragment>
  );
}
