import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Popconfirm, Layout, Divider, message } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  CarOutlined,
  ProfileOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import PurchaseOrderItemEditContainer from '../PurchaseOrderItemEdit/PurchaseOrderItemEditContainer';
import styles from './PurchaseOrderItemList.module.css';

const { Content } = Layout;

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

export default function PurchaseOrderItemListContainer({ purchaseOrderId, purchaseOrder = {} }) {
  let name = 'Line Item';
  const path = '/purchase_order_items';
  const parentParams = {
    columnKey: 'order',
    order: 'ascend',
    purchase_order_id: purchaseOrderId,
    pageSize: 1000
  };
  const [state, attrs] = useResourceList(path, name, parentParams);
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);

  const { profit_center } = purchaseOrder;
  const profitCenterCode = profit_center ? profit_center.code : '';

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: styles.dragVisible,
      render: () => <DragHandle />
    },
    {
      visible: true,
      title: 'Product Code',
      dataIndex: 'inv_product',
      key: 'inv_product',
      className: styles.dragVisible
    },
    {
      visible: true,
      title: 'Location',
      dataIndex: 'inv_location',
      key: 'inv_location'
    },
    {
      visible: true,
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },

    {
      visible: true,
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty'
    },

    {
      visible: true,
      title: 'Price',
      dataIndex: 'price',
      key: 'price'
    },

    {
      visible: true,
      title: 'Taxable',
      dataIndex: 'taxable',
      key: 'taxable',
      render: (value) => (value === true ? 'Yes' : 'No')
    },

    {
      visible: true,
      title: 'Subtotal',
      dataIndex: 'subtotal',
      key: 'subtotal'
    },

    {
      visible: true,
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList();
  };

  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(state.data), oldIndex, newIndex).filter(
        (el) => !!el
      );
      attrs.setData(newData); // Updates the local view to display new sort order

      const apiOrderData = newData.map((item, index) => ({ id: item.id, data: { order: index } }));

      // Send Update to the API
      axios
        .patch(`/purchase_order_items/batch_update`, {
          data: apiOrderData
        })
        .then((res) => {
          message.success(`Successfully Updated Order`);
        });
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass={styles.rowDragging}
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = state.data.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const data = state.data
    ? state.data.map((item) => ({
        ...item,
        subtotal: (item.price * item.qty).toFixed(2),
        actions: (
          <div>
            <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
              <EditOutlined />
            </a>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={attrs.removeResource.bind(this, item.id)}
            >
              <a href="#!">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </div>
        )
      }))
    : [];

  const subtotal = data.reduce((total, item) => Number(item.subtotal) + total, 0);
  const taxTotal = data.reduce((total, item) => {
    return total + (item.taxable ? Number(item.subtotal) * 0.06 : 0);
  }, 0);

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
      >
        <PurchaseOrderItemEditContainer
          handleCancel={toggleModal}
          id={resourceId}
          onSave={onModalSave}
          purchaseOrder={purchaseOrder}
          profitCenterCode={profitCenterCode}
        />
      </ResourceModal>

      <ResourceList
        data={data}
        extra={[
          <Button key="1" type="primary" onClick={toggleModal}>
            Add {name}
          </Button>
        ]}
        hidePagination={true}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }}
        {...attrs}
      />

      <Content className={styles.content}>
        <div className={styles.main}>
          <h3>Subtotal: ${Number(subtotal).toFixed(2)}</h3>
          <h3>Tax (6%): ${Number(taxTotal).toFixed(2)}</h3>
          <Divider />
          <h2>Total Due: ${Number(subtotal + taxTotal).toFixed(2)}</h2>
        </div>
      </Content>
    </React.Fragment>
  );
}
