import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Body from '../../components/Body/Body';
import Content from '../../components/Content/Content';
import Stat from '../../components/Stat/Stat';
import { PageHeader, Breadcrumb, Row, Col, Statistic, Card, Button } from 'antd';
import {
  ProfileOutlined,
  EditOutlined,
  DeleteOutlined,
  PrinterOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { handleError } from '../../utils/handleError';
import StoreList from '../StoreList/StoreListContainer';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import PurchaseOrderItemList from '../PurchaseOrderItemList/PurchaseOrderItemListContainer';
import PurchaseOrderEditContainer from '../PurchaseOrderEdit/PurchaseOrderEditContainer';

import styles from './PurchaseOrderShowContainer.module.css';

export default function PurchaseOrderShowContainer(props) {
  let name = 'Purchase Order';
  const purchaseOrderId = props.match.params.purchase_order_id;
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [displayModal, setDisplayModal] = useState(false);
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [loading, setLoading] = useState(false);
  const {
    id,
    profit_center = {},
    account_number,
    tank_id,
    customer_name,
    address_line_1,
    address_line_2,
    phone,
    email,
    notes,
    purchase_order_status,
    estimate_date,
    invoice_date
  } = purchaseOrder;

  useEffect(() => {
    fetchPurchaseOrder(purchaseOrderId);
  }, [purchaseOrderId]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    fetchPurchaseOrder(purchaseOrderId);
  };

  const fetchPurchaseOrder = (id) => {
    setLoading(true);
    axios
      .get(`/purchase_orders/${id}`)
      .then((res) => {
        setPurchaseOrder(res.data);
        setLoading(false);
      })
      .catch((err) => handleError(err));
  };

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/purchase_orders">Purchase Orders</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Purchase Order #{purchaseOrderId}</Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
      >
        <PurchaseOrderEditContainer
          handleCancel={toggleModal}
          id={resourceId}
          onSave={onModalSave}
        />
      </ResourceModal>

      <Content className={styles.header} breadcrumb={breadcrumb}>
        <PageHeader title={`Purchase Order #${purchaseOrderId}`} />
        <Body>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
            <Col span={10}>
              <Card title="Customer" bordered={false}>
                {account_number && <p>{account_number}</p>}
                {tank_id && <p>{tank_id}</p>}

                <p>
                  {customer_name} <br />
                  {address_line_1}
                  <br />
                  {address_line_2}
                </p>

                <p>
                  {phone}
                  <br />
                  {email}
                  <br />
                </p>
              </Card>
            </Col>
            <Col span={10}>
              <Card title="Details" bordered={false}>
                <p>
                  <strong>Status:</strong> {'Estimate'} <br />
                  <strong>Profit Center:</strong> {profit_center.code} <br />
                  <strong>Estimate Date:</strong>{' '}
                  {estimate_date && moment(estimate_date).format('YYYY-MM-DD')} <br />
                  <strong>Invoice Date:</strong>{' '}
                  {invoice_date && moment(invoice_date).format('YYYY-MM-DD')} <br />
                </p>

                <p>{notes}</p>
              </Card>
            </Col>
            <Col span={4}>
              <Card title="Actions" bordered={false}>
                <p>
                  <Button>
                    <Link to={`/purchase_orders/${purchaseOrderId}/pdf`}>
                      <PrinterOutlined /> Print
                    </Link>
                  </Button>
                </p>

                <p>
                  <Button>
                    <a href="#!" onClick={toggleModal.bind(this, null, purchaseOrderId)}>
                      <EditOutlined /> Edit
                    </a>
                  </Button>
                </p>

                <p>
                  <Button>
                    <Link to={`/purchase_orders`}>
                      <ArrowLeftOutlined /> Back
                    </Link>
                  </Button>
                </p>
              </Card>
            </Col>
          </Row>
        </Body>
      </Content>
      <PurchaseOrderItemList purchaseOrder={purchaseOrder} purchaseOrderId={purchaseOrderId} />
    </React.Fragment>
  );
}
