import React from 'react';
import moment from 'moment';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import styles from './TripPDFStyles';
import logo from './SAC-Energy-Outlined-white-rotated-dark.png';

const renderDeliveries = (deliveries) => {
  return deliveries.map((delivery, index) => {
    if (index > 4) return <React.Fragment></React.Fragment>;
    return (
      <View
        key={index}
        style={{
          flexDirection: 'row',
          marginTop: 5
        }}
      >
        <Text style={[{ flexGrow: 1, width: '25%' }]}>
          {delivery.delivery_date
            ? moment.unix(delivery.delivery_date).format('YYYY-MM-DD')
            : '---'}
        </Text>
        <Text style={[{ flexGrow: 1, width: '25%' }]}>{delivery.delivery_gallons.toFixed(1)}</Text>
        <Text style={[{ flexGrow: 1, width: '25%' }]}>{delivery.driver_code}</Text>
        <Text style={[{ flexGrow: 1, width: '25%' }]}>{delivery.truck_code}</Text>
      </View>
    );
  });
};

function PDFDispatchTicket({ item, store }) {
  const { ticket } = item;
  if (!ticket) return <React.Fragment></React.Fragment>;

  const { site, product } = ticket;
  const { customer, delivery_detail, ar_customer, deliveries } = site;
  const { name, address, city, state, zip, phone_1 } = store;

  return (
    <Page size="LETTER" style={styles.page}>
      <View
        style={{
          flexDirection: 'row',
          marginLeft: 10,
          marginRight: 10
        }}
      >
        <View style={styles.section}>
          <Image style={styles.logo} src={logo} />
        </View>
        <View style={[styles.section]}>
          <View style={styles.right}>
            <Text style={styles.right}>
              <Text style={styles.bold}>{name}</Text> | {address} | {city}, {state} {zip}
            </Text>
            <Text style={styles.right}>contact@sacenergy.com | {phone_1}</Text>
            <Text style={styles.right}>sacenergy.com</Text>

            <Text style={[styles.h2, styles.right, { marginTop: 15 }]}>
              Invoice #{ticket.ticket_number}
            </Text>
            <Text style={styles.right}>We appreciate your business.</Text>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row'
        }}
      >
        <View style={styles.section}></View>

        <View style={[styles.section]}></View>
      </View>

      <View
        style={{
          flexDirection: 'row'
        }}
      >
        <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
          <Text style={styles.h4}>Bill To</Text>
          <Text>{customer && `${customer.last_name}, ${customer.first_name}`}</Text>
          <Text>{customer && customer.address_line_1}</Text>
          <Text>{customer && customer.address_line_2}</Text>
          <Text>{customer && customer.phone}</Text>
          <Text>{customer && customer.phone_2}</Text>
        </View>

        <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
          <Text style={styles.h4}>Account</Text>
          <Text>{ticket.account_number}</Text>
        </View>

        <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
          <Text style={styles.h4}>Call Type</Text>
          <Text>{ticket.type}</Text>
        </View>

        <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
          <Text style={styles.h4}>Pay Type</Text>
          <Text>{ar_customer && ar_customer.terms}</Text>
        </View>

        <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
          <Text style={styles.h4}>Current Balance</Text>
          <Text>{ar_customer && ar_customer.current_balance.toFixed(2)}</Text>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row'
        }}
      >
        <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
          <Text style={styles.h4}>Deliver To</Text>
          <Text>{customer && `${customer.last_name}, ${customer.first_name}`}</Text>
          <Text>{site.delivery_detail.address_line_1}</Text>
          <Text>{site.delivery_detail.address_line_2}</Text>
        </View>

        <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
          <Text style={[styles.h4]}>Delivery Date</Text>
          <Text>
            {ticket.delivery_date ? moment.unix(ticket.delivery_date).format('YYYY-MM-DD') : '---'}
          </Text>

          <Text style={[styles.h4, styles.paddingTop]}>Tank</Text>
          <Text>{ticket.tank_id}</Text>
        </View>

        <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
          <Text style={[styles.h4]}>Product</Text>
          <Text>
            {product.code} - {product.title}
          </Text>

          <Text style={[styles.h4, styles.paddingTop]}>Tank Location</Text>
          <Text>{delivery_detail.tank_location}</Text>
        </View>

        <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
          <Text style={[styles.h4]}>Route</Text>
          <Text>{site.route}</Text>

          <Text style={[styles.h4, styles.paddingTop]}>Tank Size</Text>
          <Text>{site.tank_rated_size}</Text>
        </View>

        <View style={[styles.section, { borderTop: '1px solid #eee' }]}>
          <Text style={[styles.h4]}>Est Delivery Date</Text>
          <Text>
            {site.estimated_next_delivery_at && ticket.type == 'AT'
              ? moment.unix(site.estimated_next_delivery_at).format('YYYY-MM-DD')
              : '---'}
          </Text>

          <Text style={[styles.h4, styles.paddingTop]}>Tank Capacity</Text>
          <Text>{site.tank_actual_size}</Text>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row'
        }}
      >
        <View
          style={[styles.section, { borderTop: '1px solid #eee', width: '50%', minHeight: 125 }]}
        >
          <Text style={styles.h4}>Delivery Notes</Text>
          <View
            style={{
              flexDirection: 'column',
              marginTop: 10
            }}
          >
            <Text>{ticket.delivery_instructions_1}</Text>
            <Text>{ticket.delivery_instructions_2}</Text>

            <Text style={{ marginTop: 5 }}>{site.delivery_detail.special_instructions_1}</Text>
            <Text>{site.delivery_detail.special_instructions_2}</Text>
          </View>
        </View>

        <View style={[styles.section, { borderTop: '1px solid #eee', width: '50%' }]}>
          <Text style={styles.h4}>Delivery History</Text>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 10
            }}
          >
            <Text style={[styles.h5, { flexGrow: 1, width: '25%' }]}>Date</Text>
            <Text style={[styles.h5, { flexGrow: 1, width: '25%' }]}>Gallons</Text>
            <Text style={[styles.h5, { flexGrow: 1, width: '25%' }]}>Driver</Text>
            <Text style={[styles.h5, { flexGrow: 1, width: '25%' }]}>Truck</Text>
          </View>
          {renderDeliveries(deliveries)}
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          borderTop: '1px solid #eee'
        }}
      >
        <View style={[styles.section]}>
          <Text style={[styles.h4, { marginTop: 4, marginBottom: 8 }]}>Price Includes:</Text>
          <Text style={[{ marginTop: 4, marginBottom: 8 }]}>
            <Text>_____</Text>¢ - Senior Discount
          </Text>
          <Text style={[{ marginTop: 4, marginBottom: 8 }]}>
            <Text>_____</Text>¢ - Volume Discount
          </Text>
          <Text style={[{ marginTop: 4, marginBottom: 8 }]}>
            <Text>_____</Text>¢ - Neighbor Discount
          </Text>
        </View>

        <View style={[styles.section]}>
          <View
            style={{
              flexDirection: 'row'
            }}
          >
            <View style={[styles.section]}>
              <Text style={[styles.h5, { marginTop: 4, marginBottom: 4 }]}>Amount Received</Text>
              <Text style={[styles.h5, { marginTop: 4, marginBottom: 4 }]}>Payment Method</Text>
            </View>

            <View style={[styles.section, { marginLeft: 0 }]}>
              <Text
                style={[
                  styles.h4,
                  {
                    marginBottom: 4,
                    borderBottom: '1px solid #bbb'
                  }
                ]}
              >
                {' '}
              </Text>
              <Text
                style={[
                  styles.h4,
                  {
                    marginBottom: 4,
                    borderBottom: '1px solid #bbb'
                  }
                ]}
              >
                {' '}
              </Text>
            </View>
          </View>
          <View style={[styles.h3, styles.right, { marginTop: 20, marginRight: 10 }]}>
            <Text>See Attached Receipt for Invoice Total</Text>
          </View>
        </View>
      </View>

      <View
        style={{
          position: 'absolute',
          width: '100%',
          bottom: 0,
          borderTop: '1px solid #eee',
          flexDirection: 'row'
        }}
      >
        <View style={[styles.section, { flexGrow: 2 }]}>
          <Text style={styles.h3}>Now Delivering Propane!</Text>
          <Text>Learn how you can qualify for a free tank rental </Text>
          <Text style={{ marginBottom: 7 }}>
            and receive the fastest and friendliest service in the industry.
          </Text>

          <Text>Give us a call today to learn more at {phone_1} or</Text>
          <Text>visit us at sacenergy.com/propane.</Text>
        </View>

        <View style={[styles.section]}>
          <Text style={styles.h3}>Terms</Text>

          <Text style={[styles.extraSmall]}>
            • All charges paid within 5 days of delivery with receive the CASH price.
          </Text>
          <Text style={[styles.extraSmall]}>
            • The net charge amount is due within 15 days of the statement date.
          </Text>
          <Text style={[styles.extraSmall]}>
            • The finance charge of 1.5% per month will be added to all
          </Text>
          <Text style={[styles.extraSmall, { marginLeft: 5 }]}>
            accounts over 30 days. Annual percentage of 18%.
          </Text>

          <Text style={[styles.extraSmall]}>
            • Unless specified otherwise, each previous delivery must be paid in
          </Text>
          <Text style={[styles.extraSmall, { marginLeft: 5 }]}>
            full before the next delivery, or within 15 days of the statement date,
          </Text>
          <Text style={[styles.extraSmall, { marginLeft: 5 }]}>whichever comes first.</Text>
        </View>
      </View>
    </Page>
  );
}

export default PDFDispatchTicket;
