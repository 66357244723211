import React from 'react';
import moment from 'moment';
import { Layout } from 'antd';
import Content from '../../components/Content/Content';
import Body from '../../components/Body/Body';
import { PageHeader, Breadcrumb, Row, Col, Statistic, Card, Button } from 'antd';

function Equipment({ data }) {
  const {
    id,
    serial,
    model,
    description,
    manufacturer,
    purchased_at,
    purchase_cost,
    capacity,
    inspection_next_at,
    inspection_interval,
    profit_center_code,
    location_id,
    manufactured_at,
    is_invoice_processed,
    invoice_reference
  } = data;
  return (
    <Layout>
      <Content>
        <Body>
          <Row gutter={[64]}>
            <Col span={10}>
              <Card title={`Equipment #${id}`} bordered={false}>
                <h4>Serial:</h4>
                <p>{serial}</p>

                <h4>Model:</h4>
                <p>{model}</p>

                <h4>Description:</h4>
                <p>{description}</p>

                <h4>Capacity:</h4>
                <p>{capacity}</p>
              </Card>
            </Col>
            <Col span={10}>
              <Card title="Purchase Details" bordered={false}>
                <h4>Profit Center Code:</h4>
                <p>{profit_center_code}</p>

                <h4>Purchased On:</h4>
                <p>{moment.unix(purchased_at).format('YYYY-MM-DD')}</p>

                <h4>Purchase Cost:</h4>
                <p>{purchase_cost && '$' + Number(purchase_cost).toFixed(2)}</p>
              </Card>
            </Col>
          </Row>

          <Row gutter={[64]}>
            <Col span={10}>
              <Card title={`Inspection Details`} bordered={false}>
                <h4>Manufacturer:</h4>
                <p>{manufacturer && manufacturer.title}</p>

                <h4>Manufactured On:</h4>
                <p>{manufactured_at && moment.unix(manufactured_at).format('YYYY-MM-DD')}</p>

                <h4>Inspection Interval:</h4>
                <p>{inspection_interval}</p>

                <h4>Next Inspection Due On:</h4>
                <p>{inspection_next_at && moment.unix(inspection_next_at).format('YYYY-MM-DD')}</p>
              </Card>
            </Col>
            <Col span={10}>
              <Card title={`Inventory`} bordered={false}>
                <h4>Invoice Processed:</h4>
                <p>{is_invoice_processed}</p>

                <h4>Invoice Reference:</h4>
                <p>{invoice_reference}</p>
              </Card>
            </Col>
            <Col span={4}></Col>
          </Row>
        </Body>
      </Content>
    </Layout>
  );
}

export default Equipment;
