import React from 'react';
import { Row, Col, Form, Input, DatePicker, Select, Checkbox } from 'antd';
import moment from 'moment';

import styles from './TicketSearchForm.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

function TicketSearchForm(props) {
  const dateFormat = 'YYYY-MM-DD';
  const { routes = [], products = [], salesTypes = [] } = props;
  const { getFieldDecorator } = props.form;

  return (
    <div className={styles.ticketSearchForm}>
      <Form layout="vertical">
        <Row gutter={[16, 0]}>
          <h5>Delivery Dates Between</h5>
          <Col span={12}>
            <Form.Item>
              {getFieldDecorator('range', {
                initialValue: [
                  moment(moment().add(1, 'day'), dateFormat),
                  moment(moment().add(1, 'day'), dateFormat)
                ]
              })(<RangePicker format={dateFormat} style={{ width: '100%' }} />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('route')(
                <Select mode="multiple" placeholder="All Routes">
                  {routes.map((route) => {
                    return (
                      <Option key={route.id} value={route.name}>
                        {route.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('product_code')(
                <Select mode="multiple" placeholder="All Products">
                  {products.map((product) => (
                    <Option key={product.id} value={product.code}>
                      {product.code} - {product.title}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('sales_type')(
                <Select mode="multiple" placeholder="All Types">
                  {salesTypes.map((type, index) => (
                    <Option key={index} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('print_group')(
                <Select mode="multiple" placeholder="All Print Groups">
                  <Option value="DEMAND">Demand</Option>

                  <Option value="AUTO">Auto</Option>
                </Select>
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('past_due', {
                valuePropName: 'unchecked',
                initialValue: false
              })(<Checkbox>Find Past Due Tickets</Checkbox>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              {getFieldDecorator('ticket_number')(<Input placeholder="Ticket Number" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('account_number')(<Input placeholder="Account Number" />)}
            </Form.Item>
            <Form.Item>{getFieldDecorator('site_id')(<Input placeholder="Site ID" />)}</Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

TicketSearchForm.propTypes = {};

const WrappedTicketSearchForm = Form.create({ name: 'ticketSearchForm' })(TicketSearchForm);
export default WrappedTicketSearchForm;
