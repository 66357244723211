import React, { useState, useEffect } from 'react';
import { Input, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, ProfileOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import EquipmentLeaseEditContainer from '../EquipmentLeaseEdit/EquipmentLeaseEditContainer';
import { trim } from 'lodash';

export default function EquipmentLeaseListContainer({ equipmentId }) {
  let name = 'Equipment Lease';
  const path = '/equipment_leases';
  const parentParams = { equipment_id: equipmentId };
  const [state, attrs] = useResourceList(path, name, parentParams);
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);

  const { filters, setFilters } = attrs;

  const handleSearch = (key, selectedKeys, confirm) => {
    setFilters({ ...filters, [key]: selectedKeys });
    confirm();
  };

  const handleReset = (key, clearFilters) => {
    setFilters({ ...filters, [key]: null });
    clearFilters();
  };
  const columns = [
    {
      visible: true,
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (value) => (value === true ? 'Yes' : 'No')
    },
    {
      title: 'Start Date',
      dataIndex: 'start_at',
      key: 'start_at',
      sorter: (a, b) => a.start_at - b.start_at,
      sortOrder: attrs.sortInfo.columnKey === 'start_at' && attrs.sortInfo.order,
      render: (data) => data && moment.unix(data).format('M/D/YYYY')
    },

    {
      title: 'End Date',
      dataIndex: 'end_at',
      key: 'end_at',
      sorter: (a, b) => a.end_at - b.end_at,
      sortOrder: attrs.sortInfo.columnKey === 'end_at' && attrs.sortInfo.order,
      render: (data) => data && moment.unix(data).format('M/D/YYYY')
    },

    {
      title: 'Model',
      dataIndex: 'equipment.model',
      key: 'equipment_model',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Model"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('equipment_model', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('equipment_model', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('equipment_model', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.equipment_model || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      }
    },

    {
      title: 'Customer',
      dataIndex: 'site',
      key: 'factor_customer_id',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Customer Number"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('factor_customer_id', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('factor_customer_id', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('factor_customer_id', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.factor_customer_id || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: (a, b) => a.factor_customer_id - b.factor_customer_id,
      sortOrder: attrs.sortInfo.columnKey === 'factor_customer_id' && attrs.sortInfo.order,
      render: (data) => {
        // Sometimes the customer data doesn't exist so I want to show customer number
        if (data && data.customer) {
          return (
            <>
              {data.customer.full_name}
              <small> ({data.customer.account_number})</small>
            </>
          );
        } else if (data && data.active) {
          return `Customer #${data.factor_customer_id}`;
        } else {
          return null;
        }
      }
    },
    {
      title: 'Lease Type',
      dataIndex: 'lease_type',
      key: 'equipment_lease_type_id',
      sorter: (a, b) => a.lease_type - b.lease_type,
      sortOrder: attrs.sortInfo.columnKey === 'equipment_lease_type_id' && attrs.sortInfo.order,
      render: (data) => data && data.title
    },
    {
      title: 'Rent Cycle',
      dataIndex: 'rent_cycle',
      key: 'rent_cycle_id',
      sorter: (a, b) => a.rent_cycle - b.rent_cycle,
      sortOrder: attrs.sortInfo.columnKey === 'rent_cycle_id' && attrs.sortInfo.order,
      render: (data) => data && data.title
    },
    {
      title: 'Rent Cycle Interval',
      dataIndex: 'rent_cycle_interval',
      key: 'rent_cycle_interval',
      sorter: (a, b) => a.rent_cycle_interval - b.rent_cycle_interval,
      sortOrder: attrs.sortInfo.columnKey === 'rent_cycle_interval' && attrs.sortInfo.order,
      render: (data) => data
    },
    {
      title: 'Rent Price',
      dataIndex: 'rent_price',
      key: 'rent_price',
      sorter: (a, b) => a.rent_price - b.rent_price,
      sortOrder: attrs.sortInfo.columnKey === 'rent_price' && attrs.sortInfo.order,
      render: (data) => !data !== null && Number(data).toFixed(2)
    },
    {
      visible: true,
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList();
  };

  const data = state.data
    ? state.data.map((item) => ({
        ...item,
        actions: (
          <div>
            <Link to={`/equipment_leases/${item.id}`}>
              <ProfileOutlined />
            </Link>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
              <EditOutlined />
            </a>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={attrs.removeResource.bind(this, item.id)}
            >
              <a href="#!">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </div>
        )
      }))
    : [];

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
        width="75%"
      >
        <EquipmentLeaseEditContainer
          handleCancel={toggleModal}
          id={resourceId}
          onSave={onModalSave}
        />
      </ResourceModal>

      <ResourceList
        data={data}
        extra={[
          <Button key="1" type="primary" onClick={toggleModal}>
            Create {name}
          </Button>
        ]}
        {...attrs}
      />
    </React.Fragment>
  );
}
