import {
  TRIP_EDIT_SET_TRIP_ID,
  TRIP_EDIT_SET_TICKETS,
  TRIP_EDIT_REMOVE_TICKET,
  TRIP_EDIT_SET_SEARCH_RESULT_TICKETS
} from './types';

const initialState = {
  dispatchId: null,
  tripId: null,
  tickets: [],
  searchResultTickets: []
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case TRIP_EDIT_SET_TRIP_ID:
      return {
        ...state,
        tripId: payload
      };
    case TRIP_EDIT_SET_TICKETS:
      return {
        ...state,
        tickets: payload
      };
    case TRIP_EDIT_REMOVE_TICKET:
      const tickets = state.tickets.filter((t) => t !== payload);
      return {
        ...state,
        tickets
      };
    case TRIP_EDIT_SET_SEARCH_RESULT_TICKETS:
      return {
        ...state,
        searchResultTickets: payload
      };
    default:
      return state;
  }
};
