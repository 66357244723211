import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import TripEditContext from './store/TripEditContext';
import TripEdit from './TripEdit';
import {
  initialState,
  reducer,
  SET_TRIP,
  SET_DISPATCH,
  SET_LOADING,
  SET_DISPATCH_ID,
  SET_TRIP_ID,
  SET_LOCATIONS,
  SET_STORES,
  SET_TRUCKS,
  SET_DRIVERS,
  SET_ROUTES,
  SET_PRODUCTS,
  REMOVE_ITEM,
  ADD_ITEM,
  ADD_SELECTED_TICKET_RESULTS_TO_TICKETS
} from './store/TripEditStore';

function TripEditContainer(props) {
  const { dispatch_id, trip_id } = props.match.params;
  const [state, dispatch] = useReducer(reducer, initialState);

  const setLoading = (status) => {
    dispatch({ type: SET_LOADING, payload: status });
  };

  const updateTrip = (data) => {
    setLoading(true);
    axios.patch(`/trips/${trip_id}`, data).then(
      (res) => {
        dispatch({ type: SET_TRIP, payload: res.data });
        setLoading(false);
      },
      (err) => {
        console.log(err, 'Display message that we cannot find this one');
      }
    );
  };

  const createTrip = (data) => {
    console.log(data);
    setLoading(true);
    axios.post(`/trips`, data).then(
      (res) => {
        const { id, dispatch_id } = res.data;
        props.history.push(`/dispatches/${dispatch_id}/trips/${id}/edit`);
      },
      (err) => {
        console.log(err, 'Display message that we cannot find this one');
      }
    );
  };

  // Load Trip/Ticket Information
  useEffect(() => {
    dispatch({ type: SET_DISPATCH_ID, payload: dispatch_id });
    dispatch({ type: SET_TRIP_ID, payload: trip_id });
    setLoading(true);

    // Fetch Dispatch
    axios.get(`/dispatches/${dispatch_id}`).then((res) => {
      const { store_id } = res.data;
      dispatch({ type: SET_DISPATCH, payload: res.data });

      // Fetch Resources used for form data
      axios
        .get('/locations?pageSize=1000')
        .then((res) => dispatch({ type: SET_LOCATIONS, payload: res.data.records }));

      axios
        .get(`/trucks?pageSize=1000&active=true&store_id=${store_id}`)
        .then((res) => dispatch({ type: SET_TRUCKS, payload: res.data.records }));

      axios
        .get(`/drivers?pageSize=1000&active=true&store_id=${store_id}`)
        .then((res) => dispatch({ type: SET_DRIVERS, payload: res.data.records }));

      axios
        .get(`/routes?pageSize=1000&columnKey=name&order=ascend&store_id=${store_id}`)
        .then((res) => dispatch({ type: SET_ROUTES, payload: res.data.records }));

      axios
        .get(`/products?pageSize=1000&columnKey=code&order=ascend`)
        .then((res) => dispatch({ type: SET_PRODUCTS, payload: res.data.records }));

      if (!trip_id) {
        setLoading(false);
        return;
      }

      // Fetch Trip
      axios.get(`/trips/${trip_id}`).then((res) => {
        dispatch({ type: SET_TRIP, payload: res.data });
        setLoading(false);
      });
    });
  }, [dispatch_id, trip_id]);

  const handleSaveTrip = (data) => {
    if (!trip_id) {
      createTrip(data);
    } else {
      updateTrip(data);
    }
  };

  const handleCancelTrip = () => {
    props.history.push(`/dispatches/${dispatch_id}`);
  };

  const actions = {
    addTickets: (tickets) => {
      dispatch({ type: ADD_SELECTED_TICKET_RESULTS_TO_TICKETS, payload: tickets });
    },
    addTicket: (id) => {
      dispatch({ type: ADD_ITEM, payload: { id, type: 'ticket' } });
    },
    addLocation: (id) => {
      dispatch({ type: ADD_ITEM, payload: { id, type: 'location' } });
    },
    removeItem: (id) => {
      dispatch({ type: REMOVE_ITEM, payload: id });
    }
  };

  const selectors = {};

  return (
    <TripEditContext.Provider value={{ state, dispatch, actions, selectors }}>
      <TripEdit handleCancelTrip={handleCancelTrip} handleSaveTrip={handleSaveTrip} />
    </TripEditContext.Provider>
  );
}

export default TripEditContainer;
