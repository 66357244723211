import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { handleError } from '../utils/handleError';

export default function useResourceEdit({ path, name, id, onSave = () => {} }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(null);

  useEffect(() => {
    form && form.getForm().resetFields();
    if (id) {
      fetchResource(id);
    }
    return () => {
      setData();
    };
  }, [id]);

  const fetchResource = (id) => {
    setLoading(true);
    axios
      .get(`${path}/${id}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => setLoading(false) && handleError(err));
  };

  const resetFields = () => {
    console.log('reset fields', form);
    form && form.getForm().resetFields();
  };

  const handleSubmit = (e, onSuccess = () => {}) => {
    form.getForm().validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        setLoading(false);
        e.preventDefault();

        // Clean Data -> Move Enpty to Null
        const data = {};

        for (const key of Object.keys(values)) {
          if (values[key] == '') {
            data[key] = null;
          } else {
            data[key] = values[key];
          }
        }

        console.log('data here', data, values);

        if (id) {
          // Patch
          axios
            .patch(`${path}/${id}`, { ...data })
            .then((res) => {
              message.success(`${name} has been updated`);
              onSave();
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              if (err.response && err.response.data && err.response.data.message) {
                message.error(err.response.data.message);
              } else {
                message.error(`An error occurred while updating the ${name}.`);
              }
            });
        } else {
          // Post
          console.log('post');
          axios
            .post(path, { ...data })
            .then((res) => {
              console.log('then');
              message.success(`${name} has been created`);
              onSave();
              setLoading(false);
              form.getForm().resetFields();
            })
            .catch((err) => {
              console.log('catch', err.response);
              setLoading(false);
              if (err.response && err.response.data && err.response.data.message) {
                message.error(err.response.data.message);
              } else if (err.response && err.response.data.details) {
                for (const detail of err.response.data.details) {
                  message.error(detail.message);
                }
              } else {
                message.error(`An error occurred while creating the ${name}.`);
              }
            });
        }
        onSuccess();
      }
    });
  };

  const attrs = {
    loading,
    setForm,
    handleSubmit,
    resetFields,
    setData
  };

  const state = {
    data,
    loading,
    form
  };

  return [state, attrs];
}
