import React, { useState, useEffect } from 'react';
import { Marker, Popup } from 'react-leaflet';
import FullscreenControl from 'react-leaflet-fullscreen';
import { Link } from 'react-router-dom';
import { Select, Button, Checkbox, Collapse, message } from 'antd';
import moment from 'moment';
import axios from 'axios';
import Ticket from '../../components/Ticket/Ticket';
import MarkerIcon from '../../components/MarkerIconV3/MarkerIconV3';
import DivIcon from '../../components/MarkerIcon/MarkerIcon';
import { googleStreet } from '../../components/ReactMap/mapLayers';

import ReactMap from '../../components/ReactMap/ReactMap';
import { COLORS, getTicketStatusColor } from '../../utils/constants';

const { Panel } = Collapse;
const { Option } = Select;

const position = [40.0599152, -78.5138748];
export default function SiteListMap(props) {
  const { trips = [], ticketStatuses = [], fetchDispatch = () => {} } = props;
  const [activeTrips, setActiveTrips] = useState({});
  const [hideCompletedTickets, setHideCompletedTickets] = useState(true);

  useEffect(() => {
    if (trips.length > 0 && Object.keys(activeTrips).length === 0) {
      // We don't want to overwrite if trips already exist
      const reduceTrips = trips.reduce((acc, trip) => {
        let obj = {};
        obj[trip.id] = true;
        return { ...acc, ...obj };
      }, {});
      setActiveTrips(reduceTrips);
    }
  }, [trips]);

  const handleRemoveTicket = ({ tripId, ticketId }) => {
    axios.patch(`/trips/${tripId}/tickets/${ticketId}/remove`).then((res) => {
      fetchDispatch();
      message.success(`Ticket successfully removed`);
    });
  };

  const handleMoveTicket = ({ tripId, ticketId, destinationTripId }) => {
    axios.patch(`/trips/${tripId}/tickets/${ticketId}/move/${destinationTripId}`).then((res) => {
      fetchDispatch();
      message.success(`Ticket successfully moved`);
    });
  };

  const handleSaveTicketStatus = ({ ticketId, statusId }) => {
    // setTicketStatusId(statusId);
    axios
      .patch(`/tickets/${ticketId}`, {
        ticket_status_id: statusId
      })
      .then((res) => {
        message.success(`Ticket successfully updated`);
        fetchDispatch();
      });
  };

  const markerColors = [
    '#baefed', // G
    '#ea8685', // G
    '#12CBC4', // G
    '#f9b8a1', // G
    COLORS.hintOfElusiveBlue,
    COLORS.mintyGreen,
    COLORS.megaMan,
    COLORS.yrielYellow
  ];

  const renderMarkers = (trips = []) => {
    let markers = [];

    for (let [index, trip] of trips.entries()) {
      if (!activeTrips[trip.id]) continue;
      const { items, positionings = [] } = trip;
      const backgroundColor = markerColors[index];

      if (positionings[0]) {
        const truckPosition = positionings[0];
        const { latitude, longitude, timestamp } = truckPosition;
        markers.push(
          <Marker
            key={'' + trip.id + positionings.id}
            position={[latitude, longitude]}
            icon={MarkerIcon({
              icon: 'fa-truck',
              backgroundColor: '#222',
              textBackgroundColor: backgroundColor
            })}
          >
            <Popup maxWidth={'95%'}>
              <div style={{ width: 250 }}>
                <strong>Last Update:</strong>{' '}
                {moment.unix(timestamp).format('MMMM Do YYYY, h:mm:ss A')}
              </div>
            </Popup>
          </Marker>
        );
      }

      for (let [index, item] of items.entries()) {
        const { ticket } = item;

        if (ticket) {
          const { ticket_status_id, site = {} } = ticket;
          if (hideCompletedTickets && ticket_status_id === 3) {
            continue;
          }

          const { delivery_detail = {} } = site;
          const { latitude, longitude } = delivery_detail;
          const text = (index) => <div>{index}</div>;

          // const opacity = ticket_status_id !== 1 && ticket_status_id !== 6 ? 0.5 : 1;
          if (latitude && longitude) {
            markers.push(
              <Marker
                // opacity={opacity}
                key={'' + trip.id + index}
                position={[latitude, longitude]}
                icon={DivIcon({
                  text: text(index + 1),
                  backgroundColor,
                  statusId: ticket_status_id
                })}
              >
                <Popup maxWidth={'95%'}>
                  <div style={{ marginBottom: 10 }}>
                    {/* { Removing this feature so nobody accidently deletes a ticket } */}
                    {/* <Button
                      onClick={() => handleRemoveTicket({ tripId: trip.id, ticketId: ticket.id })}
                    >
                      Remove Ticket
                    </Button> */}

                    <Button>
                      <Link to={`/tickets/${ticket.id}/edit`} target="_blank">
                        Edit Ticket
                      </Link>
                    </Button>

                    <Select
                      placeholder="Change Ticket Status"
                      style={{ width: 200, marginLeft: 10 }}
                      onChange={(statusId) =>
                        handleSaveTicketStatus({ statusId, ticketId: ticket.id })
                      }
                    >
                      {ticketStatuses.map((status) => (
                        <Option style={{ padding: 7 }} key={status.id} value={status.id}>
                          {status.title}
                        </Option>
                      ))}
                    </Select>

                    <Select
                      placeholder="Move Ticket"
                      style={{ width: 400, marginLeft: 10 }}
                      onChange={(destinationTripId) =>
                        handleMoveTicket({
                          tripId: trip.id,
                          ticketId: ticket.id,
                          destinationTripId
                        })
                      }
                    >
                      {trips.map((innerTrip, index) => {
                        const { id, truck = {}, driver = {}, title } = innerTrip;
                        const { code } = truck;
                        const { full_name } = driver;
                        if (trip.id === id) return; // avoid showing the same trip
                        return (
                          <Option style={{ padding: 7 }} key={id} value={id}>
                            <span
                              style={{
                                backgroundColor: markerColors[index],
                                padding: 5,
                                borderRadius: '50%',
                                marginRight: 5
                              }}
                            >
                              {code}
                            </span>{' '}
                            {full_name} - {title}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <Ticket ticketStatuses={ticketStatuses} data={ticket} onSave={fetchDispatch} />
                </Popup>
              </Marker>
            );
          }
        }
      }
    }
    return markers;
  };
  return (
    <>
      <Collapse
        defaultActiveKey={['1']}
        style={{
          position: 'absolute',
          top: 20,
          right: 20,
          zIndex: 1000,
          maxHeight: '75%',
          overflowY: 'scroll' /* Hide vertical scrollbar */,
          overflowX: 'hidden' /* Hide horizontal scrollbar */
        }}
      >
        <Panel header="Trip Legend" key="1">
          <>
            <Checkbox
              style={{ marginBottom: 10 }}
              checked={hideCompletedTickets}
              onChange={() => {
                setHideCompletedTickets(!hideCompletedTickets);
              }}
            >
              <span>Hide Completed Tickets</span>
            </Checkbox>
            <Collapse bordered={false} accordion={true} expandIconPosition="right">
              {trips.map((trip, index) => {
                const { truck = {}, driver = {}, title } = trip;
                const { code } = truck;
                const { full_name } = driver;

                return (
                  <Panel
                    key={index}
                    header={
                      <span style={{ overflow: 'hidden' }}>
                        <span
                          style={{
                            backgroundColor: markerColors[index],
                            padding: 5,
                            borderRadius: '50%',
                            marginRight: 5
                          }}
                        >
                          {code}
                        </span>{' '}
                        {full_name} - {title}
                      </span>
                    }
                    extra={
                      <div>
                        <Checkbox
                          style={{ marginLeft: 15 }}
                          key={index}
                          checked={activeTrips[trip.id]}
                          onClick={(event) => event.stopPropagation()}
                          onChange={(active) => {
                            let obj = {};
                            obj[trip.id] = !activeTrips[trip.id];
                            setActiveTrips({ ...activeTrips, ...obj });
                          }}
                        ></Checkbox>
                      </div>
                    }
                    key={index}
                  >
                    <div>
                      {trip.items.map((item, index) => {
                        const { ticket = {}, location = {} } = item;
                        let itemTitle = '';
                        let lastEvent;
                        let style = {};
                        if (ticket) {
                          const { ticket_status_id, site = {}, events = [] } = ticket;
                          const { customer = {} } = site;
                          lastEvent = events[0]
                            ? moment.unix(events[0].timestamp).format('LTS')
                            : '';

                          itemTitle = customer
                            ? `${customer.last_name}, ${customer.first_name}`
                            : '';

                          switch (ticket_status_id) {
                            case 3:
                              style = { opacity: 0.4, textDecoration: 'line-through' };
                              break;
                            case 8:
                            case 5:
                              style = { opacity: 0.65, color: 'red' };
                              break;
                            default:
                              style = {};
                          }
                        } else {
                          itemTitle = location ? <strong>{location.title}</strong> : '';
                        }

                        return (
                          <div key={index} style={{ padding: 2 }}>
                            <span style={{ ...style }}>
                              {index + 1} - {itemTitle}
                            </span>{' '}
                            {lastEvent && (
                              <span style={{ ...style, textDecoration: 'none' }}>
                                ({lastEvent})
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </Panel>
                );
              })}
            </Collapse>
          </>
        </Panel>
      </Collapse>
      <ReactMap height="100%" center={position} zoom={10} defaultTile={googleStreet}>
        <FullscreenControl position="topleft" forceSeparateButton={true} />
        {renderMarkers(trips)}
      </ReactMap>
    </>
  );
}
