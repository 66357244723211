import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { message, PageHeader, Row, Form, Input } from 'antd';
import DivIcon from '../../components/DivIcon/DivIcon';
import Body from '../../components/Body/Body';
import Content from '../../components/Content/Content';

import { Marker } from 'react-leaflet';
import CustomSearchHandler from './CustomSearchHandler';
import { googleHybrid } from '../../components/ReactMap/mapLayers';
import Loader from '../../components/Loader/Loader';
import useResourceEdit from '../../hooks/useResourceEdit';
import ResourceFormActions from '../../components/ResourceFormActions/ResourceFormActions';
import ReactMap from '../../components/ReactMap/ReactMap';

export default function SiteEditContainer(props) {
  const id = props.match.params.site_id;
  const [state, attrs] = useResourceEdit({ path: '/sites', name: 'Site', id });
  const { loading, data = {} } = state;
  const { handleSubmit, setForm } = attrs;
  const { delivery_detail = {}, customer = {} } = data;
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  const handleSave = () => {
    axios
      .patch(`/delivery_details/${delivery_detail.id}`, {
        latitude,
        longitude
      })
      .then((res) => {
        message.success(`Site successfully updated`);
      });
  };

  useEffect(() => {
    setLatitude(delivery_detail.latitude);
    setLongitude(delivery_detail.longitude);
  }, [delivery_detail]);

  const position = latitude && longitude ? [latitude, longitude] : [40.0599152, -78.5138748];

  if (loading) {
    return <Loader display={true} />;
  }

  return (
    <React.Fragment>
      <Content>
        <PageHeader title="Edit Site Location" />
        <Body>
          <ReactMap height={400} center={position} zoom={19} defaultTile={googleHybrid}>
            <CustomSearchHandler
              position="topleft"
              zoom={1}
              showMarker={false}
              closeResultsOnClick={true}
              inputPlaceholder="Search for a place..."
              callback={(latLng, info, raw) => {
                setLongitude(latLng.lng);
                setLatitude(latLng.lat);
              }}
            />
            {delivery_detail && (
              <Marker
                position={position}
                draggable
                icon={DivIcon({ icon: 'fa-home' })}
                onMoveend={(e) => {
                  const coords = e.target.getLatLng();
                  setLatitude(coords.lat);
                  setLongitude(coords.lng);
                }}
              />
            )}
          </ReactMap>
          {/* <StoreForm data={data} stores={stores} ref={(ref) => setForm(ref)} /> */}

          <Row style={{ marginTop: 8 }}>
            <div style={{ marginTop: 8 }}>
              <strong>Delivery Address</strong>
              <p>
                {(customer.last_name, customer.first_name)}
                <br />
                {delivery_detail.address_line_1}
                <br />
                {delivery_detail.address_line_2}
              </p>
            </div>

            <div style={{ marginTop: 8 }}>
              <strong>Special Instructions</strong>
              <p>
                {delivery_detail.special_instructions_1}
                <br />
                {delivery_detail.special_instructions_2}
              </p>
            </div>

            <Form.Item label="Latitude">
              <Input
                placeholder="Latitude"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
              />
            </Form.Item>

            <Form.Item label="Longitude">
              <Input
                placeholder="Longitude"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
              />
            </Form.Item>
          </Row>
          <ResourceFormActions id={id} handleCancel={() => {}} handleSubmit={handleSave} />
        </Body>
      </Content>
    </React.Fragment>
  );
}
