import React, { useState, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { message, Modal, Button, Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import TripEditContext from '../../store/TripEditContext';
import {
  SET_TICKET_RESULTS,
  SET_TOTAL_TIME,
  SET_TOTAL_DISTANCE,
  SET_ROUTE_SHAPE,
  SET_ITEMS
} from '../../store/TripEditStore';
import TicketSearchForm from '../../../../components/forms/TicketSearchForm/TicketSearchForm';
import moment from 'moment';
import { metersToMiles } from '../../../../utils/units';

import styles from './TicketListHeader.module.css';

function TicketListHeader(props) {
  const { state, dispatch } = useContext(TripEditContext);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [findTicketsForm, setFindTicketsForm] = useState();
  const {
    dispatch: { store_id },
    tickets,
    routes,
    products,
    salesTypes,
    locations,
    originLocationId,
    destinationLocationId
  } = state;

  const toggleModal = () => {
    setModalVisible((value) => !value);
  };

  const menu = () => (
    <Menu>
      <Menu.Item key="0">
        <a
          href="#!"
          onClick={() => {
            dispatch({ type: SET_ITEMS, payload: [] });
          }}
        >
          Clear Tickets
        </a>
      </Menu.Item>
    </Menu>
  );

  const handleRoute = (e, updatedItems) => {
    const hideLoader = message.loading('Routing Tickets...', 0);
    const { items } = state;
    const currentItems = updatedItems || items;

    let waypoints = currentItems.map((i) => {
      if (i.ticket) {
        return `geo!${i.ticket.site.delivery_detail.latitude},${i.ticket.site.delivery_detail.longitude}`;
      } else {
        return `geo!${i.location.latitude},${i.location.longitude}`;
      }
    });

    axios
      .get('/here/basic', {
        params: {
          waypoints
        }
      })
      .then((res) => {
        const deliveryTimeInSeconds = items.reduce((sum, i) => {
          const perStopInSeconds = 7 * 60;
          const gallonsInSeconds = i.ticket ? (i.ticket.delivery_gallons / 60) * 60 : 0; // second argument represents gallons per minute pumped
          return sum + perStopInSeconds + gallonsInSeconds;
        }, 0);

        const totalTimeInSeconds =
          res.data.response.route[0].summary.baseTime + deliveryTimeInSeconds;
        const totalDistanceInMeters = res.data.response.route[0].summary.distance;
        const routeShape = res.data.response.route[0].shape;
        dispatch({ type: SET_TOTAL_TIME, payload: totalTimeInSeconds });
        dispatch({
          type: SET_TOTAL_DISTANCE,
          payload: metersToMiles(totalDistanceInMeters)
        });
        dispatch({ type: SET_ROUTE_SHAPE, payload: routeShape });
      });
    hideLoader();
  };

  const createStopObject = ({ id, latitude, longitude }) => {
    return {
      Costs: {
        CostOfStop: 0,
        HoursPerStop: 0,
        Loaded: true,
        OnDuty: false,
        UseOrigin: true
      },
      IsViaPoint: false,
      Address: null,
      Coords: {
        Lat: latitude,
        Lon: longitude
      },
      Label: id,
      PlaceName: null
    };
  };

  const handleRouteTrimble = () => {
    const hideLoader = message.loading('Routing Tickets...', 0);
    const { tickets } = state;
    const ticketsCopy = [...tickets];
    const params = {};
    let stops = [];
    let start, end;

    if (originLocationId) {
      const originLocation = locations.find((l) => l.id === originLocationId);
      start = createStopObject({
        id: 'start',
        longitude: originLocation.longitude,
        latitude: originLocation.latitude
      });
    } else {
      const t = ticketsCopy[0];
      start = createStopObject({
        id: t.id,
        longitude: t.site.delivery_detail.longitude,
        latitude: t.site.delivery_detail.latitude
      });
      delete ticketsCopy[0];
    }

    if (destinationLocationId) {
      const destinationLocation = locations.find((l) => l.id === destinationLocationId);
      end = createStopObject({
        id: 'end',
        longitude: destinationLocation.longitude,
        latitude: destinationLocation.latitude
      });
    } else {
      const t = ticketsCopy[ticketsCopy.length - 1];
      end = createStopObject({
        id: t.id,
        longitude: t.site.delivery_detail.longitude,
        latitude: t.site.delivery_detail.latitude
      });
      delete ticketsCopy[ticketsCopy.length - 1];
    }

    stops.push(start);
    for (const [index, t] of ticketsCopy.entries()) {
      if (t) {
        stops.push(
          createStopObject({
            id: t.id,
            longitude: t.site.delivery_detail.longitude,
            latitude: t.site.delivery_detail.latitude
          })
        );
      }
    }
    stops.push(end);

    // console.log('What are params?', params);
    axios
      .post('/trimble/route', {
        stops,
        routeOptions: {
          RouteOptimization: 0
        }
      })
      .then((res) => {
        const { ReportLines } = res.data[0];
        const { shape } = res.data;
        const lastReportLine = ReportLines[ReportLines.length - 1];
        const { TMiles, THours } = lastReportLine;
        const travelTime = THours.split(':');
        const travelTimeInSeconds =
          parseInt(travelTime[0]) * 60 * 60 +
          parseInt(travelTime[1]) * 60 +
          parseInt(travelTime[2]);

        const deliveryTimeInSeconds = tickets.reduce((sum, t) => {
          const perStopInSeconds = 7 * 60;
          const gallonsInSeconds = (t.delivery_gallons / 60) * 60; // second argument represents gallons per minute pumped
          return sum + perStopInSeconds + gallonsInSeconds;
        }, 0);

        const totalTimeInSeconds = travelTimeInSeconds + deliveryTimeInSeconds;

        dispatch({ type: SET_TOTAL_TIME, payload: totalTimeInSeconds });
        dispatch({
          type: SET_TOTAL_DISTANCE,
          payload: TMiles
        });
        dispatch({ type: SET_ROUTE_SHAPE, payload: shape });

        hideLoader();
      });
  };

  const handleOptimize = () => {
    const hideLoader = message.loading('Optimizing Ticket Order...', 0);
    const { items } = state;
    const itemsCopy = [...items];
    const params = {};

    // Set Starting Location
    const startItem = itemsCopy[0];
    params.start = startItem.ticket
      ? `${startItem.id};${startItem.ticket.site.delivery_detail.latitude},${startItem.ticket.site.delivery_detail.longitude}`
      : `${startItem.id};${startItem.location.latitude},${startItem.location.longitude}`;

    delete itemsCopy[0];

    // Set Ending Location
    const endItem = itemsCopy[itemsCopy.length - 1];
    params.end = endItem.ticket
      ? `${endItem.id};${endItem.ticket.site.delivery_detail.latitude},${endItem.ticket.site.delivery_detail.longitude}`
      : `${endItem.id};${endItem.location.latitude},${endItem.location.longitude}`;

    delete itemsCopy[itemsCopy.length - 1];

    for (const [index, item] of itemsCopy.entries()) {
      if (item) {
        params[`destination${index}`] = item.ticket
          ? `${item.id};${item.ticket.site.delivery_detail.latitude},${item.ticket.site.delivery_detail.longitude}`
          : `${item.id};${item.location.latitude},${item.location.longitude}`;
      }
    }

    axios
      .get('/here/findsequence', {
        params
      })
      .then((res) => {
        const waypoints = res.data.results[0].waypoints;
        let updatedItems = [];
        for (const waypoint of waypoints) {
          if (!isNaN(waypoint.id)) {
            updatedItems.push(items.find((i) => i.id === parseInt(waypoint.id)));
          }
        }

        dispatch({ type: SET_ITEMS, payload: updatedItems });
        message.success('Tickets have been optimized');
        hideLoader();
        // handleRoute(null, updatedItems);
      });
  };

  const handleOptimizeTrimble = () => {
    const hideLoader = message.loading('Optimizing Ticket Order...', 0);
    const { tickets } = state;
    const ticketsCopy = [...tickets];
    const params = {};
    let stops = [];
    let start, end;

    if (originLocationId) {
      const originLocation = locations.find((l) => l.id === originLocationId);
      start = createStopObject({
        id: 'start',
        longitude: originLocation.longitude,
        latitude: originLocation.latitude
      });
    } else {
      const t = ticketsCopy[0];
      start = createStopObject({
        id: t.id,
        longitude: t.site.delivery_detail.longitude,
        latitude: t.site.delivery_detail.latitude
      });
      delete ticketsCopy[0];
    }

    if (destinationLocationId) {
      const destinationLocation = locations.find((l) => l.id === destinationLocationId);
      end = createStopObject({
        id: 'end',
        longitude: destinationLocation.longitude,
        latitude: destinationLocation.latitude
      });
    } else {
      const t = ticketsCopy[ticketsCopy.length - 1];
      end = createStopObject({
        id: t.id,
        longitude: t.site.delivery_detail.longitude,
        latitude: t.site.delivery_detail.latitude
      });
      delete ticketsCopy[ticketsCopy.length - 1];
    }

    stops.push(start);
    for (const [index, t] of ticketsCopy.entries()) {
      if (t) {
        stops.push(
          createStopObject({
            id: t.id,
            longitude: t.site.delivery_detail.longitude,
            latitude: t.site.delivery_detail.latitude
          })
        );
      }
    }
    stops.push(end);

    // console.log('What are params?', params);
    axios
      .post('/trimble/route', {
        stops,
        routeOptions: {
          RouteOptimization: 2 // Send Optimize Flag
        }
      })
      .then((res) => {
        // Update Ticket Order
        const { ReportLines } = res.data[0];
        let updatedTickets = [];
        for (const reportLine of ReportLines) {
          const { Stop } = reportLine;
          const { Coords } = Stop;
          if (!isNaN(Stop.Label)) {
            updatedTickets.push(tickets.find((t) => t.id === parseInt(Stop.Label)));
          }
        }
        dispatch({ type: SET_ITEMS, payload: updatedTickets });

        // Update Shape, Distance, Time
        const { shape } = res.data;
        const lastReportLine = ReportLines[ReportLines.length - 1];
        const { TMiles, THours } = lastReportLine;
        const travelTime = THours.split(':');
        const travelTimeInSeconds =
          parseInt(travelTime[0]) * 60 * 60 +
          parseInt(travelTime[1]) * 60 +
          parseInt(travelTime[2]);

        const deliveryTimeInSeconds = tickets.reduce((sum, t) => {
          const perStopInSeconds = 7 * 60;
          const gallonsInSeconds = (t.delivery_gallons / 60) * 60; // second argument represents gallons per minute pumped
          return sum + perStopInSeconds + gallonsInSeconds;
        }, 0);

        const totalTimeInSeconds = travelTimeInSeconds + deliveryTimeInSeconds;

        dispatch({ type: SET_TOTAL_TIME, payload: totalTimeInSeconds });
        dispatch({
          type: SET_TOTAL_DISTANCE,
          payload: TMiles
        });
        dispatch({ type: SET_ROUTE_SHAPE, payload: shape });

        // dispatch({ type: SET_ITEMS, payload: updatedTickets });
        // handleRoute(null, updatedTickets);
        hideLoader();
      });
  };

  const handleSubmit = (e) => {
    console.log('What is store id', store_id);
    setLoading(true);
    e.preventDefault();
    const values = findTicketsForm.getForm().getFieldsValue();

    // Convert date range to start and end date
    if (values.range.length > 0) {
      values.start_date = moment(values.range[0]).format('YYYY-MM-DD');
      values.end_date = moment(values.range[1]).format('YYYY-MM-DD');
      delete values.range;
    }

    let params = [];

    const keys = Object.keys(values).filter((o) =>
      typeof values[o] !== 'undefined' ? true : false
    );

    for (const key of keys) {
      if (Array.isArray(values[key])) {
        for (const item of values[key]) {
          params.push(`${key}=${item}`);
        }
      } else {
        params.push(`${key}=${values[key]}`);
      }
    }

    params.push('pageSize=1000'); // Keep the results from pagination and limit to 1000
    params.push('unassigned=true');
    params.push(`store_id=${store_id}`);
    const queryString = params.join('&');

    // Axios tickets and load up
    axios.get(`/tickets?${queryString}`).then((res) => {
      dispatch({ type: SET_TICKET_RESULTS, payload: res.data.records });
      toggleModal();
      setLoading(false);
    });
  };

  return (
    <div className={styles.ticketListHeader}>
      <div className={styles.actions}>
        <Button type="link" className={styles.link} onClick={toggleModal}>
          Find Tickets
        </Button>
        <Button type="link" className={styles.link} onClick={handleRoute}>
          Route
        </Button>
        <Button type="link" className={styles.link} onClick={handleOptimize}>
          Optimize
        </Button>
        <Dropdown overlay={menu.bind(this)} trigger={['click']}>
          <a href="#!">
            <MoreOutlined />
          </a>
        </Dropdown>
      </div>
      <Modal
        visible={modalVisible}
        title="Find Available Tickets"
        onOk={handleSubmit}
        onCancel={toggleModal}
        footer={[
          <Button key="back" onClick={toggleModal}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Search
          </Button>
        ]}
      >
        <TicketSearchForm
          ref={(ref) => setFindTicketsForm(ref)}
          routes={routes}
          products={products}
          salesTypes={salesTypes}
        />
      </Modal>
    </div>
  );
}

TicketListHeader.propTypes = {};

export default TicketListHeader;
