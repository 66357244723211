import React, { useEffect } from 'react';
import { Tag, Button, Icon, Popconfirm } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ResourceList from '../../../components/ResourceList/ResourceList';
import useResourceList from '../../../hooks/useResourceList';
import { calculateTotalGallonsByProduct } from '../../TripEdit/store/TripEditStore';

export default function TripItemList({ items = [], products = [] }) {
  let legTicketItems = [];

  // Inject items with gallon totals
  const itemsWithGallons = items.map((item) => {
    if (item.type === 'location') {
      item.totalGallonsByProduct = calculateTotalGallonsByProduct(legTicketItems);
      legTicketItems = [];
    } else {
      legTicketItems.push(item);
    }
    return item;
  });

  const handleGallonsByProduct = (totalGallonsByProduct) => {
    if (products.length <= 0) return;
    let tags = [];
    for (let [key, value] of Object.entries(totalGallonsByProduct)) {
      const product = products.find((product) => product.id == key);
      tags.push(
        <p key={key}>
          <Tag color={`#${product.hex_code}`}>
            {product.title}: {value && value.toFixed(2)}
          </Tag>
        </p>
      );
    }
    return tags;
  };

  const columns = [
    {
      key: 'ticket_status_id',
      title: 'Status',
      render: (data) => {
        if (data.ticket) {
          switch (data.ticket.ticket_status_id) {
            case 1:
            case 2:
              return <Tag color="blue">Open</Tag>;
            case 3:
              return <Tag color="green">Delivered</Tag>;
            case 4:
              return <Tag color="green">Posted</Tag>;
            case 5:
              return <Tag color="red">Ticket Removed</Tag>;
            case 6:
              return <Tag color="yellow">Active Delivery</Tag>;
            case 7:
              return <Tag color="red">Special</Tag>;
            case 8:
              return <Tag color="red">Undeliverable</Tag>;
            default:
              return '';
          }
        }
      }
    },
    {
      key: 'id',
      title: 'Type',
      render: (data) => data.ticket && data.ticket.ticket_number
    },
    {
      title: 'Customer #',
      render: (data) =>
        data.ticket ? data.ticket.account_number : <strong>{data.location.title}</strong>
    },
    {
      title: 'Site #',
      render: (data) => data.ticket && data.ticket.tank_id
    },
    {
      title: 'Name',
      render: (data) =>
        data.ticket
          ? `${data.ticket.site.customer.last_name}, ${data.ticket.site.customer.first_name}`
          : handleGallonsByProduct(data.totalGallonsByProduct)
    },
    {
      title: 'Delivery Address',
      render: (data) =>
        data.ticket &&
        `${data.ticket.site.delivery_detail.address_line_1}, ${data.ticket.site.delivery_detail.address_line_2}`
    },
    {
      title: 'Gallons',
      render: (data) => data.ticket && data.ticket.delivery_gallons
    },
    {
      title: 'Fill',
      render: (data) => data.ticket && data.ticket.fill && 'Fill'
    },
    {
      title: 'Route',
      render: (data) => data.ticket && data.ticket.site.route
    }
  ];

  return (
    <React.Fragment>
      <ResourceList
        showHeader={false}
        columns={columns}
        data={itemsWithGallons}
        name="Ticket"
        pagination={{ pageSize: 1000 }}
      />
    </React.Fragment>
  );
}
