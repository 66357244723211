import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loader from '../../components/Loader/Loader';
import useResourceEdit from '../../hooks/useResourceEdit';
import ResourceFormActions from '../../components/ResourceFormActions/ResourceFormActions';
import StoreForm from '../../components/forms/StoreForm/StoreForm';

export default function DriverEditContainer({ id, handleCancel = () => {}, onSave = () => {} }) {
  const [state, attrs] = useResourceEdit({ path: '/stores', name: 'Store', id, onSave });
  const { loading, data } = state;
  const { handleSubmit, setForm } = attrs;
  const [stores, setStores] = useState();

  // Run First Load
  useEffect(() => {
    axios.get(`/stores`).then((res) => setStores(res.data.records));
    return () => {
      setStores();
    };
  }, []);

  if (loading) {
    return <Loader display={true} />;
  }

  return (
    <React.Fragment>
      <StoreForm data={data} stores={stores} ref={(ref) => setForm(ref)} />
      <ResourceFormActions id={id} handleCancel={handleCancel} handleSubmit={handleSubmit} />
    </React.Fragment>
  );
}
