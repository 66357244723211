import React, { useState, useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, ProfileOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import TruckEditContainer from '../TruckEdit/TruckEditContainer';

export default function TruckListContainer() {
  let name = 'Truck';
  const path = '/trucks';
  const [state, attrs] = useResourceList(path, name);
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);

  const columns = [
    {
      title: 'Store',
      dataIndex: 'store_id',
      key: 'store_id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: attrs.sortInfo.columnKey === 'store_id' && attrs.sortInfo.order
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code - b.code,
      sortOrder: attrs.sortInfo.columnKey === 'code' && attrs.sortInfo.order
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortOrder: attrs.sortInfo.columnKey === 'description' && attrs.sortInfo.order
    },
    {
      title: 'Number of Compartments',
      dataIndex: 'total_compartments',
      key: 'total_compartments',
      sorter: (a, b) => a.total_compartments - b.total_compartments,
      sortOrder: attrs.sortInfo.columnKey === 'total_compartments' && attrs.sortInfo.order
    },
    {
      title: 'Total Capacity',
      dataIndex: 'total_capacity_in_gallons',
      key: 'total_capacity_in_gallons',
      sorter: (a, b) => a.total_capacity_in_gallons - b.total_capacity_in_gallons,
      sortOrder: attrs.sortInfo.columnKey === 'total_capacity_in_gallons' && attrs.sortInfo.order
    },
    {
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList();
  };

  const data = state.data
    ? state.data.map((item) => ({
        ...item,
        actions: (
          <div>
            <Link to={`/trucks/${item.id}`}>
              <ProfileOutlined />
            </Link>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
              <EditOutlined />
            </a>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={attrs.removeResource.bind(this, item.id)}
            >
              <a href="#!">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </div>
        )
      }))
    : [];

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
      >
        <TruckEditContainer handleCancel={toggleModal} id={resourceId} onSave={onModalSave} />
      </ResourceModal>

      <ResourceList
        data={data}
        extra={[
          <Button key="1" type="primary" onClick={toggleModal}>
            Create {name}
          </Button>
        ]}
        {...attrs}
      />
    </React.Fragment>
  );
}
