import React from 'react';
import { Layout } from 'antd';
import styles from './Body.module.css';

const { Content } = Layout;

function ContentWrapper(props) {
  return (
    <Layout>
      <Content className={styles.body}>{props.children}</Content>
    </Layout>
  );
}

export default ContentWrapper;
