import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Form, Input, Select, DatePicker } from 'antd';
import styles from './EquipmentForm.module.css';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

function EquipmentForm({ form, data = {}, stores = [], manufacturers = [] }) {
  const {
    id = null,
    store_id = null,
    model = null,
    serial = null,
    description = null,
    capacity = null,
    manufacturer_id = null,
    manufactured_at = null,
    purchased_at = null,
    purchase_cost = null,
    inspection_next_at = null,
    inspection_interval = null,
    profit_center_code = null,
    is_invoice_processed = null,
    invoice_reference = null,
    active = null
  } = data;

  const { getFieldDecorator } = form;
  const dateFormat = 'YYYY-MM-DD';

  const isEdit = id > 0 ? true : false;

  return (
    <Form className={styles.form} layout="vertical">
      <Row>
        <Col span={10}>
          <Form.Item label="Model">
            {getFieldDecorator('model', {
              initialValue: model || '',
              rules: [{ required: true }]
            })(<Input placeholder="420-001" disabled={isEdit} />)}
          </Form.Item>
          <Form.Item label="Serial">
            {getFieldDecorator('serial', {
              initialValue: serial || '',
              rules: []
            })(<Input placeholder="000001" />)}
          </Form.Item>

          <Form.Item label="Description">
            {getFieldDecorator('description', {
              initialValue: description || ''
            })(<TextArea rows={3} placeholder="420lb Propane Cylinder Tank" />)}
          </Form.Item>

          <Form.Item label="Capacity">
            {getFieldDecorator('capacity', {
              initialValue: capacity || '',
              rules: []
            })(<Input placeholder="96" />)}
          </Form.Item>

          <Form.Item label="Manufacturer">
            {getFieldDecorator('manufacturer_id', {
              initialValue: manufacturer_id || '',
              rules: []
            })(
              <Select placeholder="Select Manufacturer">
                {manufacturers.map((m) => (
                  <Option key={m.id} value={m.id}>
                    {m.title}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Date Manufactured">
            {getFieldDecorator('manufactured_at', {
              initialValue: manufactured_at ? moment(moment(manufactured_at), dateFormat) : null
            })(<DatePicker format={dateFormat} style={{ width: '100%' }} />)}
          </Form.Item>

          <Form.Item label="Date Purchased">
            {getFieldDecorator('purchased_at', {
              initialValue: purchased_at ? moment(moment(purchased_at), dateFormat) : null
            })(<DatePicker format={dateFormat} style={{ width: '100%' }} />)}
          </Form.Item>

          <Form.Item label="Purchase Cost">
            {getFieldDecorator('purchase_cost', {
              initialValue: purchase_cost || '',
              rules: []
            })(<Input placeholder="365.00" />)}
          </Form.Item>
        </Col>

        <Col span={10} offset={2}>
          <Form.Item label="Inspection Interval (Days)">
            {getFieldDecorator('inspection_interval', {
              initialValue: inspection_interval || '',
              rules: []
            })(<Input placeholder="365" />)}
          </Form.Item>

          <Form.Item label="Next Inspection Due">
            {getFieldDecorator('inspection_next_at', {
              initialValue: inspection_next_at
                ? moment(moment(inspection_next_at), dateFormat)
                : null
            })(<DatePicker format={dateFormat} style={{ width: '100%' }} />)}
          </Form.Item>

          <Form.Item label="Profit Center">
            {getFieldDecorator('profit_center_code', {
              initialValue: profit_center_code || '',
              rules: [{ required: true }]
            })(
              <Select placeholder="Profit Center">
                <Option value="102">Bedford Fuel Oil : 102</Option>
                <Option value="118">Bedford Propane : 118</Option>
                <Option value="104">Bedford Warehouse : 104</Option>
                <Option value="509">New Cumberland Fuel Oil : 509</Option>
                <Option value="119">New Cumberland Propane : 119</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Invoice Processed?">
            {getFieldDecorator('is_invoice_processed', {
              initialValue: is_invoice_processed !== null ? is_invoice_processed.toString() : '',
              rules: [{ required: true }]
            })(
              <Select placeholder="Invoice Processed?">
                <Option value="true">Yes</Option>
                <Option value="false">No</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Invoice Reference">
            {getFieldDecorator('invoice_reference', {
              initialValue: invoice_reference || ''
            })(<TextArea rows={3} placeholder="Invoice #000100" />)}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

EquipmentForm.propTypes = {
  form: PropTypes.object,
  data: PropTypes.object
};

const WrappedEquipmentForm = Form.create({ name: 'EquipmentForm' })(EquipmentForm);
export default WrappedEquipmentForm;
