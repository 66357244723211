import React from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Input, Select, Button } from 'antd';
import styles from './ManufacturerForm.module.css';

const { Option } = Select;
const { TextArea } = Input;

function ManufacturerForm({ form, data = {} }) {
  const { title = null, address_line_1 = null, address_line_2 = null, notes = null } = data;
  const { getFieldDecorator } = form;

  return (
    <Form className={styles.form} layout="vertical">
      <Row>
        <Form.Item label="Title">
          {getFieldDecorator('title', {
            initialValue: title || '',
            rules: [{ required: true }]
          })(<Input placeholder="Title" />)}
        </Form.Item>

        <Form.Item label="Address Line 1">
          {getFieldDecorator('address_line_1', {
            initialValue: address_line_1 || '',
            rules: []
          })(<Input placeholder="Address Line 1" />)}
        </Form.Item>

        <Form.Item label="Address Line 2">
          {getFieldDecorator('address_line_2', {
            initialValue: address_line_2 || '',
            rules: []
          })(<Input placeholder="Address Line 2" />)}
        </Form.Item>

        <Form.Item label="Notes">
          {getFieldDecorator('notes', {
            initialValue: notes || ''
          })(<TextArea rows={3} placeholder="Notes" />)}
        </Form.Item>
      </Row>
    </Form>
  );
}

ManufacturerForm.propTypes = {
  drivers: PropTypes.array,
  trucks: PropTypes.array,
  dispatch: PropTypes.object
};

const WrappedManufacturerForm = Form.create({ name: 'ManufacturerForm' })(ManufacturerForm);
export default WrappedManufacturerForm;
