import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import ManufacturerEditContainer from '../ManufacturerEdit/ManufacturerEditContainer';

export default function ManufacturerListContainer() {
  let name = 'Manufacturer';
  const path = '/manufacturers';
  const [state, attrs] = useResourceList(path, name);
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title - b.title,
      sortOrder: attrs.sortInfo.columnKey === 'title' && attrs.sortInfo.order
    },
    {
      title: 'Address Line 1',
      dataIndex: 'address_line_1',
      key: 'address_line_1',
      sorter: (a, b) => a.address_line_1 - b.address_line_1,
      sortOrder: attrs.sortInfo.columnKey === 'address_line_1' && attrs.sortInfo.order
    },

    {
      title: 'Address Line 2',
      dataIndex: 'address_line_2',
      key: 'address_line_2',
      sorter: (a, b) => a.address_line_2 - b.address_line_2,
      sortOrder: attrs.sortInfo.columnKey === 'address_line_2' && attrs.sortInfo.order
    },

    {
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList();
  };

  const data = state.data
    ? state.data.map((item) => ({
        ...item,
        actions: (
          <div>
            <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
              <EditOutlined />
            </a>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={attrs.removeResource.bind(this, item.id)}
            >
              <a href="#!">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </div>
        )
      }))
    : [];

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
      >
        <ManufacturerEditContainer
          handleCancel={toggleModal}
          id={resourceId}
          onSave={onModalSave}
        />
      </ResourceModal>

      <ResourceList
        {...attrs}
        data={data}
        extra={[
          <Button key="1" type="primary" onClick={toggleModal}>
            Create {name}
          </Button>
        ]}
      />
    </React.Fragment>
  );
}
