import { ReactLeafletSearch } from 'react-leaflet-search';
import { withLeaflet } from 'react-leaflet';

class CustomSearchHandler extends ReactLeafletSearch {
  latLngHandler(latLng, info, raw) {
    super.latLngHandler(latLng, info, raw);
    // Add our custom prop to call, so sweet
    const callback = this.props.callback;
    callback(latLng, info, raw);
  }
}
export default withLeaflet(CustomSearchHandler);
