import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Body from '../../components/Body/Body';
import Content from '../../components/Content/Content';
import { Tag, PageHeader, Breadcrumb, Descriptions, Statistic, Row, Col, Icon } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { handleError } from '../../utils/handleError';
import { secondsToHoursMinutes } from '../../utils/units';
import { calculateTotalGallonsByProduct } from '../TripEdit/store/TripEditStore';
import TripItemList from './TripItemList/TripItemList';

import styles from './TripShowContainer.module.css';

export default function TripShowContainer(props) {
  const dispatchId = props.match.params.dispatch_id;
  const tripId = props.match.params.trip_id;
  const [trip, setTrip] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    start_at,
    title,
    total_distance_in_miles,
    total_time_in_seconds,
    total_tickets,
    total_gallons,
    truck = {},
    driver = {},
    items = []
  } = trip;

  useEffect(() => {
    fetchTrip(tripId);
  }, [tripId]);

  const fetchTrip = (id) => {
    setLoading(true);
    axios
      .get(`/trips/${tripId}`)
      .then((res) => {
        setTrip(res.data);
        axios.get('/products').then((res) => {
          setProducts(res.data.records);
          setLoading(false);
        });
      })
      .catch((err) => handleError(err));
  };

  // const fetchDispatch = (id) => {
  //   setLoading(true);
  //   axios
  //     .get(`/dispatches/${dispatchId}`)
  //     .then((res) => {
  //       setDispatch(res.data);
  //       setLoading(false);
  //     })
  //     .catch((err) => handleError(err));
  // };

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to={`/dispatches/${dispatchId}`}>Trips</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{title}</Breadcrumb.Item>
    </Breadcrumb>
  );

  const handleGallonsByProduct = () => {
    const totalGallonsByProduct = calculateTotalGallonsByProduct(items);
    let stats = [];
    for (let [key, value] of Object.entries(totalGallonsByProduct)) {
      const product = products.find((product) => product.id == key);
      if (product) {
        stats.push(
          <Col key={key} span={6}>
            <Statistic title={product.title} value={value && value.toFixed(2)} />
          </Col>
        );
      }
    }
    return stats;
  };

  return (
    <React.Fragment>
      <Content className={styles.header} breadcrumb={breadcrumb}>
        <PageHeader />
        <Body>
          <Row gutter={16}>
            <Col span={6}>
              <Statistic title="Estimated Start" value={moment.unix(start_at).format('lll')} />
            </Col>
            <Col span={6}>
              <Statistic
                title="Estimated End"
                value={moment
                  .unix(start_at)
                  .add(total_time_in_seconds, 'seconds')
                  .format('lll')}
              />
            </Col>
            <Col span={6}>
              <Statistic title="Truck" value={truck.description} suffix={truck.code} />
            </Col>
            <Col span={6}>
              <Statistic title="Driver" value={driver.full_name} suffix={driver.code} />
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: 25 }}>
            <Col span={6}>
              <Statistic title="Total Time" value={secondsToHoursMinutes(total_time_in_seconds)} />
            </Col>
            <Col span={6}>
              <Statistic title="Total Distance" value={total_distance_in_miles} suffix="miles" />
            </Col>
            <Col span={6}>
              <Statistic title="Total Tickets" value={total_tickets} />
            </Col>
            <Col span={6}>
              <Statistic title="Total Gallons" value={total_gallons} />
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: 25 }}>
            {handleGallonsByProduct()}
          </Row>
        </Body>
      </Content>
      <TripItemList className={styles.compartments} items={items} products={products} />
    </React.Fragment>
  );
}
