import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { message, Row, Col, Form, Input, Button, Select, Switch, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import DivIcon from '../../components/DivIcon/DivIcon';
import Body from '../../components/Body/Body';
import Content from '../../components/Content/Content';

import Loader from '../../components/Loader/Loader';
import useResourceEdit from '../../hooks/useResourceEdit';
import ResourceFormActions from '../../components/ResourceFormActions/ResourceFormActions';
import StoreForm from '../../components/forms/StoreForm/StoreForm';
import ReactMap from '../../components/ReactMap/ReactMap';

import Ticket from '../../components/Ticket/Ticket';
import { SET_TRIP_ID } from '../TripEdit/store/TripEditStore';

const { TextArea } = Input;
const { Option } = Select;

export default function TicketCreateContainer({
  site,
  handleCancel = () => {},
  onSave = () => {}
}) {
  const [ticketStatusId, setTicketStatusId] = useState(7);
  const [gallons, setGallons] = useState(1);
  const [notes, setNotes] = useState();
  const [sp1Notes, setSp1Notes] = useState();
  const [sp2Notes, setSp2Notes] = useState();
  const [fill, setFill] = useState(false);
  const [estimatedGallons, setEstimatedGallons] = useState();
  const [deliveryDate, setDeliveryDate] = useState();
  const [storeId, setStoreId] = useState();

  const handleSave = async () => {
    const data = {
      factor_user_id: 'sacoil',
      factor_print_group: 'DEMAND',
      factor_customer: site.account_number,
      factor_tank: site.tank_id,
      factor_delv_gallons: gallons,
      factor_delv_date: deliveryDate,
      factor_sp_inst1: sp1Notes,
      factor_sp_inst2: sp2Notes,
      estimated_gallons: estimatedGallons,
      fill: fill,
      notes: notes,
      product: site.product.product,
      ticket_status_id: ticketStatusId,
      store_id: storeId
    };

    axios.post(`/tickets`, data).then((res) => {
      message.success(`Ticket successfully created`);
      onSave();
    });
  };

  // if (loading) {
  //   return <Loader display={true} />;
  // }

  return (
    <React.Fragment>
      <Body>
        <Row style={{ marginTop: 22 }} gutter={[64, 16]}>
          <Col span={12}>
            <Form.Item label="Store">
              <Select defaultValue={storeId} style={{ width: '100%' }} onChange={setStoreId}>
                <Option value={1}>Bedford</Option>
                <Option value={2}>New Cumberland</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Delivery Notes Line 1">
              <Input
                defaultValue={sp1Notes}
                style={{ width: '100%' }}
                onChange={(e) => setSp1Notes(e.target.value)}
              />
            </Form.Item>

            <Form.Item label="Delivery Notes Line 2">
              <Input
                defaultValue={sp2Notes}
                style={{ width: '100%' }}
                onChange={(e) => setSp2Notes(e.target.value)}
              />
            </Form.Item>

            <Form.Item label="Ticket Status">
              <Select
                defaultValue={ticketStatusId}
                style={{ width: '100%' }}
                onChange={setTicketStatusId}
              >
                <Option value={7}>Special</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Delivery Date">
              <DatePicker
                style={{ width: '100%' }}
                onChange={(value) => setDeliveryDate(moment(value).format('MM/DD/YYYY'))}
              />
            </Form.Item>

            <Form.Item label="Fill">
              <Select defaultValue={fill} style={{ width: '100%' }} onChange={setFill}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>

            {!fill && (
              <Form.Item label="Delivery Gallons">
                <Input
                  defaultValue={gallons}
                  style={{ width: '100%' }}
                  onChange={(e) => setGallons(e.target.value)}
                />
              </Form.Item>
            )}

            {fill && (
              <Form.Item label="Estimated Delivery Gallons">
                <Input
                  defaultValue={estimatedGallons}
                  style={{ width: '100%' }}
                  onChange={(e) => setEstimatedGallons(e.target.value)}
                />
              </Form.Item>
            )}

            <Form.Item label="Special Ticket Notes (Flags Ticket)">
              <TextArea
                defaultValue={notes}
                rows={3}
                style={{ width: '100%' }}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <ResourceFormActions handleCancel={handleCancel} handleSubmit={handleSave} />
      </Body>
    </React.Fragment>
  );
}
