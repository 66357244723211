// import React from "react";
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

let middlewares = [thunk];
let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export default ({ initialStore = {}, cache = false }) => {
  const store = createStore(
    rootReducer,
    initialStore,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  return store;
};
