import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import { Button, message, Descriptions, Tag, Form, Select, List } from 'antd';

const { Option } = Select;

function Ticket({ data, style = { width: '50vw', height: '40vh', overflow: 'auto' } }) {
  const [moreDeliveries, setMoreDeliveries] = useState(null);

  const {
    id,
    site = {},
    events = [],
    delivery_date,
    delivery_gallons,
    fill,
    account_number,
    tank_id,
    delivery_instructions_1,
    delivery_instructions_2,
    product,
    ticket_status_id,
    notes
  } = data;

  const { customer = {}, delivery_detail = {}, ar_customer = {}, deliveries = [] } = site;

  const ticketStatus = (ticket_status_id) => {
    switch (ticket_status_id) {
      case 1:
        return <Tag color="blue">Open</Tag>;
      case 2:
        return <Tag color="blue">Dispatched</Tag>;
      case 3:
        return <Tag color="green">Delivered</Tag>;
      case 4:
        return <Tag color="green">Posted</Tag>;
      case 5:
        return <Tag color="red">Ticket Removed</Tag>;
      case 6:
        return <Tag color="yellow">Active Delivery</Tag>;
      case 7:
        return <Tag color="red">Special</Tag>;
      case 8:
        return <Tag color="red">Undeliverable</Tag>;
      default:
        return '';
    }
  };

  return (
    <div style={style}>
      <Descriptions layout="vertical" bordered size="small" colon={false}>
        <Descriptions.Item label="Delivery Information">
          {customer && customer.first_name} {customer && customer.last_name}
          <br />
          {delivery_detail && delivery_detail.address_line_1}
          <br />
          {delivery_detail && delivery_detail.address_line_2}
          <br />
          {customer && customer.phone}
          <br />
          {customer && customer.phone_2}
        </Descriptions.Item>
        <Descriptions.Item label="Order Notes">
          {notes && (
            <div
              style={{
                backgroundColor: 'lightyellow',
                padding: 5,
                marginLeft: -5,
                marginBottom: 5
              }}
            >
              <h5>Special Notes</h5>
              {notes}
            </div>
          )}
          {delivery_instructions_1}
          <br />
          {delivery_instructions_2}
          <br />
          {delivery_detail && delivery_detail.special_instructions_1}
          <br />
          {delivery_detail && delivery_detail.special_instructions_2}
        </Descriptions.Item>
        <Descriptions.Item label="Ticket Status">
          {ticketStatus(ticket_status_id)}

          <div style={{ paddingTop: 5 }}>
            {events &&
              events.map((event) => (
                <div style={{ fontSize: 12, padding: 2 }}>
                  {event.action}: {moment.unix(event.timestamp).format('MM-DD-YYYY LTS')}
                </div>
              ))}
          </div>
        </Descriptions.Item>

        <Descriptions.Item label="Delivery Date">
          {moment.unix(delivery_date).format('dddd, MMMM Do YYYY ')}
        </Descriptions.Item>

        <Descriptions.Item label="Product / ">
          {product ? `${product.code} - ${product.title}` : ''}
        </Descriptions.Item>

        <Descriptions.Item label="Delivery Gallons">
          {fill ? `Estimated ${delivery_gallons} Gallons - Fill Tank` : delivery_gallons}
        </Descriptions.Item>

        <Descriptions.Item label="Route">{site && site.route}</Descriptions.Item>
        <Descriptions.Item label="Tank Location">
          {delivery_detail && delivery_detail.tank_location}
        </Descriptions.Item>
        <Descriptions.Item label="Tank Size">{site && site.tank_rated_size}</Descriptions.Item>

        <Descriptions.Item label="Account">{account_number}</Descriptions.Item>
        <Descriptions.Item label="Tank">{tank_id}</Descriptions.Item>
        <Descriptions.Item label="Customer Type">{site && site.sales_type}</Descriptions.Item>

        <Descriptions.Item label="Terms">{ar_customer && ar_customer.terms}</Descriptions.Item>
        <Descriptions.Item label="Current Balance">
          {ar_customer && ar_customer.current_balance}
        </Descriptions.Item>
        <Descriptions.Item label="Current Statement Balance">
          {ar_customer && ar_customer.current_statement_balance}
        </Descriptions.Item>

        <Descriptions.Item label="Last Delivery">
          {site && site.last_delivery_at && moment.unix(site.last_delivery_at).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="Estimated Next Delivery">
          {site &&
            site.estimated_next_delivery_at &&
            moment.unix(site.estimated_next_delivery_at).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="Estimated Empty">
          {site &&
            site.estimated_next_delivery_at &&
            moment.unix(site.estimated_out_at).format('YYYY-MM-DD')}
        </Descriptions.Item>

        <Descriptions.Item label="Actual / Average K Factor">
          {site && site.actual_k_factor} / {site && site.average_k_factor}
        </Descriptions.Item>
        <Descriptions.Item label="Estimated Next Delivery DD">
          {site && site.estimated_next_delivery_dd}
        </Descriptions.Item>
        <Descriptions.Item label="Estimated Out DD">
          {site && site.estimated_out_dd}
        </Descriptions.Item>
      </Descriptions>
      <List
        style={{ marginTop: 15 }}
        header={
          <div>
            Delivery History
            <span style={{ paddingLeft: 7, color: '#999', fontSize: 10 }}>
              Date | Gallons | Driver | Truck
            </span>
            <a
              style={{ fontSize: 10, marginLeft: 5 }}
              href="#"
              onClick={() => {
                // load more deliveries
                axios
                  .get(
                    `/deliveries?pageSize=100&account_number=${account_number}&tank_id=${tank_id}&columnKey=fodv_date&order=decend`
                  )
                  .then((res) => setMoreDeliveries(res.data.records));
              }}
            >
              (Load More)
            </a>
          </div>
        }
        bordered
        dataSource={moreDeliveries || deliveries}
        renderItem={(item) => (
          <List.Item style={{ flex: 2, flexDirection: 'row', justifyContent: 'space-between' }}>
            <span>{moment.unix(item.delivery_date).format('YYYY-MM-DD')}</span>
            <span>{item.delivery_gallons}</span>
            <span>{item.driver_code}</span>
            <span>{item.truck_code}</span>
          </List.Item>
        )}
      />
    </div>
  );
}

Ticket.propTypes = {};

export default Ticket;
