import React, { useState, useEffect } from 'react';
import { Input, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, ProfileOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import EquipmentEditContainer from '../EquipmentEdit/EquipmentEditContainer';
import { trim } from 'lodash';

export default function EquipmentListContainer() {
  let name = 'Equipment';
  const path = '/equipments';
  const [state, attrs] = useResourceList(path, name);
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);

  const { filters, setFilters } = attrs;

  const handleSearch = (key, selectedKeys, confirm) => {
    setFilters({ ...filters, [key]: selectedKeys });
    confirm();
  };

  const handleReset = (key, clearFilters) => {
    setFilters({ ...filters, [key]: null });
    clearFilters();
  };
  const columns = [
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      sorter: (a, b) => a.model - b.model,
      sortOrder: attrs.sortInfo.columnKey === 'model' && attrs.sortInfo.order,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Model"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('model', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('model', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('model', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.model || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      }
    },
    {
      title: 'Serial',
      dataIndex: 'serial',
      key: 'serial',
      sorter: (a, b) => a.id - b.id,
      sortOrder: attrs.sortInfo.columnKey === 'serial' && attrs.sortInfo.order,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Serial"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('serial', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('serial', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('serial', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.serial || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.id - b.id,
      sortOrder: attrs.sortInfo.columnKey === 'description' && attrs.sortInfo.order,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Description"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('description', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('description', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('description', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.description || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      render: (data) => data && data.trim()
    },

    {
      title: 'Capacity',
      dataIndex: 'capacity',
      key: 'capacity',
      sorter: (a, b) => a.id - b.id,
      sortOrder: attrs.sortInfo.columnKey === 'capacity' && attrs.sortInfo.order
    },

    {
      title: 'Active Lease',
      dataIndex: 'active_lease',
      key: 'factor_customer_id',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Customer Number"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('factor_customer_id', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('factor_customer_id', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('factor_customer_id', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.factor_customer_id || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: (a, b) => a.factor_customer_id - b.factor_customer_id,
      sortOrder: attrs.sortInfo.columnKey === 'factor_customer_id' && attrs.sortInfo.order,
      render: (data) => {
        // Sometimes the customer data doesn't exist so I want to show customer number
        if (data && data.active && data.site && data.site.customer) {
          return (
            <>
              {data.site.customer.full_name}
              <small> ({data.factor_customer_id})</small>
            </>
          );
        } else if (data && data.active) {
          return `Customer #${data.factor_customer_id}`;
        } else {
          return null;
        }
      }
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      key: 'manufacturer_id',
      sorter: (a, b) => a.manufacturer_id - b.manufacturer_id,
      sortOrder: attrs.sortInfo.columnKey === 'manufacturer_id' && attrs.sortInfo.order,
      render: (data) => data && data.title
    },

    {
      visible: false,
      title: 'Manufactured At',
      dataIndex: 'manufactured_at',
      key: 'manufactured_at',
      sorter: (a, b) => a.manufactured_at - b.manufactured_at,
      sortOrder: attrs.sortInfo.columnKey === 'manufactured_at' && attrs.sortInfo.order,
      render: (data) => data && moment.unix(data).format('M/D/YYYY')
    },

    {
      visible: false,
      title: 'Purchased At',
      dataIndex: 'purchased_at',
      key: 'purchased_at',
      sorter: (a, b) => a.purchased_at - b.purchased_at,
      sortOrder: attrs.sortInfo.columnKey === 'purchased_at' && attrs.sortInfo.order,
      render: (data) => data && moment.unix(data).format('M/D/YYYY')
    },

    {
      title: 'Purchase Cost',
      dataIndex: 'purchase_cost',
      key: 'purchase_cost',
      sorter: (a, b) => a.purchase_cost - b.purchase_cost,
      sortOrder: attrs.sortInfo.columnKey === 'purchase_cost' && attrs.sortInfo.order,
      render: (data) => data && Number(data).toFixed(2)
    },

    {
      title: 'Next Inspection Due',
      dataIndex: 'inspection_next_at',
      key: 'inspection_next_at',
      sorter: (a, b) => a.inspection_next_at - b.inspection_next_at,
      sortOrder: attrs.sortInfo.columnKey === 'inspection_next_at' && attrs.sortInfo.order,
      render: (data) => data && moment.unix(data).format('M/D/YYYY')
    },

    {
      visible: false,
      title: 'Inspection Interval',
      dataIndex: 'inspection_interval',
      key: 'inspection_interval',
      sorter: (a, b) => a.inspection_interval - b.inspection_interval,
      sortOrder: attrs.sortInfo.columnKey === 'inspection_interval' && attrs.sortInfo.order,
      render: (data) => data
    },

    {
      title: 'Profit Center Code',
      dataIndex: 'profit_center_code',
      key: 'profit_center_code',
      sorter: (a, b) => a.profit_center_code - b.profit_center_code,
      sortOrder: attrs.sortInfo.columnKey === 'profit_center_code' && attrs.sortInfo.order,
      filters: [
        {
          text: 'Bedford Fuel Oil : 102',
          value: '102'
        },
        {
          text: 'Bedford Warehouse : 104',
          value: '104'
        },
        {
          text: 'Bedford Propane : 118',
          value: '118'
        },

        {
          text: 'New Cumberland Fuel Oil : 509',
          value: '509'
        },
        {
          text: 'New Cumberland Propane : 119',
          value: '119'
        }
      ],
      filteredValue: attrs.filters.profit_center_code || null,
      render: (data) => data
    },

    {
      visible: false,
      title: 'Invoice Processed?',
      dataIndex: 'is_invoice_processed',
      key: 'is_invoice_processed',
      sorter: (a, b) => a.is_invoice_processed - b.is_invoice_processed,
      sortOrder: attrs.sortInfo.columnKey === 'is_invoice_processed' && attrs.sortInfo.order,
      filters: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      filteredValue: attrs.filters.is_invoice_processed || null,
      render: (data) => (data === true ? 'Yes' : 'No')
    },

    {
      visible: false,
      title: 'Invoice Reference',
      dataIndex: 'invoice_reference',
      key: 'invoice_reference',
      sorter: (a, b) => a.invoice_reference - b.invoice_reference,
      sortOrder: attrs.sortInfo.columnKey === 'invoice_reference' && attrs.sortInfo.order,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Reference Number"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch('invoice_reference', selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch('invoice_reference', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('invoice_reference', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.invoice_reference || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      render: (data) => data
    },

    {
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList();
  };

  const data = state.data
    ? state.data.map((item) => ({
        ...item,
        actions: (
          <div>
            <Link to={`/equipments/${item.id}`}>
              <ProfileOutlined />
            </Link>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
              <EditOutlined />
            </a>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={attrs.removeResource.bind(this, item.id)}
            >
              <a href="#!">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </div>
        )
      }))
    : [];

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
        width="75%"
      >
        <EquipmentEditContainer handleCancel={toggleModal} id={resourceId} onSave={onModalSave} />
      </ResourceModal>

      <ResourceList
        data={data}
        extra={[
          <Button key="1" type="primary" onClick={toggleModal}>
            Create {name}
          </Button>
        ]}
        {...attrs}
      />
    </React.Fragment>
  );
}
