import React from 'react';
import { Divider, Button } from 'antd';
import styles from './ResourceFormActions.module.css';

export default function ResourceFormActions({ id, handleCancel, handleSubmit }) {
  return (
    <React.Fragment>
      <Divider />
      <div className={styles.actions}>
        <Button className={styles.buttonPadding} key="back" onClick={handleCancel}>
          Cancel
        </Button>
        <Button key="submit" type="primary" onClick={handleSubmit}>
          {id ? 'Save' : 'Create'}
        </Button>
      </div>
    </React.Fragment>
  );
}
