import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Layout, Button } from 'antd';
import TripEditContext from './store/TripEditContext';
import TicketListHeader from './Sidebar/TicketListHeader/TicketListHeader';
import TicketList from './Sidebar/TicketList/TicketList';
import TripEditHeader from './TripEditHeader/TripEditHeader';
import TripEditTicketResults from './TripEditTicketResults/TripEditTicketResults';
import TripEditMap from './TripEditMap/TripEditMap';
import Loader from '../../components/Loader/Loader';

import styles from './TripEdit.module.css';
import ButtonGroup from 'antd/lib/button/button-group';

const { Content, Sider } = Layout;

function TripEdit(props) {
  const { state } = useContext(TripEditContext);
  const { loading } = state;
  const { handleCancelTrip, handleSaveTrip } = props;

  if (loading) return <Loader display={true} />;

  return (
    <Layout className={styles.tripEdit}>
      {/* Sidebar */}
      <Sider
        className={styles.sidebar}
        width={375}
        style={{ background: '#fff', border: '1px solid #eee' }}
      >
        <Content className={styles.content}>
          <TicketListHeader />
          <TicketList className={styles.ticketList} />
        </Content>
      </Sider>

      {/* Main Content */}
      {/* I had to add the zIndex to fix a browser issue with react-drag-listview */}

      <Layout className={styles.main}>
        <Content className={styles.header}>
          <TripEditHeader handleSaveTrip={handleSaveTrip} handleCancelTrip={handleCancelTrip} />
        </Content>
        <Content className={styles.map}>
          <TripEditTicketResults />
          <TripEditMap />
        </Content>
      </Layout>
    </Layout>
  );
}

TripEdit.propTypes = {};

export default TripEdit;
