import React from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Input, Select, Button } from 'antd';
import styles from './DriverForm.module.css';

const { Option } = Select;

function DriverForm({ form, data = {}, stores = [] }) {
  const { code = null, first_name = null, last_name = null, active = null, store_id = null } = data;
  const { getFieldDecorator } = form;

  return (
    <Form className={styles.form} layout="vertical">
      <Row>
        <Form.Item label="Driver Code">
          {getFieldDecorator('code', {
            initialValue: code || '',
            rules: [{ required: true }]
          })(<Input placeholder="Driver Code" />)}
        </Form.Item>

        <Form.Item label="First Name">
          {getFieldDecorator('first_name', {
            initialValue: first_name || '',
            rules: [{ required: true }]
          })(<Input placeholder="First Name" />)}
        </Form.Item>

        <Form.Item label="Last Name">
          {getFieldDecorator('last_name', {
            initialValue: last_name || '',
            rules: [{ required: true }]
          })(<Input placeholder="Last Name" />)}
        </Form.Item>

        <Form.Item label="Active">
          {getFieldDecorator('active', {
            initialValue: active !== null ? active.toString() : 'true',
            rules: [{ required: true }]
          })(
            <Select placeholder="Active">
              <Option value="true">True</Option>
              <Option value="false">False</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Store">
          {getFieldDecorator('store_id', {
            initialValue: store_id || '',
            rules: [{ required: true }]
          })(
            <Select placeholder="Select Store">
              {stores.map((s) => (
                <Option key={s.id} value={s.id}>
                  {s.id} - {s.city}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Row>
    </Form>
  );
}

DriverForm.propTypes = {
  drivers: PropTypes.array,
  trucks: PropTypes.array,
  dispatch: PropTypes.object
};

const WrappedDriverForm = Form.create({ name: 'DriverForm' })(DriverForm);
export default WrappedDriverForm;
