import React, { Component } from 'react'; // eslint-disable-line no-unused-vars

export default function(user, ComposedComponent) {
  class RedirectAuth extends Component {
    constructor(props) {
      super(props);
      this.initialize(props);
    }

    componentDidMount() {
      this.initialize(this.props);
    }

    initialize(props) {
      if (user) {
        this.props.history.push('/');
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  return RedirectAuth;
}
