import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loader from '../../components/Loader/Loader';
import useResourceEdit from '../../hooks/useResourceEdit';
import ResourceFormActions from '../../components/ResourceFormActions/ResourceFormActions';
import EquipmentLeaseForm from '../../components/forms/EquipmentLeaseForm/EquipmentLeaseForm';
import { ConsoleSqlOutlined } from '@ant-design/icons';

export default function EquipmentLeaseEditContainer({
  id,
  handleCancel = () => {},
  onSave = () => {}
}) {
  const [state, attrs] = useResourceEdit({
    path: '/equipment_leases',
    name: 'Equipment Leases',
    id,
    onSave
  });
  const { loading, data } = state;
  const { handleSubmit, resetFields, setForm } = attrs;
  const [equipments, setEquipments] = useState();
  const [cycles, setCycles] = useState();
  const [equipmentLeaseTypes, setEquipmentLeaseTypes] = useState();

  // Run First Load
  useEffect(() => {
    axios
      .get(`/equipments?pageSize=0&available_for_lease=1`)
      .then((res) => setEquipments(res.data.records));

    axios.get(`/cycles?pageSize=0`).then((res) => setCycles(res.data.records));
    axios
      .get(`/equipment_lease_types?pageSize=0`)
      .then((res) => setEquipmentLeaseTypes(res.data.records));

    return () => {
      setEquipments();
      setCycles();
      setEquipmentLeaseTypes();
    };
  }, []);

  if (loading) {
    return <Loader display={true} />;
  }

  return (
    <React.Fragment>
      <EquipmentLeaseForm
        data={data}
        equipments={equipments}
        cycles={cycles}
        equipmentLeaseTypes={equipmentLeaseTypes}
        ref={(ref) => setForm(ref)}
      />
      <ResourceFormActions
        id={id}
        handleCancel={() => {
          handleCancel();
          resetFields();
        }}
        handleSubmit={handleSubmit}
      />
    </React.Fragment>
  );
}
