import { UI_SET_LOADING, UI_SET_SAVING } from './types';

const initialState = {
  isLoading: false,
  loadingMessage: 'loading'
};

export default (state = initialState, action = {}) => {
  const { payload } = action;
  switch (action.type) {
    case UI_SET_LOADING:
      return {
        ...state,
        isLoading: payload,
        loadingMessage: 'loading'
      };
    case UI_SET_SAVING:
      return {
        ...state,
        isLoading: payload,
        loadingMessage: 'saving'
      };
    default:
      return state;
  }
};
