import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Descriptions, Popover } from 'antd';
import { CloseSquareOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import TripEditContext from '../store/TripEditContext';
import {
  ADD_SELECTED_TICKET_RESULTS_TO_TICKETS,
  SET_TICKET_RESULTS,
  SET_SELECTED_TICKET_RESULTS
} from '../store/TripEditStore';

import styles from './TripEditTicketResults.module.css';

function TripEditTicketResults(props) {
  const { state, dispatch } = useContext(TripEditContext);
  const { ticketResults, selectedTicketResults } = state;
  const totalResults = ticketResults.length;
  const totalSelectedResults = selectedTicketResults.length;

  const ticketsMissingCoords = ticketResults.filter((t) => !t.site.delivery_detail.latitude);
  const totalMissingCoords = ticketsMissingCoords.length;

  if (totalResults < 1) return <React.Fragment></React.Fragment>;

  const addSelectedTickets = () => {
    dispatch({ type: ADD_SELECTED_TICKET_RESULTS_TO_TICKETS });
  };

  const cancelTicketResults = () => {
    dispatch({ type: SET_SELECTED_TICKET_RESULTS, payload: [] });
    dispatch({ type: SET_TICKET_RESULTS, payload: [] });
  };

  return (
    <div className={styles.ticketResultsBar}>
      <CloseSquareOutlined style={{ float: 'right' }} onClick={cancelTicketResults} />

      <Descriptions>
        <Descriptions.Item label="Total Tickets">{totalResults}</Descriptions.Item>
        <Descriptions.Item label="Tickets Missing GeoCoordinates">
          <Popover
            content={ticketsMissingCoords.map((ticket) => (
              <Descriptions key={ticket.id} style={{ width: '50vw' }} rows={4}>
                <Descriptions.Item label="Ticket">{ticket.ticket_number}</Descriptions.Item>
                <Descriptions.Item label="Account">{ticket.account_number}</Descriptions.Item>
                <Descriptions.Item label="Tank">
                  {ticket.tank_id}{' '}
                  <Link to={`/sites/${ticket.site.id}/edit`} target="_blank">
                    Edit Location
                  </Link>
                </Descriptions.Item>
              </Descriptions>
            ))}
            title="Tickets Missing GeoCoordinates"
            trigger="click"
          >
            <a href="#!"> {totalMissingCoords} </a>
          </Popover>
        </Descriptions.Item>
      </Descriptions>
      {totalSelectedResults > 0 && (
        <Button size="small" type="primary" onClick={addSelectedTickets} style={{ float: 'right' }}>
          Add {totalSelectedResults} Selected Tickets
        </Button>
      )}
    </div>
  );
}

TripEditTicketResults.propTypes = {};

export default TripEditTicketResults;
