import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Divider, Tag, Row, Col, Button } from 'antd';
import TripEditContext from '../store/TripEditContext';
import Stat from '../../../components/Stat';
import TripForm from './TripForm';
import { secondsToHoursMinutes } from '../../../utils/units';
import styles from './TripEditHeader.module.css';

function TripEditHeader(props) {
  const { handleCancelTrip } = props;
  const { state, dispatch } = useContext(TripEditContext);
  const {
    dispatchId,
    trip,
    stores,
    trucks,
    drivers,
    totalGallons,
    totalTime,
    totalDistance,
    totalTickets,
    products,
    totalGallonsByProduct
  } = state;

  let tripForm;

  const handleSubmit = (e) => {
    e.preventDefault();
    const { items } = state;
    const { handleSaveTrip } = props;
    tripForm.getForm().validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        // Modifying Values in Form and Adding Additional Fields as needed
        values.start_at = values.start_at.format();
        values.dispatch_id = dispatchId;
        values.items = items.map((i) => {
          if (i) {
            const id = i.type === 'ticket' ? i.ticket.id : i.location.id;
            return { id, type: i.type };
          }
        });
        values.total_distance_in_miles = totalDistance;
        values.total_time_in_seconds = totalTime;
        handleSaveTrip(values);
      }
    });
  };
  const handleGallonsByProduct = () => {
    let tags = [];
    for (let [key, value] of Object.entries(totalGallonsByProduct)) {
      const product = products.find((product) => product.id == key);
      tags.push(
        <Tag key={key} color={`#${product.hex_code}`}>
          {product.title}: {value && value.toFixed(2)}
        </Tag>
      );
    }
    return tags;
  };

  return (
    <div className={styles.tripEditHeader}>
      <Row>
        <Col span={12}>
          <TripForm
            ref={(ref) => (tripForm = ref)}
            trip={trip}
            stores={stores}
            trucks={trucks}
            drivers={drivers}
          />
          <Divider style={{ margin: 0, marginBottom: 10 }} />
          {handleGallonsByProduct()}
        </Col>
        <Col span={10} offset={2}>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Stat title="Tickets" value={totalTickets} />
            </Col>
            <Col span={12}>
              <Stat title="Gallons" value={totalGallons} />
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Stat title="Total Miles" value={totalDistance} />
            </Col>
            <Col span={12}>
              <Stat title="Total Time" value={secondsToHoursMinutes(totalTime)} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className={styles.actionBar} span={24}>
          <Button className={styles.saveButton} type="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
          <Button className={styles.saveButton} onClick={handleCancelTrip}>
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
}

TripEditHeader.propTypes = {};

export default TripEditHeader;
