import { combineReducers } from 'redux';
import tripEdit from './tripEdit/reducer';
import dispatchList from './dispatchList/reducer';
import dispatchEdit from './dispatchEdit/reducer';

export default combineReducers({
  dispatchList,
  dispatchEdit,
  tripEdit
});
