import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axios from 'axios';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import configureStore from './store';
import 'antd/dist/antd.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const store = configureStore({});

// Set Axios defaults
axios.defaults.baseURL =
  process.env.NODE_ENV === 'production' ? 'https://api.sacenergy.com' : 'https://api.sacenergy.com';

// // Init Sentry Error Tracking
// Sentry.init({
//   dsn: 'https://fc48c5e1ec664056a89df44400c120b3@o505657.ingest.sentry.io/5603642',
//   autoSessionTracking: true,
//   integrations: [new Integrations.BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0
// });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
