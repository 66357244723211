import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import L, { divIcon } from 'leaflet';
import { Typography } from 'antd';

import styles from './MarkerIcon.module.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { reducer } from '../../containers/TripEdit/store/TripEditStore';

import { COLORS, getTicketStatusColor } from '../../utils/constants';

const { Text } = Typography;

function MarkerIcon({
  backgroundColor = '#76bff7',
  icon,
  text,
  textBackgroundColor = '#000',
  statusId
}) {
  return (
    <React.Fragment>
      <div
        style={{ backgroundColor, borderColor: getTicketStatusColor(statusId) }}
        className={styles.markerPin}
      >
        {icon && (
          <i
            style={{ color: textBackgroundColor }}
            className={[styles.icon, 'fa', icon].join(' ')}
          ></i>
        )}
        {text && <span className={styles.text}>{text}</span>}
      </div>
    </React.Fragment>
  );
}

export default ({ backgroundColor = '#76bff7', icon, text, statusId }) =>
  divIcon({
    className: 'custom-div-icon',
    html: renderToStaticMarkup(
      <MarkerIcon backgroundColor={backgroundColor} icon={icon} text={text} statusId={statusId} />
    ),
    iconSize: [30, 42],
    iconAnchor: [15, 42],
    popupAnchor: [0, -22]
  });
