import React from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Input, Select, InputNumber } from 'antd';
import styles from './FuelDiscountForm.module.css';

const { Option } = Select;
const { TextArea } = Input;

function FuelDiscount({ form, data = {} }) {
  const {
    discount = null,
    name = null,
    code = null,
    description = null,
    active = null,
    auto_apply = null,
    discount_type = null,
    start_date = null,
    end_date = null,
    expires = null,
    uses_per_customer = null,
    uses_per_coupon = null,
    allow_additional_discounts = null
  } = data;

  const { getFieldDecorator } = form;

  return (
    <Form className={styles.form} layout="vertical">
      <Row>
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: name || '',
            rules: [{ required: true }]
          })(<Input placeholder="Name" />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            initialValue: description || ''
          })(<TextArea rows={3} placeholder="Description" />)}
        </Form.Item>
        <Form.Item label="Code">
          {getFieldDecorator('code', {
            initialValue: code || '',
            rules: [{ required: true }]
          })(<Input placeholder="Code" />)}
        </Form.Item>
        <Form.Item label="Discount">
          {getFieldDecorator('discount', {
            initialValue: discount || '',
            rules: [{ required: true }]
          })(<Input placeholder="Discount" />)}
        </Form.Item>

        <Form.Item label="Discount Type">
          {getFieldDecorator('discount_type', {
            initialValue: discount_type ? discount_type.toString() : 'cpg',
            rules: [{ required: true }]
          })(
            <Select placeholder="Discount Type">
              <Option value="cpg">Cost Per Gallon</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Uses Per Customer">
          {getFieldDecorator('uses_per_customer', {
            initialValue: uses_per_customer || ''
          })(<InputNumber />)}
        </Form.Item>

        <Form.Item label="Uses Per Coupon">
          {getFieldDecorator('uses_per_coupon', {
            initialValue: uses_per_coupon || ''
          })(<InputNumber />)}
        </Form.Item>

        <Form.Item label="Expires">
          {getFieldDecorator('expires', {
            initialValue: expires ? expires.toString() : 'true',
            rules: [{ required: true }]
          })(
            <Select placeholder="Expires">
              <Option value="true">True</Option>
              <Option value="false">False</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Auto Apply">
          {getFieldDecorator('auto_apply', {
            initialValue: auto_apply ? auto_apply.toString() : 'true',
            rules: [{ required: true }]
          })(
            <Select placeholder="Auto Apply">
              <Option value="true">True</Option>
              <Option value="false">False</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Allow Additional Discounts">
          {getFieldDecorator('allow_additional_discounts', {
            initialValue: allow_additional_discounts
              ? allow_additional_discounts.toString()
              : 'true',
            rules: [{ required: true }]
          })(
            <Select placeholder="Allow Additional Discounts">
              <Option value="true">True</Option>
              <Option value="false">False</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Active">
          {getFieldDecorator('active', {
            initialValue: active ? active.toString() : 'false',
            rules: [{ required: true }]
          })(
            <Select placeholder="Active">
              <Option value="true">True</Option>
              <Option value="false">False</Option>
            </Select>
          )}
        </Form.Item>
      </Row>
    </Form>
  );
}

FuelDiscount.propTypes = {
  form: PropTypes.object,
  data: PropTypes.object
};

const WrappedFuelDiscount = Form.create({ name: 'FuelDiscount' })(FuelDiscount);
export default WrappedFuelDiscount;
