import { SET_TRIPS } from './types';
import union from 'lodash/union';

const initialState = {
  byId: {},
  allIds: []
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_TRIPS:
      const ids = action.payload ? Object.keys(action.payload) : [];
      return {
        ...state,
        byId: { ...state.byId, ...action.payload },
        allIds: union(state.allIds, ids)
      };
    default:
      return state;
  }
};
