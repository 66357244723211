// This is a skeleton map
import React, { useRef, useEffect, useState } from 'react';
import { Map, Marker, Popup, Polyline, TileLayer, LayersControl } from 'react-leaflet';
import { Button } from 'antd';
import FullscreenControl from 'react-leaflet-fullscreen';
import '@geoman-io/leaflet-geoman-free';
import { ticketsInsidePolygon } from '../../utils/geo';
import mapLayers, { googleStreet } from './mapLayers';

import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'react-leaflet-fullscreen/dist/styles.css';
import 'leaflet/dist/leaflet.css';

function ReactMap(props) {
  const {
    center = [39.9219238, -78.343211],
    zoom = 12,
    defaultTile = googleStreet,
    height = '100%',
    width = '100%',
    routeShape,
    tickets,
    ticketResults,
    selectedTicketResults,
    setSelectedTicketResults,
    addSelectedTicketResults,
    handleAddTicket
  } = props;
  const map = useRef(null);
  const [currentCenter, setCurrentCenter] = useState(center);
  const [currentZoom, setCurrentZoom] = useState(zoom);

  useEffect(() => {
    window.L_DISABLE_3D = true; // Try to keep text crisp in all browsers
    const leafletMap = map.current.leafletElement;
    leafletMap.pm.addControls({
      position: 'topleft',
      drawMarker: false,
      drawCircle: false,
      drawCircleMarker: true,
      drawPolyline: false,
      editMode: false,
      dragMode: false,
      drawRectangle: true,
      drawPolygon: true,
      cutPolygon: false,
      removalMode: false
    });

    leafletMap.on('pm:create', (e) => {
      let newFeature = e.layer.toGeoJSON();
      console.log('new', newFeature);
    });
  }, []);

  // useEffect(() => {
  //   const latlngs = routeShape.map((item) => item.split(','));
  //   const leafletMap = map.current.leafletElement;

  //   map.current.polyline
  //     .antPath(latlngs, {
  //       color: '#3388ff',
  //       opacity: 0.85,
  //       delay: 2200
  //     })
  //     .addTo(map.current.leafletElement);

  //   // centerMap(map.current.leafletElement, tickets);
  //   return () => {
  //     map.current.leafletElement.clearLayers();
  //   };
  // }, [routeShape]);

  useEffect(() => {
    // This section listens for map events releated to drawing
    const leafletMap = map.current.leafletElement;
    leafletMap.on('pm:create', (e) => {
      // Get Polygon from map event
      if (e.shape === 'CircleMarker') {
        const selectedCoordinates = e.marker._latlng;

        const selectedTicket = ticketResults.find(
          (t) =>
            t.site.delivery_detail.latitude == selectedCoordinates.lat &&
            t.site.delivery_detail.longitude == selectedCoordinates.lng
        );

        leafletMap.removeLayer(e.layer); // Remove Layer
        selectedTicket && handleAddTicket(selectedTicket.id);
      } else {
        console.log(e);

        const coordinates = e.layer.getLatLngs()[0];
        const polygon = coordinates.map((coord) => [coord.lat, coord.lng]);
        const selectedTickets = ticketsInsidePolygon(polygon, ticketResults);
        leafletMap.removeLayer(e.layer); // Remove Layer
        setSelectedTicketResults(selectedTickets);
      }

      // Listen to when a layer is changed in edit mode
      e.layer.on('pm:markerdragend', (inner_e) => {
        console.log('Fired dragend');
        const coordinates = e.layer.getLatLngs()[0];
        const polygon = coordinates.map((coord) => [coord.lat, coord.lng]);
        const selectedTickets = ticketsInsidePolygon(polygon, ticketResults);
        setSelectedTicketResults(selectedTickets);
      });
    });

    return () => {
      // These help keep listeners from firing to many times
      leafletMap.off('pm:create');
    };
  }, [tickets, ticketResults, selectedTicketResults]);

  return (
    <Map
      ref={(ref) => {
        map.current = ref;
      }}
      style={{ width, height }}
      center={currentCenter}
      zoom={currentZoom}
    >
      <FullscreenControl position="topleft" forceSeparateButton={true} />
      <TileLayer url={defaultTile.url} subdomains={defaultTile.subdomains} />

      <LayersControl position="bottomright">
        {mapLayers.map((layer) => {
          return (
            <LayersControl.BaseLayer name={layer.name} key={layer.name}>
              <TileLayer url={layer.url} subdomains={layer.subdomains} />
            </LayersControl.BaseLayer>
          );
        })}
      </LayersControl>

      <Polyline positions={routeShape.map((item) => item.split(','))} />
      {props.children}
    </Map>
  );
}

export default ReactMap;
