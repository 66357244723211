import { StyleSheet } from '@react-pdf/renderer';

// Create styles
export default StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontSize: 8
  },
  section: {
    padding: 15,
    flexGrow: 1
  },

  logo: {
    width: 100
  },

  bold: {
    fontSize: 8,
    fontWeight: 700
  },

  small: {
    fontSize: 8
  },

  extraSmall: {
    fontSize: 8
  },

  h2: {
    fontSize: 18,
    fontWeight: 700
  },

  h3: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 3
  },

  h4: {
    fontSize: 10,
    fontWeight: 700,
    marginBottom: 3
  },

  h5: {
    fontSize: 8,
    fontWeight: 700,
    marginBottom: 3
  },

  right: {
    alignSelf: 'flex-end'
  },

  row: {
    flexDirection: 'row'
  },

  paddingTop: {
    paddingTop: 12
  },

  padding: {
    paddingRight: 5
  },

  subtitle: {
    color: '#555'
  },

  location: {
    fontWeight: 700,
    fontSize: 9
  }
});
