export const secondsToHoursMinutes = (seconds) => {
  seconds = Number(seconds);
  var h = Math.floor(seconds / 3600);
  var m = Math.ceil((seconds % 3600) / 60);

  var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
  var mDisplay = m > 0 ? m + (m == 1 ? ' minute ' : ' minutes ') : '';
  return hDisplay + mDisplay;
};

export const metersToMiles = (meters) => {
  return (meters / 1609.34).toFixed(2);
};
