import { SET_DISPATCHES, REMOVE_DISPATCH } from './types';
import union from 'lodash/union';
import omit from 'lodash/omit';

const initialState = {
  byId: {},
  allIds: []
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_DISPATCHES:
      const ids = action.payload ? Object.keys(action.payload) : [];
      return {
        ...state,
        byId: { ...state.byId, ...action.payload },
        allIds: union(state.allIds, ids)
      };
    case REMOVE_DISPATCH:
      const id = action.payload;
      return {
        ...state,
        byId: omit(state.byId, action.payload),
        allIds: state.allIds.filter((item) => item !== id)
      };

    default:
      return state;
  }
};
