import React, { useState, useContext, useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, CarOutlined, ProfileOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import DispatchEditContainer from '../DispatchEdit/DispatchEditContainer';
import UserContext from '../../UserContext';

const stores = {
  1: 'Bedford',
  2: 'New Cumberland',
  3: 'Maryland'
};
export default function DispatchListContainer(props) {
  const queryParams = queryString.parse(props.location.search);
  const { date } = queryParams;
  const { user = {} } = useContext(UserContext);
  const { default_store_id } = user;

  let name = 'Dispatch';
  const path = '/dispatches';
  const [state, attrs] = useResourceList(
    path,
    name,
    {},
    { date, columnKey: 'date', order: 'descend', store_id: default_store_id }
  );
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);

  const dispatchEditRoles = [1, 2, 4, 6];
  const columns = [
    {
      width: '10%',
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: attrs.sortInfo.columnKey === 'id' && attrs.sortInfo.order
    },
    {
      width: '20%',
      title: 'Store',
      dataIndex: 'store_id',
      key: 'store_id',
      sortOrder: attrs.sortInfo.columnKey === 'store_id' && attrs.sortInfo.order,
      // TODO load this info from the database like we do for routes on SiteListContainer.js
      filters: [
        {
          text: 'Bedford',
          value: '1'
        },
        {
          text: 'New Cumberland',
          value: '2'
        },
        {
          text: 'Maryland',
          value: '3'
        }
      ],
      filteredValue: attrs.filters.store_id || null,
      render: (data) => stores[data]
    },
    {
      width: '50%',
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date - b.date,
      sortOrder: attrs.sortInfo.columnKey === 'date' && attrs.sortInfo.order,
      render: (data) => moment(data).format('dddd, MMMM Do YYYY')
    },

    {
      width: '20%',
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList();
  };

  const data = state.data
    ? state.data.map((item) => ({
        ...item,
        actions: (
          <div>
            <Link to={`/dispatches/${item.id}`}>
              <ProfileOutlined />
            </Link>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <Link to={`/dispatches/${item.id}/map`}>
              <CarOutlined />
            </Link>
            {user && dispatchEditRoles.includes(user.role_id) && (
              <React.Fragment>
                <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
                <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
                  <EditOutlined />
                </a>
                <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
                <Popconfirm
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={attrs.removeResource.bind(this, item.id)}
                >
                  <a href="#!">
                    <DeleteOutlined />
                  </a>
                </Popconfirm>
              </React.Fragment>
            )}
          </div>
        )
      }))
    : [];

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
      >
        <DispatchEditContainer handleCancel={toggleModal} id={resourceId} onSave={onModalSave} />
      </ResourceModal>
      <ResourceList
        data={data}
        extra={
          user &&
          dispatchEditRoles.includes(user.role_id) && [
            <Button key="1" type="primary" onClick={toggleModal}>
              Create {name}
            </Button>
          ]
        }
        {...attrs}
      />
    </React.Fragment>
  );
}
