import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import { Button } from 'antd';
import Map from '../../../components/Map/Map';
import DivIcon from '../../../components/DivIcon/DivIcon';
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import Ticket from '../../../components/Ticket/Ticket';
import TripEditContext from '../store/TripEditContext';
import { averageGeolocation } from '../../../utils/geo';
import { SET_SELECTED_TICKET_RESULTS, ADD_SELECTED_TICKET_RESULTS } from '../store/TripEditStore';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import styles from './TripEditMap.module.css';

const TripEditMap = () => {
  const [height, setHeight] = useState(0);
  const { state, dispatch, actions } = useContext(TripEditContext);
  const { addTicket, addLocation, removeItem } = actions;
  const { locations, ticketResults, items, selectedTicketResults, routeShape } = state;

  const ticketItems = items.filter((item) => item.ticket);
  const tickets = ticketItems.map((item) => item.ticket);

  const setSelectedTicketResults = (tickets) => {
    dispatch({ type: SET_SELECTED_TICKET_RESULTS, payload: tickets });
  };

  const addSelectedTicketResults = (tickets) => {
    dispatch({ type: ADD_SELECTED_TICKET_RESULTS, payload: tickets });
  };

  const getCenterCoords = () => {
    if (tickets && tickets.length > 0) {
      const centerCoords = averageGeolocation(
        tickets.map((ticket) => ({
          latitude: ticket.site.delivery_detail.latitude,
          longitude: ticket.site.delivery_detail.longitude
        }))
      );
      return [centerCoords.latitude, centerCoords.longitude];
    } else {
      return [39.9219238, -78.343211];
    }
  };

  const handleLocationMarkers = () => {
    return locations.map((location) => {
      const { id, title, latitude, longitude } = location;
      if (latitude && longitude) {
        return (
          <Marker
            key={location.id}
            position={[latitude, longitude]}
            icon={DivIcon({ backgroundColor: '#c30b82', icon: 'fa-building' })}
          >
            <Popup maxWidth={'95%'}>
              {location.title}

              <div style={{ marginBottom: 10 }}>
                <Button onClick={() => addLocation(id)}>Add Location</Button>
              </div>
            </Popup>
          </Marker>
        );
      }
    });
  };

  const handleTicketMarkers = () => {
    return (
      items &&
      items.map((item, index) => {
        const { ticket, location } = item;

        if (ticket) {
          // Ticket
          const { site } = ticket;
          const { delivery_detail } = site;
          const { latitude, longitude } = delivery_detail;

          if (latitude && longitude) {
            return (
              <Marker
                key={ticket.id}
                position={[latitude, longitude]}
                icon={DivIcon({ text: index + 1 })}
              >
                <Popup maxWidth={'95%'}>
                  <div style={{ marginBottom: 10 }}>
                    <Button onClick={() => removeItem(item.id)}>Remove Ticket</Button>
                  </div>
                  <Ticket data={ticket} />
                </Popup>
              </Marker>
            );
          }
        } else {
          // Location
        }
      })
    );
  };

  const handleAvailableTicketMarkers = () => {
    return ticketResults.map((ticket) => {
      const { site } = ticket;
      const { delivery_detail } = site;
      const { latitude, longitude } = delivery_detail;
      let backgroundColor = `#${ticket.product.hex_code}` || '#52c41a';
      let icon = 'fa-ticket-alt';

      if (selectedTicketResults.find((r) => r.id === ticket.id)) {
        backgroundColor = '#297305';
        icon = 'fa-plus';
      }
      if (latitude && longitude) {
        return (
          <Marker
            key={ticket.id}
            position={[latitude, longitude]}
            icon={DivIcon({ backgroundColor, icon })}
          >
            <Popup maxWidth={'95%'}>
              <div style={{ marginBottom: 10 }}>
                <Button onClick={() => addTicket(ticket.id)} type="primary">
                  Add Ticket
                </Button>
              </div>
              <Ticket data={ticket} />
            </Popup>
          </Marker>
        );
      }
    });
  };

  const createClusterCustomIcon = (cluster) => {
    const count = cluster.getChildCount();

    return divIcon({
      html: renderToStaticMarkup(
        <div>
          <span className={styles.markerCluster}>{count}</span>
        </div>
      ),
      className: `${styles.markerClusterLabel}`
    });
  };
  return (
    <Map
      center={getCenterCoords()}
      routeShape={routeShape}
      tickets={tickets}
      ticketResults={ticketResults}
      selectedTicketResults={selectedTicketResults}
      setSelectedTicketResults={setSelectedTicketResults}
      addSelectedTicketResults={addSelectedTicketResults}
      handleAddTicket={addTicket}
    >
      <React.Fragment>
        {handleLocationMarkers()}

        {handleTicketMarkers()}

        <MarkerClusterGroup
          maxClusterRadius={1}
          iconCreateFunction={createClusterCustomIcon}
          showCoverageOnHover={false}
          spiderLegPolylineOptions={{
            weight: 0,
            opacity: 0
          }}
        >
          {handleAvailableTicketMarkers()}
        </MarkerClusterGroup>
      </React.Fragment>
    </Map>

    // <Map
    //   height={height}
    //   locations={locations}
    //   routeShape={routeShape}
    //   tickets={tickets}
    //   ticketResults={ticketResults}
    //   selectedTicketResults={selectedTicketResults}
    //   setSelectedTicketResults={setSelectedTicketResults}
    //   addSelectedTicketResults={addSelectedTicketResults}
    // />
  );
};

TripEditMap.propTypes = {
  setTicketState: PropTypes.func,
  tickets: PropTypes.array,
  searchResultTickets: PropTypes.array
};

export default TripEditMap;
