import React, { useState } from 'react';
import { Row, Col, Form, AutoComplete } from 'antd';
import axios from 'axios';
import styles from './InventorySearchForm.module.css';

function InventorySearchForm(props) {
  const { parentParams = {}, setData = () => {}, purchaseOrder = {} } = props;
  const [dataSource, setDataSource] = useState([]);

  const searchInventory = async (data) => {
    await axios.get('/inventories', { params: { ...parentParams, ...data } }).then((res) => {
      const dataSourceData = res.data.records.map((item) => {
        return {
          text: (
            <span>
              <span style={{ paddingRight: 10 }}>
                <strong>[{item.qty_on_hand}]</strong>
              </span>
              <span>
                {item.product.product} - {item.product.description}
              </span>
            </span>
          ),
          data: item,
          value: item.id
        };
      });
      setDataSource(dataSourceData);
    });
  };
  return (
    <div className={styles.inventorySearchForm}>
      <Form layout="vertical">
        <Row gutter={[0, 0]}>
          <Col>
            <h5>Select Product</h5>
            <AutoComplete
              className={styles.searchInput}
              dataSource={dataSource}
              style={{ width: 200 }}
              onSelect={(itemId) => {
                const item = dataSource.find((item) => {
                  return item.data.id === Number(itemId);
                });
                setData({
                  inv_product: item.data.product.product,
                  inv_link: item.data.product.link,
                  inv_location: 1,
                  description: item.data.product.description,
                  qty: 1,
                  price: (Number(item.data.wac) / 0.7).toFixed(2), // 30% Margin
                  cost: Number(item.data.wac).toFixed(2),
                  taxable: true,
                  purchase_order_id: purchaseOrder.id
                });
              }}
              onSearch={(data) => searchInventory({ product: data })}
              placeholder="Search By Product Code or Description"
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

InventorySearchForm.propTypes = {};
export default InventorySearchForm;
