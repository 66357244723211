import React, { useEffect, useContext, useState } from 'react';
import { Row, Col, Form, Input, TimePicker, Select } from 'antd';
import moment from 'moment';
import TripEditContext from '../../store/TripEditContext';
import { SET_ORIGIN_LOCATION_ID, SET_DESTINATION_LOCATION_ID } from '../../store/TripEditStore';

import './TripForm.css';

const { Option } = Select;

function TripForm({ form, trip = {}, drivers = [], trucks = [] }) {
  const { start_at, title, truck_id, driver_id } = trip;
  const { getFieldDecorator } = form;

  const time = moment.unix(start_at);

  return (
    <div className="TripForm">
      <Form layout="vertical">
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label="Trip Name">
              {getFieldDecorator('title', {
                initialValue: title,
                rules: [{ required: true, message: 'Trip Name is required' }]
              })(<Input placeholder="Trip 1" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            {' '}
            <Form.Item label="Estimated Start Time">
              {getFieldDecorator('start_at', {
                initialValue: start_at ? time : null,
                rules: [{ required: true, message: 'Start Time is required' }]
              })(
                <TimePicker use12Hours minuteStep={10} format="h:mm a" style={{ width: '100%' }} />
              )}
            </Form.Item>
          </Col>
        </Row>

        <React.Fragment>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item label="Truck">
                {getFieldDecorator('truck_id', {
                  initialValue: truck_id || '',
                  rules: [{ required: true }]
                })(
                  <Select placeholder="Select Truck">
                    {trucks.map((t) => (
                      <Option key={t.id} value={t.id}>
                        {t.code}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Driver">
                {getFieldDecorator('driver_id', {
                  initialValue: driver_id || '',
                  rules: [{ required: true }]
                })(
                  <Select placeholder="Select Driver">
                    {drivers.map((d) => (
                      <Option key={d.id} value={d.id}>
                        {d.full_name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </React.Fragment>
      </Form>
    </div>
  );
}

TripForm.propTypes = {};

const WrappedTripForm = Form.create({
  name: 'tripForm'
})(TripForm);
export default WrappedTripForm;
