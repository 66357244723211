import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Body from '../../components/Body/Body';
import Content from '../../components/Content/Content';
import { PageHeader, Breadcrumb, Descriptions, Statistic, Row, Col, Icon } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { handleError } from '../../utils/handleError';
import Loader from '../../components/Loader/Loader';
import TripList from '../TripList/TripListContainer';
import DispatchShowMap from './DispatchShowMap';
import styles from './DispatchShowMapContainer.module.css';

export default function DispatchShowMapContainer(props) {
  const dispatchId = props.match.params.dispatch_id;
  const [dispatch, setDispatch] = useState({});
  const [loading, setLoading] = useState(false);
  const [ticketStatuses, setTicketStatuses] = useState([]);
  const interval = useRef(null);
  const { id, trips } = dispatch;

  useEffect(() => {
    async function fetchTicketStatuses() {
      const res = await axios.get(`/ticket_statuses`, {
        pageSize: 0,
        columnKey: 'id',
        order: 'ascend'
      });
      setTicketStatuses(res.data.records);
    }

    interval.current = setInterval(() => {
      fetchDispatch(dispatchId, false);
    }, 60 * 1000);

    fetchDispatch(dispatchId);
    fetchTicketStatuses();

    return () => {
      clearInterval(interval.current);
    };
  }, [id]);

  const fetchDispatch = (id, useLoader = true) => {
    useLoader && setLoading(true);
    axios
      .get(`/dispatches/${id}`)
      .then(async (res) => {
        // setDispatch(res.data);
        // for each trip fetch
        let newTrips = [];
        const dispatch = res.data;
        const { trips } = dispatch;

        for (let trip of trips) {
          await axios.get(`trips/${trip.id}`).then((res) => {
            newTrips.push(res.data);
          });
        }
        setDispatch({ ...dispatch, trips: newTrips });
        useLoader && setLoading(false);
      })
      .catch((err) => handleError(err));
  };

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/dispatches">Dispatches</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to={`/dispatches/${id}`}>Dispatch {id}</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Map</Breadcrumb.Item>
    </Breadcrumb>
  );

  if (loading) {
    return <Loader display={true} />;
  }

  return (
    <React.Fragment>
      <Content className={styles.header} breadcrumb={breadcrumb}></Content>
      <div className={styles.map}>
        <DispatchShowMap
          ticketStatuses={ticketStatuses}
          trips={trips}
          fetchDispatch={() => fetchDispatch(dispatchId)}
        />
      </div>
    </React.Fragment>
  );
}
