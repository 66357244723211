export const googleStreet = {
  name: 'Street',
  url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
  maxZoom: 20,
  subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
};

export const googleHybrid = {
  name: 'Hybrid',
  url: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
  maxZoom: 20,
  subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
};
export const googleSat = {
  name: 'Satellite',
  url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
  maxZoom: 20,
  subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
};

export const googleTerrain = {
  name: 'Terrain',
  url: 'http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
  maxZoom: 20,
  subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
};

export const googleTraffic = {
  name: 'Traffic',
  url: 'https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}',
  maxZoom: 20,
  subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
};

export default [
  { ...googleStreet },
  { ...googleHybrid },
  { ...googleSat },
  { ...googleTerrain },
  { ...googleTraffic }
];
