import React, { useState, useEffect } from 'react';
import { Input, Button, DatePicker, Select, Layout, PageHeader, Tooltip, message } from 'antd';
import { EditOutlined, CheckOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import axios from 'axios';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import TicketListMap from './TicketListMap';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import TicketEditContainer from '../TicketEditModal/TicketEditContainer';

import { getTicketStatusTag } from '../../utils/constants';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Content } = Layout;

export default function SiteListContainer(props) {
  const ticketId = props.match.params.ticket_id;
  const queryParams = queryString.parse(props.location.search);
  const { date } = queryParams;

  let name = 'Ticket';
  const path = '/tickets';
  const [state, attrs] = useResourceList(
    path,
    name,
    {},
    { date, columnKey: 'id', order: 'descend' },
    { pageSize: 25 }
  );

  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [ticketStatuses, setTicketStatuses] = useState([]);
  const [products, setProducts] = useState([]);
  const [isSyncWithSimpleLoading, setIsSyncWithSimpleLoading] = useState(false);
  // Load Statuses && Products
  useEffect(() => {
    async function fetchTicketStatuses() {
      const res = await attrs.fetch(`/ticket_statuses`, {
        pageSize: 0,
        columnKey: 'id',
        order: 'ascend'
      });
      setTicketStatuses(res.data.records);
    }

    async function fetchProducts() {
      const res = await attrs.fetch(`/products`, {
        pageSize: 0,
        columnKey: 'id',
        order: 'ascend'
      });
      setProducts(res.data.records);
    }

    fetchProducts();
    fetchTicketStatuses();
  }, []);

  const columns = [
    {
      title: 'ID',
      key: 'id',
      render: (record) => {
        const index = data.findIndex((item) => item.id === record.id) + 1;

        if (record && record.site && record.site.delivery_detail) {
          const { delivery_detail } = record.site;
          if (delivery_detail && delivery_detail.latitude) return index;
        }
        return (
          <span
            style={{
              color: 'white',
              backgroundColor: 'red',
              padding: '5px 7px 5px 7px',
              marginLeft: -7
            }}
          >
            {index}
          </span>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'ticket_status_id',
      key: 'ticket_status_id',
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'ticket_status_id' && attrs.sortInfo.order,
      filters: ticketStatuses
        ? ticketStatuses.map((status) => ({ text: status.title, value: status.id }))
        : [{ text: 'Temp', value: 'Value' }],
      filteredValue: attrs.filters.ticket_status_id || null,
      render: (data) => getTicketStatusTag(data)
    },
    {
      title: 'Ticket',
      dataIndex: 'ticket_number',
      key: 'ticket_number',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Ticket Number"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            />

            <Button
              type="link"
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.ticket_number || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'ticket_number' && attrs.sortInfo.order
    },
    {
      title: 'Customer',
      dataIndex: 'account_number',
      key: 'account_number',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Customer Number"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            />

            <Button
              type="link"
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.account_number || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'account_number' && attrs.sortInfo.order
    },
    {
      title: 'Site',
      dataIndex: 'tank_id',
      key: 'tank_id',
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'tank_id' && attrs.sortInfo.order
    },
    {
      title: 'Delivery Date',
      dataIndex: 'delivery_date',
      key: 'delivery_date',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <RangePicker
              defaultValue={selectedKeys && [moment(selectedKeys[0]), moment(selectedKeys[1])]}
              onChange={(dateArray) => {
                // dateArray = [moment, moment]
                // We need to convert to a value that is ready for our api
                setSelectedKeys(
                  dateArray
                    ? [
                        moment(dateArray[0]).format('YYYY-MM-DD'),
                        moment(dateArray[1]).format('YYYY-MM-DD')
                      ]
                    : []
                );
              }}
              style={{ marginBottom: 8, display: 'block' }}
            />

            <Button
              type="link"
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.delivery_date || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: true, // Just show the button, do not sort locally
      sortOrder: attrs.sortInfo.columnKey === 'delivery_date' && attrs.sortInfo.order,
      render: (data) => data && moment.unix(data).format('MM-DD-YYYY (dddd)')
    },

    {
      title: 'Last Name',
      dataIndex: 'site.customer',
      key: 'last_name',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Last Name"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            />

            <Button
              type="link"
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.last_name || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      render: (data) => data && `${data.last_name}`
    },

    {
      title: 'First Name',
      dataIndex: 'site.customer',
      key: 'first_name',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="First Name"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            />

            <Button
              type="link"
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.first_name || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      render: (data) => data && `${data.first_name}`
    },

    {
      title: 'Address Line 1',
      dataIndex: 'site.delivery_detail.address_line_1',
      key: 'site.delivery_detail.address_line_1'
    },

    {
      title: 'Address Line 2',
      dataIndex: 'site.delivery_detail.address_line_2',
      key: 'site.delivery_detail.address_line_2'
    },
    {
      title: 'Gallons',
      // dataIndex: 'delivery_gallons',
      key: 'delivery_gallons',
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'delivery_gallons' && attrs.sortInfo.order,
      render: (record) => {
        if (record.manually_estimated_gallons) {
          return (
            <div>
              {record.delivery_gallons} <span style={{ color: '#999' }}>[M]</span>
            </div>
          );
        } else if (record.fill) {
          return (
            <div>
              {record.delivery_gallons} <span style={{ color: '#999' }}>[E]</span>
            </div>
          );
        } else {
          return record.delivery_gallons;
        }
      }
    },
    {
      title: 'Fill',
      dataIndex: 'fill',
      key: 'fill',
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'fill' && attrs.sortInfo.order,
      filters: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      filteredValue: attrs.filters.fill || null,
      render: (data) => (data === true ? 'Yes' : 'No')
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product_code',
      filters: products.map((product) => ({ text: product.title, value: product.code })),
      filteredValue: attrs.filters.product_code || null,
      render: (data) => data && data.title
    },
    {
      title: 'Print Group',
      dataIndex: 'type',
      key: 'type',
      sortOrder: attrs.sortInfo.columnKey === 'store_id' && attrs.sortInfo.order,
      filters: [
        {
          text: 'AT',
          value: 'AUTO'
        },
        {
          text: 'WC',
          value: 'DEMAND'
        }
      ],
      filteredValue: attrs.filters.type || null,
      render: (data) => data
    },
    {
      title: 'Route',
      dataIndex: 'site.route',
      key: 'route',
      // TODO get these from the db and populate
      filters: routes
        ? routes.map((route) => ({ text: route.description, value: route.name }))
        : [{ text: 'Temp', value: 'Value' }],
      filteredValue: attrs.filters.route || null
    },
    {
      title: 'Region',
      dataIndex: 'site.region',
      key: 'region',
      filters: [
        {
          text: 'Bedford',
          value: '1'
        },
        {
          text: 'New Cumberland',
          value: '2'
        }
      ],
      filteredValue: attrs.filters.region || null
    },

    {
      title: 'Validated',
      dataIndex: 'validated',
      key: 'validated',
      filters: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      filteredValue: attrs.filters.validated || null,
      render: (value) => (value ? 'Yes' : 'No')
    },

    {
      title: 'Manually Estimated',
      dataIndex: 'manually_estimated_gallons',
      key: 'manually_estimated_gallons',
      editable: true,
      filters: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      filteredValue: attrs.filters.manually_estimated_gallons || null,
      render: (value) => (value ? 'Yes' : 'No')
    },

    {
      title: 'Trip',
      dataIndex: 'trip_id',
      key: 'trip_id',
      render: (tripId) =>
        tripId && (
          <Link target="_blank" to={`/dispatches/0/trips/${tripId}/edit`}>
            {tripId}
          </Link>
        )
    },

    {
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  // Load Routes
  useEffect(() => {
    async function fetchRoutes() {
      const res = await attrs.fetch(`/routes`, {
        pageSize: 0,
        columnKey: 'name',
        order: 'ascend',
        store_id: attrs.filters.region || null
      });
      setRoutes(res.data.records);
    }
    fetchRoutes();
  }, [attrs.filters.region]);

  useEffect(() => {
    ticketId && toggleModal(this, ticketId);
  }, [ticketId]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList({
      pagination: attrs.pagination,
      sorter: attrs.sortInfo,
      filters: attrs.filters
    });
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const data = state.data
    ? state.data.map((item) => {
        const { validated } = item;
        return {
          ...item,
          actions: (
            <div>
              {item && (
                <>
                  <Tooltip title="Edit Ticket">
                    <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
                      <EditOutlined />
                    </a>
                  </Tooltip>
                  {!validated && (
                    <>
                      <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
                      <Tooltip title="Validate Ticket">
                        <a
                          href="#!"
                          onClick={() => {
                            // Send Request
                            axios.patch(`/tickets/${item.id}`, { validated: true }).then((res) => {
                              message.success(`Ticket successfully validated`);
                              // Refresh Page
                              attrs.fetchResourceListManually({
                                sortInfo: attrs.sortInfo,
                                filters: attrs.filters
                              });
                            });
                          }}
                        >
                          <CheckOutlined />
                        </a>
                      </Tooltip>
                    </>
                  )}
                </>
              )}
            </div>
          )
        };
      })
    : [];

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
        width="95%"
      >
        <TicketEditContainer
          handleCancel={toggleModal}
          id={resourceId}
          onSave={onModalSave}
          ticketStatuses={ticketStatuses}
        />
      </ResourceModal>
      <TicketListMap tickets={data} />
      <Layout>
        <Content
          style={{
            background: '#fff',
            marginLeft: 30,
            marginRight: 30,
            marginTop: 30,

            padding: 15,
            textAlign: 'right'
          }}
        >
          <Button
            loading={isSyncWithSimpleLoading}
            onClick={() => {
              setIsSyncWithSimpleLoading(true);
              axios
                .get('https://sacenergy.simpleenergyapp.com/api/trigger-external-sync')
                .then((res) => {
                  console.log(res);
                });
              new Promise((resolve) => {
                setTimeout(resolve, 5 * 1000); // 5 seconds
              }).then(() => {
                setIsSyncWithSimpleLoading(false);
              });
            }}
            type="primary"
          >
            Trigger Sync with Simple
          </Button>
        </Content>

        <Content style={{ background: '#fff', marginLeft: 30, marginRight: 30, marginTop: 30 }}>
          <PageHeader title="Preset Views" />

          <Select
            defaultValue={0}
            style={{ width: '95%', marginLeft: 20, marginBottom: 20 }}
            onChange={(value) => {
              switch (value) {
                case 0:
                  break;
                case 999:
                  attrs.fetchResourceListManually({
                    sortInfo: {},
                    filters: {}
                  });
                  break;
                case 1:
                  const columnInfo = attrs.columnInfo;
                  // TODO Change this to columnsToShow later and move to DB
                  const columnsToHide = [
                    'ID',
                    'Status',
                    'Ticket',
                    'Print Group',
                    'Region',
                    'Manually Estimated',
                    'Trip'
                  ];
                  const updatedColumnInfo = columnInfo.map((column) => {
                    if (columnsToHide.includes(column.title)) {
                      column.visible = false;
                    }
                    return column;
                  });
                  attrs.setColumnInfo(updatedColumnInfo);
                  attrs.fetchResourceListManually({
                    sortInfo: {},
                    filters: { validated: [false], region: ['1'] }
                  });
                  break;
                case 2:
                  attrs.fetchResourceListManually({
                    sortInfo: {},
                    filters: { validated: [false], region: ['2'] }
                  });
                  break;
                case 3:
                  attrs.fetchResourceListManually({
                    sortInfo: {},
                    filters: {
                      region: ['1'],
                      delivery_date: [
                        moment()
                          .subtract(25, 'years')
                          .format('YYYY-MM-DD'),
                        moment()
                          .subtract(1, 'days')
                          .format('YYYY-MM-DD')
                      ],
                      ticket_status_id: [1, 2, 6, 7, 8, 9]
                    }
                  });
                  break;
                case 4:
                  attrs.fetchResourceListManually({
                    sortInfo: {},
                    filters: {
                      region: ['2'],
                      delivery_date: [
                        moment()
                          .subtract(25, 'years')
                          .format('YYYY-MM-DD'),
                        moment()
                          .subtract(1, 'days')
                          .format('YYYY-MM-DD')
                      ],
                      ticket_status_id: [1, 2, 6, 7, 8, 9]
                    }
                  });
                  break;
                case 5:
                  attrs.fetchResourceListManually({
                    sortInfo: {},
                    filters: {
                      region: ['1'],
                      delivery_date: [
                        moment()
                          .add(1, 'days')
                          .format('YYYY-MM-DD'),
                        moment()
                          .add(1, 'days')
                          .format('YYYY-MM-DD')
                      ]
                    }
                  });
                  break;
                case 6:
                  attrs.fetchResourceListManually({
                    sortInfo: {},
                    filters: {
                      region: ['2'],
                      delivery_date: [
                        moment()
                          .add(1, 'days')
                          .format('YYYY-MM-DD'),
                        moment()
                          .add(1, 'days')
                          .format('YYYY-MM-DD')
                      ]
                    }
                  });
                  break;

                case 7:
                  attrs.fetchResourceListManually({
                    sortInfo: { columnKey: 'delivery_date', order: 'descend' },
                    filters: {
                      type: ['AUTO'],
                      region: ['1'],
                      ticket_status_id: [1, 2, 6, 7, 8, 9]
                    }
                  });
                  break;

                case 8:
                  attrs.fetchResourceListManually({
                    sortInfo: { columnKey: 'delivery_date', order: 'descend' },
                    filters: {
                      type: ['AUTO'],
                      region: ['2'],
                      ticket_status_id: [1, 2, 6, 7, 8, 9]
                    }
                  });
                  break;

                case 9:
                  attrs.fetchResourceListManually({
                    sortInfo: {},
                    filters: {
                      region: ['1'],
                      delivery_date: [
                        moment()
                          .add(1, 'months')
                          .format('YYYY-MM-DD'),
                        moment()
                          .add(100, 'years')
                          .format('YYYY-MM-DD')
                      ],
                      ticket_status_id: [1, 2, 6, 7, 8, 9]
                    }
                  });
                  break;

                case 10:
                  attrs.fetchResourceListManually({
                    sortInfo: {},
                    filters: {
                      region: ['2'],
                      delivery_date: [
                        moment()
                          .add(1, 'months')
                          .format('YYYY-MM-DD'),
                        moment()
                          .add(100, 'years')
                          .format('YYYY-MM-DD')
                      ],
                      ticket_status_id: [1, 2, 6, 7, 8, 9]
                    }
                  });
                  break;

                default:
                  break;
              }
            }}
          >
            <Option value={0}>Select A Saved View</Option>
            <Option value={null}></Option>
            <Option value={999}>Reset All Filters</Option>
            <Option value={null}></Option>

            <Option value={1}>Validate Tickets - Bedford</Option>
            <Option value={5}>Delivery Date Tomorrow - Bedford</Option>
            <Option value={3}>Undelivered Tickets - Bedford</Option>
            <Option value={7}>Automatics - Bedford</Option>
            <Option value={9}>Tickets Far in the Future - Bedford</Option>
            <Option value={null}></Option>

            <Option value={2}>Validate Tickets - New Cumberland</Option>
            <Option value={6}>Delivery Date Tomorrow - New Cumberland</Option>
            <Option value={4}>Undelivered Tickets - New Cumberland</Option>
            <Option value={8}>Automatics - New Cumberland</Option>
            <Option value={10}>Tickets Far in the Future - New Cumberland</Option>
          </Select>
        </Content>
      </Layout>

      <ResourceList
        columns={attrs.columns}
        setColumns={(value) => attrs.setColumns(value)}
        data={data}
        {...attrs}
      />
    </React.Fragment>
  );
}
