import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Body from '../../components/Body/Body';
import Content from '../../components/Content/Content';
import { PageHeader, Breadcrumb, Descriptions, Statistic, Row, Col, Icon } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { handleError } from '../../utils/handleError';
import TripList from '../TripList/TripListContainer';

import styles from './DispatchShowContainer.module.css';

export default function DispatchShowContainer(props) {
  const dispatchId = props.match.params.dispatch_id;
  const [dispatch, setDispatch] = useState({});
  const [loading, setLoading] = useState(false);
  const { id, date, truck = {}, driver = {}, total_trips, total_gallons, total_tickets } = dispatch;

  useEffect(() => {
    fetchDispatch(dispatchId);
  }, [id]);

  const fetchDispatch = (id) => {
    setLoading(true);
    axios
      .get(`/dispatches/${id}`)
      .then((res) => {
        setDispatch(res.data);
        setLoading(false);
      })
      .catch((err) => handleError(err));
  };

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/dispatches">Dispatches</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Dispatch {id}</Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <React.Fragment>
      <Content className={styles.header} breadcrumb={breadcrumb}>
        <PageHeader />
        <Body>
          <Row gutter={16}>
            <Col span={8}>
              <Statistic title="Date" value={moment(date).format('dddd, MMMM Do YYYY')} />
            </Col>
            <Col span={8}></Col>
            <Col span={8}></Col>
          </Row>

          <Row gutter={16} style={{ marginTop: 25 }}>
            <Col span={8}>
              <Statistic title="Total Trips" value={total_trips} />
            </Col>
            <Col span={8}>
              <Statistic title="Total Gallons" value={total_gallons} />
            </Col>
            <Col span={8}>
              <Statistic title="Total Tickets" value={total_tickets} />
            </Col>
          </Row>
        </Body>
      </Content>
      <TripList className={styles.compartments} dispatchId={dispatchId} />
    </React.Fragment>
  );
}
