import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loader from '../../components/Loader/Loader';
import useResourceEdit from '../../hooks/useResourceEdit';
import ResourceFormActions from '../../components/ResourceFormActions/ResourceFormActions';
import DispatchForm from '../../components/forms/DispatchForm/DispatchForm';

export default function DispatchEditContainer({ id, handleCancel = () => {}, onSave = () => {} }) {
  const [state, attrs] = useResourceEdit({ path: '/dispatches', name: 'Dispatch', id, onSave });
  const { loading, data } = state;
  const { handleSubmit, setForm } = attrs;
  const [stores, setStores] = useState();
  const [drivers, setDrivers] = useState();
  const [trucks, setTrucks] = useState();

  // Run First Load
  useEffect(() => {
    axios.get(`/stores?pageSize=10000`).then((res) => setStores(res.data.records));
    axios.get(`/trucks?pageSize=10000&active=true`).then((res) => setTrucks(res.data.records));
    axios.get(`/drivers?pageSize=10000&active=true`).then((res) => setDrivers(res.data.records));
    return () => {
      setStores();
      setDrivers();
      setTrucks();
    };
  }, []);

  if (loading) {
    return <Loader display={true} />;
  }

  return (
    <React.Fragment>
      <DispatchForm
        data={data}
        stores={stores}
        drivers={drivers}
        trucks={trucks}
        ref={(ref) => setForm(ref)}
      />
      <ResourceFormActions id={id} handleCancel={handleCancel} handleSubmit={handleSubmit} />
    </React.Fragment>
  );
}
