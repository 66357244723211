import { DISPATCH_EDIT_SET_DISPATCH, DISPATCH_EDIT_SET_SAVING } from './types';

const initialState = {
  dispatch: null,
  saving: false
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DISPATCH_EDIT_SET_DISPATCH:
      return {
        ...state,
        dispatch: payload
      };
    case DISPATCH_EDIT_SET_SAVING:
      return {
        ...state,
        saving: payload
      };
    default:
      return state;
  }
};
