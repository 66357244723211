import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import L, { divIcon } from 'leaflet';
import { Typography } from 'antd';

import styles from './MarkerIconV3.module.css';
import '@fortawesome/fontawesome-free/css/all.css';

const { Text } = Typography;

function MarkerIcon({ backgroundColor = '#76bff7', icon, text, textBackgroundColor = '#000' }) {
  return (
    <React.Fragment>
      <div style={{ backgroundColor }} className={styles.markerPin}>
        {icon && (
          <i
            style={{ color: textBackgroundColor }}
            className={[styles.icon, 'fa', icon].join(' ')}
          ></i>
        )}
        {text && (
          <span style={{ color: textBackgroundColor }} className={styles.text}>
            {text}
          </span>
        )}
      </div>
    </React.Fragment>
  );
}

export default ({ backgroundColor = '#76bff7', icon, text, textBackgroundColor }) =>
  divIcon({
    className: 'custom-div-icon',
    html: renderToStaticMarkup(
      <MarkerIcon
        backgroundColor={backgroundColor}
        icon={icon}
        text={text}
        textBackgroundColor={textBackgroundColor}
      />
    ),
    iconSize: [30, 42],
    iconAnchor: [15, 42],
    popupAnchor: [0, -22]
  });
