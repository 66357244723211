import React from 'react';
import { Spin } from 'antd';
import styles from './Loader.module.css';

export const Loader = ({ tip, display = true }) => {
  const style = {
    display: display ? 'block' : 'none'
  };

  return (
    <div style={style} className={styles.loader}>
      <div className={styles.spinner}>
        <Spin tip={tip} />
      </div>
    </div>
  );
};

export default Loader;
