import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { message } from 'antd';
// options
// pageSize
export default function useResourceList(
  path,
  name,
  parentParams = {},
  defaultParams = {},
  options = {}
) {
  const [data, setData] = useState(null);
  const [sortInfo, setSortInfo] = useState({});
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(null);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [columnInfo, setColumnInfo] = useState([]);

  const pagination = {
    current: page,
    pageSize: pageSize,
    total: totalRecords,
    pageSizeOptions: ['10', '25', '50', '100', '500', '10000'],
    showSizeChanger: true
  };

  useEffect(() => {
    fetchResourceList();
  }, []);

  useEffect(() => {
    if (columnInfo.length <= 0) {
      setColumnInfo(
        columns.map((col) => ({
          title: col.title,
          visible: col.visible === false ? false : true
        }))
      );
    }
  }, [columns]);

  const fetchResourceListManually = ({ sortInfo, filters, customPagination }) => {
    setFilters(filters);
    setSortInfo(sortInfo);
    fetchResourceList({ pagination: customPagination || pagination, sorter: sortInfo, filters });
  };

  const fetchResourceList = (params = {}) => {
    const { pagination = {}, sorter = {}, filters } = params;
    const { current = null, pageSize } = pagination;

    setLoading(true);
    let fetchParams = current ? { page: current, pageSize } : { page, pageSize };
    fetchParams = { ...fetchParams, ...sorter, ...filters };

    setPage(fetchParams.page);
    setPageSize(fetchParams.pageSize);
    setSortInfo(sorter);
    setFilters(filters || {});

    axios
      .get(path, { params: { ...defaultParams, ...fetchParams, ...parentParams } })
      .then((res) => {
        setData(res.data.records);

        setTotalRecords(res.data.pagination.total_records);
        setLoading(false);
      });
  };

  const removeResource = (id) => {
    setLoading(true);
    axios
      .delete(`${path}/${id}`, { params: parentParams })
      .then((res) => {
        fetchResourceList();
        message.success(`${name} successfully removed`);
      })
      .catch((err) => {
        setLoading(false);
        message.error({ content: err.response.data.error, duration: 10 });
      });
  };

  const handleTableChange = (pagination, filters = {}, sorter = {}) => {
    const { columnKey, order } = sorter;
    fetchResourceList({ pagination, sorter: { columnKey, order }, filters });
  };

  const handleClearAll = () => {
    handleTableChange(pagination);
  };

  const fetch = async (url, params) => {
    return await axios.get(url, { params });
  };

  // console.log(sortInfo, filters, pagination);

  const attrs = {
    name,
    loading,
    sortInfo,
    filters,
    fetch,
    handleTableChange,
    handleClearAll,
    fetchResourceList,
    fetchResourceListManually,
    removeResource,
    setLoading,
    setSortInfo,
    setFilters,
    pagination,
    columns,
    setColumns,
    columnInfo,
    setColumnInfo,
    setData
  };

  const state = {
    data
  };

  return [state, attrs];
}
