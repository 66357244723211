import React, { useState, useEffect } from 'react';
import {
  Input,
  Button,
  DatePicker,
  Select,
  Layout,
  PageHeader,
  Tooltip,
  Table,
  message
} from 'antd';
import { EditOutlined, CheckOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import axios from 'axios';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';

import ResourceModal from '../../components/ResourceModal/ResourceModal';
import TicketEditContainer from '../TicketEditModal/TicketEditContainer';

import { getTicketStatusTag } from '../../utils/constants';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Content } = Layout;

export default function CustomerListContainer(props) {
  const inventoryId = props.match.params.ticket_id;
  const queryParams = queryString.parse(props.location.search);
  const { date } = queryParams;

  let name = 'Customer';
  const path = '/customers';
  const [state, attrs] = useResourceList(
    path,
    name,
    {},
    { date, columnKey: 'id', order: 'descend' },
    { pageSize: 25 }
  );

  const { filters, setFilters } = attrs;
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [ticketStatuses, setTicketStatuses] = useState([]);

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'account_number',
      key: 'account_number',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Customer Number"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            />

            <Button
              type="link"
              onClick={() => handleSearch('account_number', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('account_number', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.account_number || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: true,
      sortOrder: attrs.sortInfo.columnKey === 'account_number' && attrs.sortInfo.order
    },

    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Last Name"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            />

            <Button
              type="link"
              onClick={() => handleSearch('last_name', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('last_name', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.last_name || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      }
    },

    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="First Name"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            />

            <Button
              type="link"
              onClick={() => handleSearch('first_name', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('first_name', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.first_name || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      }
    },
    {
      title: 'Address Line 1',
      dataIndex: 'address_line_1',
      key: 'address_line_1',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Address Line 1"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            />

            <Button
              type="link"
              onClick={() => handleSearch('address_line_1', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('address_line_1', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters['address_line_1'] || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: (a, b) => a.address_line_1 - b.address_line_1,
      sortOrder: attrs.sortInfo.columnKey === 'address_line_1' && attrs.sortInfo.order
    },

    {
      title: 'Address Line 2',
      dataIndex: 'address_line_2',
      key: 'address_line_2',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Address Line 2"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            />

            <Button
              type="link"
              onClick={() => handleSearch('address_line_2', selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset('address_line_2', clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters['address_line_2'] || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: (a, b) => a.address_line_2 - b.address_line_2,
      sortOrder: attrs.sortInfo.columnKey === 'address_line_2' && attrs.sortInfo.order
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  // Load Routes
  useEffect(() => {
    async function fetchRoutes() {
      const res = await attrs.fetch(`/routes`, {
        pageSize: 0,
        columnKey: 'name',
        order: 'ascend',
        store_id: attrs.filters.region || null
      });
      setRoutes(res.data.records);
    }
    fetchRoutes();
  }, [attrs.filters.region]);

  useEffect(() => {
    inventoryId && toggleModal(this, inventoryId);
  }, [inventoryId]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList({
      pagination: attrs.pagination,
      sorter: attrs.sortInfo,
      filters: attrs.filters
    });
  };

  const handleSearch = (key, selectedKeys, confirm) => {
    setFilters({ ...filters, [key]: selectedKeys });
    confirm();
  };

  const handleReset = (key, clearFilters) => {
    setFilters({ ...filters, [key]: null });
    clearFilters();
  };

  const expandedRowRender = (row, index, indent, expanded) => {
    const { sites = [] } = row;

    const columns = [
      { title: 'Tank Id', dataIndex: 'tank_id', key: 'tank_id' },
      { title: 'Product', dataIndex: 'product.product', key: 'product.product' },
      { title: 'Last Name', dataIndex: 'customer.last_name', key: 'customer.last_name' },
      { title: 'First Name', dataIndex: 'customer.first_name', key: 'customer.first_name' },
      {
        title: 'Address Line 1',
        dataIndex: 'delivery_detail.address_line_1',
        key: 'delivery_detail.address_line_1'
      },
      {
        title: 'Address Line 2',
        dataIndex: 'delivery_detail.address_line_2',
        key: 'delivery_detail.address_line_2'
      },
      { title: 'Region', dataIndex: 'region', key: 'region' }
    ];

    return (
      <Table
        style={{ margin: 15 }}
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={sites}
        pagination={false}
      />
    );
  };

  const data = state.data ? state.data : [];

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
        width="95%"
      >
        <TicketEditContainer
          handleCancel={toggleModal}
          id={resourceId}
          onSave={onModalSave}
          ticketStatuses={ticketStatuses}
        />
      </ResourceModal>

      <ResourceList
        columns={attrs.columns}
        setColumns={(value) => attrs.setColumns(value)}
        data={data}
        expandedRowRender={expandedRowRender}
        {...attrs}
      />
    </React.Fragment>
  );
}
