import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Body from '../../components/Body/Body';
import Content from '../../components/Content/Content';
import Stat from '../../components/Stat/Stat';
import { PageHeader, Breadcrumb, Row, Col, Statistic, Tag, Card, Button } from 'antd';
import {
  ProfileOutlined,
  EditOutlined,
  DeleteOutlined,
  PrinterOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { handleError } from '../../utils/handleError';
import StoreList from '../StoreList/StoreListContainer';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import PurchaseOrderItemList from '../PurchaseOrderItemList/PurchaseOrderItemListContainer';
import EquipmentLeaseList from './EquipmentLeaseList';
import EquipmentLeaseEditContainer from '../EquipmentLeaseEdit/EquipmentLeaseEditContainer';
import Equipment from './Equipment';
import styles from './EquipmentLeaseShowContainer.module.css';

export default function EquipmentShowContainer(props) {
  let name = 'Equipment Lease';
  const equipmentLeaseId = props.match.params.equipment_lease_id;
  const [equipmentLease, setEquipmentLease] = useState({});
  const [displayModal, setDisplayModal] = useState(false);
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [loading, setLoading] = useState(false);
  const { equipment = {} } = equipmentLease;

  const {
    id,
    active,
    start_at,
    end_at,
    invoiced_last_at,
    invoiced_through_at,
    is_signed,
    lease_type,
    minimum_gallons_for_rent_credit,
    minimum_gallons_rent_credit_price,
    rent_accrued,
    rent_cycle,
    rent_cycle_interval,
    rent_price,
    replacement_cost,
    site
  } = equipmentLease;

  useEffect(() => {
    fetchEquipment(equipmentLeaseId);
  }, [equipmentLeaseId]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    fetchEquipment(equipmentLeaseId);
  };

  const fetchEquipment = (id) => {
    setLoading(true);
    axios
      .get(`/equipment_leases/${id}`)
      .then((res) => {
        setEquipmentLease(res.data);
        setLoading(false);
      })
      .catch((err) => handleError(err));
  };

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/equipment_leases">Equipment Leases</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Equipment Lease #{equipmentLeaseId}</Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
        width="75%"
      >
        <EquipmentLeaseEditContainer
          handleCancel={toggleModal}
          id={resourceId}
          onSave={onModalSave}
        />
      </ResourceModal>

      <Content className={styles.header} breadcrumb={breadcrumb}>
        <PageHeader />
        <Body>
          <Row gutter={[64]}>
            <Col span={10}>
              <Card title={`Equipment Lease #${equipmentLease.id}`} bordered={false}>
                <h4>Status</h4>
                <p>{active ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>}</p>

                <h4>Customer</h4>
                <p>
                  {site && site.customer && (
                    <>
                      {site.customer.full_name}
                      <small> ({site.customer.account_number})</small>
                      <small> Site: {site.tank_id}</small>
                    </>
                  )}
                </p>

                <h4>Start On:</h4>
                <p>{start_at && moment.unix(start_at).format('YYYY-MM-DD')}</p>

                <h4>End On:</h4>
                <p>{end_at && moment.unix(end_at).format('YYYY-MM-DD')}</p>

                <h4>Equipment Replacement Cost:</h4>
                <p>{replacement_cost && `$` + Number(replacement_cost).toFixed(2)}</p>
              </Card>
            </Col>
            <Col span={10}>
              <Card title="Lease Type" bordered={false}>
                <h4>Lease Type:</h4>
                <p>{lease_type && lease_type.title}</p>

                <h4>Signed Lease?</h4>
                <p>{is_signed ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}</p>

                <h4>Rent Price:</h4>
                <p>{rent_price && '$' + Number(rent_price).toFixed(2)}</p>
              </Card>
            </Col>
            <Col span={4}>
              <Card title="Actions" bordered={false}>
                <p>
                  <Button>
                    <a href="#!" onClick={toggleModal.bind(this, null, equipmentLeaseId)}>
                      <EditOutlined /> Edit
                    </a>
                  </Button>
                </p>

                <p>
                  <Button>
                    <Link to={`/equipment_leases`}>
                      <ArrowLeftOutlined /> Back
                    </Link>
                  </Button>
                </p>
              </Card>
            </Col>
          </Row>

          <Row gutter={[64]}>
            <Col span={10}>
              <Card title={`Billing Details`} bordered={false}>
                <h4>Rent Cycle:</h4>
                <p>{rent_cycle && rent_cycle.title}</p>

                <h4>Rent Cycle Interval:</h4>
                <p>{rent_cycle_interval}</p>

                <h4>Minimum Gallons For Rent Credit:</h4>
                <p>{minimum_gallons_for_rent_credit}</p>

                <h4>Minimum Gallons Rent Credit Price:</h4>
                <p>
                  {minimum_gallons_rent_credit_price &&
                    '$' + Number(minimum_gallons_rent_credit_price).toFixed(2)}
                </p>
              </Card>
            </Col>
            <Col span={10}>
              <Card title={`Billing History`} bordered={false}>
                <h4>Invoiced Last On:</h4>
                <p>{invoiced_last_at && moment.unix(invoiced_last_at).format('YYYY-MM-DD')}</p>

                <h4>Invoiced Through:</h4>
                <p>
                  {invoiced_through_at && moment.unix(invoiced_through_at).format('YYYY-MM-DD')}
                </p>

                <h4>Accrued Rent:</h4>
                <p>{rent_accrued && '$' + Number(rent_accrued).toFixed(2)}</p>
              </Card>
            </Col>
            <Col span={4}></Col>
          </Row>
        </Body>
      </Content>
      <Equipment data={equipment} />

      {/* <EquipmentLeaseList equipment={equipment} equipmentLeaseId={equipmentLeaseId} /> */}
    </React.Fragment>
  );
}
