import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loader from '../../components/Loader/Loader';
import useResourceEdit from '../../hooks/useResourceEdit';
import ResourceFormActions from '../../components/ResourceFormActions/ResourceFormActions';
import InventorySearchForm from '../../components/forms/InventorySearchForm/InventorySearchForm';
import PurchaseOrderItemForm from '../../components/forms/PurchaseOrderItemForm/PurchaseOrderItemForm';

export default function PurchaseOrderItemEdit({
  id,
  handleCancel = () => {},
  onSave = () => {},
  profitCenterCode,
  purchaseOrder = {}
}) {
  const [state, attrs] = useResourceEdit({
    path: '/purchase_order_items',
    name: 'Purchase Order Item',
    id,
    onSave
  });
  const { loading, data } = state;
  const { handleSubmit, setForm, setData } = attrs;

  if (loading) {
    return <Loader display={true} />;
  }

  return (
    <React.Fragment>
      {!data && (
        <InventorySearchForm
          setData={setData}
          purchaseOrder={purchaseOrder}
          parentParams={{ profit_center: profitCenterCode }}
        />
      )}
      {data && (
        <>
          <PurchaseOrderItemForm
            data={data}
            ref={(form) => setForm(form)}
            parentParams={{ profit_center: profitCenterCode }}
          />

          <ResourceFormActions
            id={id}
            handleCancel={() => {
              handleCancel();
              setData(null); // Custom since we use a search function
            }}
            handleSubmit={(e) => {
              handleSubmit(e, () => {
                setData(null); // Pass onSuccess Function to handle clearing our data store
              });
            }}
          />
        </>
      )}
    </React.Fragment>
  );
}
