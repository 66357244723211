import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Divider, message } from 'antd';
import Loader from '../../components/Loader/Loader';
import useResourceEdit from '../../hooks/useResourceEdit';
import ResourceFormActions from '../../components/ResourceFormActions/ResourceFormActions';
import ManufacturerForm from '../../components/forms/ManufacturerForm/ManufacturerForm';

export default function ManufacturerEditContainer({
  id,
  handleCancel = () => {},
  onSave = () => {}
}) {
  const [state, attrs] = useResourceEdit({
    path: '/manufacturers',
    name: 'Manufacturer',
    id,
    onSave
  });
  const { loading, data } = state;
  const { handleSubmit, setForm } = attrs;
  const [stores, setStores] = useState();

  // Run First Load
  useEffect(() => {
    axios.get(`/stores`).then((res) => setStores(res.data.records));
    return () => {
      setStores();
    };
  }, []);

  if (loading) {
    return <Loader display={true} />;
  }

  return (
    <React.Fragment>
      <ManufacturerForm data={data} stores={stores} ref={(ref) => setForm(ref)} />
      <ResourceFormActions id={id} handleCancel={handleCancel} handleSubmit={handleSubmit} />
    </React.Fragment>
  );
}
