import React, { useContext } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import UserContext from '../../UserContext';
import styles from './Header.module.css';

const { Header: AntHeader } = Layout;
const { SubMenu } = Menu;

const dispatchRoles = [1, 2, 3, 4, 6];
const salesCommissionRoles = [1, 2];

export default function Header(props) {
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();

  const handleLogout = () => {
    // TODO refactor this to use useLocalstorage
    localStorage.removeItem('jwtToken');
    setUser();
    history.push('/login');
  };

  return (
    <AntHeader className={styles.header}>
      <div className={styles.logo}>
        dashboard
        <div className={styles.version}>v0.2.9</div>
      </div>

      {user && (
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['1']}
          style={{ lineHeight: '50px', float: 'right', background: 'none' }}
        >
          {!user && (
            <Menu.Item style={{ background: 'none' }} key={1}>
              <Link to="/login">Login</Link>
            </Menu.Item>
          )}

          {user && dispatchRoles.includes(user.role_id) && (
            <Menu.Item style={{ background: 'none' }} key={2}>
              <Link to="/dispatches">Dispatch</Link>
            </Menu.Item>
          )}

          <SubMenu title="Resources">
            <Menu.Item style={{ background: 'none' }} key={3}>
              <Link to="/tickets">Tickets</Link>
            </Menu.Item>
            <Menu.Item style={{ background: 'none' }} key={4}>
              <Link to="/sites">Delivery Sites</Link>
            </Menu.Item>
            <Menu.Item style={{ background: 'none' }} key={5}>
              <Link to="/drivers">Drivers</Link>
            </Menu.Item>
            <Menu.Item style={{ background: 'none' }} key={6}>
              <Link to="/stores">Stores</Link>
            </Menu.Item>
            <Menu.Item style={{ background: 'none' }} key={7}>
              <Link to="/trucks">Trucks</Link>
            </Menu.Item>
            <Menu.Item style={{ background: 'none' }} key={8}>
              <Link to="/fuel_discount_redemptions">Fuel Discount Redemptions</Link>
            </Menu.Item>
            <Menu.Item style={{ background: 'none' }} key={9}>
              <Link to="/inventories">Inventory</Link>
            </Menu.Item>
            <Menu.Item style={{ background: 'none' }} key={10}>
              <Link to="/purchase_orders">Purchase Orders</Link>
            </Menu.Item>

            <Menu.Item style={{ background: 'none' }} key={11}>
              <Link to="/manufacturers">Manufacturers</Link>
            </Menu.Item>

            <Menu.Item style={{ background: 'none' }} key={12}>
              <Link to="/equipments">Equipment</Link>
            </Menu.Item>

            <Menu.Item style={{ background: 'none' }} key={13}>
              <Link to="/equipment_leases">Equipment Leases</Link>
            </Menu.Item>

            {user && salesCommissionRoles.includes(user.role_id) && (
              <Menu.Item style={{ background: 'none' }} key={14}>
                <Link to="/sales_sites">Sales Commission</Link>
              </Menu.Item>
            )}
          </SubMenu>

          <Menu.Item onClick={handleLogout} style={{ background: 'none' }} key={15}>
            Logout
          </Menu.Item>
        </Menu>
      )}
    </AntHeader>
  );
}
