import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Input, InputNumber, Select } from 'antd';
import styles from './PurchaseOrderItemForm.module.css';

const { Option } = Select;
const { TextArea } = Input;

function PurchaseOrderItemForm({ form, data = {} }) {
  const {
    inv_product = null,
    inv_link = null,
    inv_location = null,
    description = null,
    qty = null,
    cost = 0.0,
    price = null,
    notes = null,
    taxable = null,
    group_as_misc = false,
    purchase_order_id = null
  } = data;

  console.log('What is data?', data);

  const { getFieldDecorator } = form;

  return (
    <Form className={styles.form} layout="vertical">
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Form.Item label="Product">
            {getFieldDecorator('inv_product', {
              initialValue: inv_product || null,
              rules: [{ required: true }]
            })(<Input style={{ width: '100%' }} placeholder="Product Code" readOnly={true} />)}
          </Form.Item>

          <Form.Item label="Description">
            {getFieldDecorator('description', {
              initialValue: description || '',
              rules: [{ required: true }]
            })(<Input placeholder="Product Description" />)}
          </Form.Item>

          <Form.Item label="Inventory Location">
            {getFieldDecorator('inv_location', {
              initialValue: inv_location || null,
              rules: [{ required: true }]
            })(<Input style={{ width: '100%' }} placeholder="Location" />)}
          </Form.Item>

          <Form.Item label="Qty">
            {getFieldDecorator('qty', {
              initialValue: qty || null,
              rules: [{ required: true, type: 'integer' }]
            })(<InputNumber style={{ width: '100%' }} placeholder="Qty" />)}
          </Form.Item>

          <Form.Item label={`Cost`}>
            {getFieldDecorator('cost', {
              initialValue: cost ? Number(cost) : null,
              rules: [{ required: true, type: 'number' }]
            })(<InputNumber style={{ width: '100%' }} placeholder="Cost" />)}
          </Form.Item>

          <Form.Item label={`Sales Price`}>
            {getFieldDecorator('price', {
              initialValue: price ? Number(price) : null,
              rules: [{ required: true, type: 'number' }]
            })(<InputNumber style={{ width: '100%' }} placeholder="Price" />)}
          </Form.Item>

          <Form.Item label="Taxable">
            {getFieldDecorator('taxable', {
              initialValue: taxable !== null ? taxable.toString() : 'true',
              rules: [{ required: true }]
            })(
              <Select placeholder="Taxable">
                <Option value="true">True</Option>
                <Option value="false">False</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Group as Misc Hardware">
            {getFieldDecorator('group_as_misc', {
              initialValue: group_as_misc !== null ? group_as_misc.toString() : 'true',
              rules: [{ required: true }]
            })(
              <Select placeholder="Taxable">
                <Option value="true">True</Option>
                <Option value="false">False</Option>
              </Select>
            )}
          </Form.Item>

          {getFieldDecorator('inv_link', {
            initialValue: inv_link || '',
            rules: [{ required: true }]
          })(<Input hidden={true} placeholder="" />)}

          {/* {getFieldDecorator('cost', {
            initialValue: cost || '',
            rules: [{ required: true }]
          })(<Input hidden={true} placeholder="" />)} */}

          {getFieldDecorator('purchase_order_id', {
            initialValue: purchase_order_id || '',
            rules: [{ required: true }]
          })(<Input hidden={true} placeholder="" />)}
        </Col>
      </Row>
      <Row>
        <Form.Item label="Notes">
          {getFieldDecorator('notes', {
            initialValue: notes || ''
          })(<TextArea rows={3} placeholder="Notes" />)}
        </Form.Item>
      </Row>
    </Form>
  );
}

PurchaseOrderItemForm.propTypes = {
  form: PropTypes.object,
  data: PropTypes.object
};

const WrappedPurchaseOrderItemForm = Form.create({ name: 'PurchaseOrderItemForm' })(
  PurchaseOrderItemForm
);
export default WrappedPurchaseOrderItemForm;
