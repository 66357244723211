import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ReactDragListView from 'react-drag-listview';
import { SET_ITEMS, calculateTotalGallonsByProduct } from '../../store/TripEditStore';
import TripEditContext from '../../store/TripEditContext';
import TicketListItem from './TicketListItem/TicketListItem';
import styles from './TicketList.module.css';

function TicketList() {
  const { state, dispatch, actions } = useContext(TripEditContext);
  const { removeItem } = actions;
  const { items = [], products } = state;

  const onDragEnd = (fromIndex, toIndex) => {
    const itemsCopy = [...items];
    const item = itemsCopy.splice(fromIndex, 1)[0];
    itemsCopy.splice(toIndex, 0, item);
    dispatch({ type: SET_ITEMS, payload: itemsCopy });
  };

  let legTicketItems = [];
  let totalGallonsByProduct;
  return (
    <div className={styles.ticketList}>
      <ReactDragListView
        nodeSelector="div.TicketListItem"
        handleSelector="span.sortHandle"
        className="TicketList"
        onDragEnd={onDragEnd}
      >
        {items.map((item, index) => {
          if (item.type === 'location') {
            totalGallonsByProduct = calculateTotalGallonsByProduct(legTicketItems);
            legTicketItems = [];
          } else {
            legTicketItems.push(item);
          }
          return (
            <TicketListItem
              key={index}
              data={item}
              index={index}
              handleRemoveItem={removeItem}
              type={item.type}
              totalGallonsByProduct={totalGallonsByProduct}
              products={products}
            />
          );
        })}
      </ReactDragListView>
    </div>
  );
}

TicketList.propTypes = {
  tickets: PropTypes.array,
  setTicketState: PropTypes.func,
  handleRemoveItem: PropTypes.func
};

export default TicketList;
