import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import DriverEditContainer from '../DriverEdit/DriverEditContainer';

export default function DriversListContainer() {
  let name = 'Driver';
  const path = '/drivers';
  const [state, attrs] = useResourceList(path, name);
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);

  const columns = [
    {
      title: 'Store',
      dataIndex: 'store_id',
      key: 'store_id',
      sorter: (a, b) => a.store_id - b.store_id,
      sortOrder: attrs.sortInfo.columnKey === 'store_id' && attrs.sortInfo.order
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code - b.code,
      sortOrder: attrs.sortInfo.columnKey === 'code' && attrs.sortInfo.order
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.first_name - b.first_name,
      sortOrder: attrs.sortInfo.columnKey === 'first_name' && attrs.sortInfo.order
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => a.last_name - b.last_name,
      sortOrder: attrs.sortInfo.columnKey === 'last_name' && attrs.sortInfo.order
    },
    {
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList();
  };

  const data = state.data
    ? state.data.map((item) => ({
        ...item,
        actions: (
          <div>
            <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
              <EditOutlined />
            </a>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={attrs.removeResource.bind(this, item.id)}
            >
              <a href="#!">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </div>
        )
      }))
    : [];

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
      >
        <DriverEditContainer handleCancel={toggleModal} id={resourceId} onSave={onModalSave} />
      </ResourceModal>

      <ResourceList
        {...attrs}
        data={data}
        extra={[
          <Button key="1" type="primary" onClick={toggleModal}>
            Create {name}
          </Button>
        ]}
      />
    </React.Fragment>
  );
}
