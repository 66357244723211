import { combineReducers } from 'redux';
import dispatches from './dispatches/reducer';
import trips from './trips/reducer';
import tickets from './tickets/reducer';
import sites from './sites/reducer';
import site_customers from './site_customers/reducer';
import locations from './locations/reducer';
import drivers from './drivers/reducer';
import trucks from './trucks/reducer';

export default combineReducers({
  dispatches,
  trips,
  tickets,
  sites,
  site_customers,
  locations,
  drivers,
  trucks
});
