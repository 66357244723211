import React from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Input, InputNumber } from 'antd';
import styles from './TruckCompartmentForm.module.css';

const { TextArea } = Input;

function TruckCompartmentForm({ form, data = {}, truckId }) {
  const { number = null, capacity_in_gallons = null } = data;
  const { getFieldDecorator } = form;

  return (
    <Form className={styles.form} layout="vertical">
      <Row>
        <Form.Item label="Number">
          {getFieldDecorator('number', {
            initialValue: number || '',
            rules: [{ required: true }]
          })(<Input placeholder="number" />)}
        </Form.Item>

        <Form.Item label="Capacity in Gallons">
          {getFieldDecorator('capacity_in_gallons', {
            initialValue: capacity_in_gallons || '',
            rules: [{ required: true }]
          })(<Input placeholder="Capacity in Gallons" />)}
        </Form.Item>

        {getFieldDecorator('truck_id', {
          initialValue: truckId || '',
          rules: [{ required: true }]
        })(<Input type="hidden" />)}
      </Row>
    </Form>
  );
}

TruckCompartmentForm.propTypes = {
  form: PropTypes.object,
  data: PropTypes.object
};

const WrappedTruckCompartmentForm = Form.create({ name: 'TruckCompartmentForm' })(
  TruckCompartmentForm
);
export default WrappedTruckCompartmentForm;
