import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Body from '../../components/Body/Body';
import Content from '../../components/Content/Content';
import { PageHeader, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { handleError } from '../../utils/handleError';
import TruckCompartmentList from '../TruckCompartmentList/TruckCompartmentListContainer';

import styles from './TruckShowContainer.module.css';

export default function TruckShowContainer(props) {
  const truckId = props.match.params.truck_id;
  const [truck, setTruck] = useState({});
  const [loading, setLoading] = useState(false);
  const { id, code, description } = truck;

  useEffect(() => {
    fetchTruck(truckId);
  }, [id]);

  const fetchTruck = (id) => {
    setLoading(true);
    axios
      .get(`/trucks/${id}`)
      .then((res) => {
        setTruck(res.data);
        setLoading(false);
      })
      .catch((err) => handleError(err));
  };

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/trucks">Trucks</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Truck {code}</Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <React.Fragment>
      <Content className={styles.header} breadcrumb={breadcrumb}>
        <PageHeader title={`Truck ${code}`} />
        <Body>{description}</Body>
      </Content>
      <TruckCompartmentList className={styles.compartments} truckId={truckId} />
    </React.Fragment>
  );
}
