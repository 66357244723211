import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loader from '../../components/Loader/Loader';
import useResourceEdit from '../../hooks/useResourceEdit';
import ResourceFormActions from '../../components/ResourceFormActions/ResourceFormActions';
import EquipmentForm from '../../components/forms/EquipmentForm/EquipmentForm';

export default function EquipmentEditContainer({ id, handleCancel = () => {}, onSave = () => {} }) {
  const [state, attrs] = useResourceEdit({ path: '/equipments', name: 'Equipment', id, onSave });
  const { loading, data } = state;
  const { handleSubmit, resetFields, setForm } = attrs;
  const [stores, setStores] = useState();
  const [manufacturers, setManufacturers] = useState();

  // Run First Load
  useEffect(() => {
    axios.get(`/stores`).then((res) => setStores(res.data.records));
    axios.get(`/manufacturers?pageSize=0`).then((res) => setManufacturers(res.data.records));
    return () => {
      setStores();
      setManufacturers();
    };
  }, []);

  if (loading) {
    return <Loader display={true} />;
  }

  return (
    <React.Fragment>
      <EquipmentForm
        data={data}
        stores={stores}
        manufacturers={manufacturers}
        ref={(ref) => setForm(ref)}
      />
      <ResourceFormActions
        id={id}
        handleCancel={() => {
          console.log('canceling');
          handleCancel();
          resetFields();
        }}
        handleSubmit={handleSubmit}
      />
    </React.Fragment>
  );
}
