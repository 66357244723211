import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import L, { divIcon } from 'leaflet';
import { Typography } from 'antd';

import styles from './Marker.module.css';
import '@fortawesome/fontawesome-free/css/all.css';

const { Text } = Typography;

function CustomMarker({ backgroundColor = '#76bff7', icon, text, textBackgroundColor = '#000' }) {
  return (
    <React.Fragment>
      <div style={{ backgroundColor }} className={styles.markerPin}></div>
      {icon && <i className={[styles.icon, 'fa', icon].join(' ')}></i>}
      {text && (
        <div style={{ color: textBackgroundColor }} className={styles.text}>
          {text}
        </div>
      )}
    </React.Fragment>
  );
}

export default ({ backgroundColor = '#76bff7', icon, text, textBackgroundColor }) =>
  divIcon({
    className: 'custom-div-icon',
    html: renderToStaticMarkup(
      <CustomMarker
        backgroundColor={backgroundColor}
        icon={icon}
        text={text}
        textBackgroundColor={textBackgroundColor}
      />
    ),
    iconSize: [30, 42],
    iconAnchor: [15, 42],
    popupAnchor: [0, -22]
  });
