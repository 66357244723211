import React from 'react';
import Body from '../../components/Body/Body';
import Content from '../../components/Content/Content';
import { PageHeader } from 'antd';

export default function DashboardContainer() {
  return (
    <React.Fragment>
      <Content>
        <PageHeader title="Dashboard" />
        <Body>Dashboard will go here.</Body>
      </Content>
    </React.Fragment>
  );
}
