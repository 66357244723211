import React, { useState, useEffect } from 'react';
import { Input, Button, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined, ScheduleOutlined } from '@ant-design/icons';
import moment from 'moment';
import queryString from 'query-string';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import SiteListMap from './SiteListMap';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import SiteEditContainer from '../SiteEditModal/SiteEditContainer';
import TicketCreateContainer from '../TicketCreateModal/TicketCreateContainer';

const { RangePicker } = DatePicker;

export default function SiteListContainer(props) {
  const queryParams = queryString.parse(props.location.search);
  const { date } = queryParams;

  let name = 'Delivery Site';
  const path = '/sites';
  const [state, attrs] = useResourceList(
    path,
    name,
    {},
    { date, columnKey: 'account_number', order: 'descend' },
    { pageSize: 25 }
  );
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);
  const [displayScheduleDeliveryModal, setDisplayScheduleDeliveryModal] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [products, setProducts] = useState([]);

  // Products
  useEffect(() => {
    async function fetchProducts() {
      const res = await attrs.fetch(`/products`, {
        pageSize: 0,
        columnKey: 'id',
        order: 'ascend'
      });
      setProducts(res.data.records);
    }

    fetchProducts();
  }, []);

  const columns = [
    {
      title: 'ID',
      render: (record) => {
        const index = data.findIndex((item) => item.id === record.id) + 1;
        const { delivery_detail } = record;
        if (delivery_detail && delivery_detail.latitude) return index;
        return (
          <span
            style={{
              color: 'white',
              backgroundColor: 'red',
              padding: '5px 7px 5px 7px',
              marginLeft: -7
            }}
          >
            {index}
          </span>
        );
      }
    },
    {
      title: 'Customer',
      dataIndex: 'account_number',
      key: 'account_number',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Customer Number"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.account_number || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: (a, b) => a.account_number - b.account_number,
      sortOrder: attrs.sortInfo.columnKey === 'account_number' && attrs.sortInfo.order
    },

    {
      title: 'Tank',
      dataIndex: 'tank_id',
      key: 'tank_id',
      sorter: (a, b) => a.tank_id - b.tank_id,
      sortOrder: attrs.sortInfo.columnKey === 'tank_id' && attrs.sortInfo.order
    },

    {
      title: 'Sales Type',
      dataIndex: 'sales_type',
      key: 'sales_type',
      filters: [
        { text: 'WC', value: 'WC' },
        { text: 'AT', value: 'AT' }
      ],
      filteredValue: attrs.filters.sales_type || null
    },

    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product_code',
      filters: products.map((product) => ({ text: product.title, value: product.code })),
      filteredValue: attrs.filters.product_code || null,
      render: (data) => data && data.title
    },

    {
      title: 'Last Name',
      dataIndex: 'customer.last_name',
      key: 'last_name',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Last Name"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.last_name || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      }
    },

    {
      title: 'First Name',
      dataIndex: 'customer.first_name',
      key: 'first_name',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="First Name"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.first_name || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      }
    },

    {
      title: 'Address Line 1',
      dataIndex: 'delivery_detail.address_line_1',
      key: 'delivery_detail.address_line_1',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Address Line 1"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters['delivery_detail.address_line_1'] || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: (a, b) => a.delivery_detail.address_line_1 - b.delivery_detail.address_line_1,
      sortOrder:
        attrs.sortInfo.columnKey === 'delivery_detail.address_line_1' && attrs.sortInfo.order
    },

    {
      title: 'Address Line 2',
      dataIndex: 'delivery_detail.address_line_2',
      key: 'delivery_detail.address_line_2',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Address Line 2"
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(selectedKeys, confirm)}
            />

            <Button
              type="link"
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters['delivery_detail.address_line_2'] || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: (a, b) => a.delivery_detail.address_line_2 - b.delivery_detail.address_line_2,
      sortOrder:
        attrs.sortInfo.columnKey === 'delivery_detail.address_line_2' && attrs.sortInfo.order
    },
    {
      visible: false,
      title: 'Opened',
      dataIndex: 'opened_at',
      key: 'opened_at',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <RangePicker
              onChange={(dateArray) => {
                // dateArray = [moment, moment]
                // We need to convert to a value that is ready for our api
                setSelectedKeys(
                  dateArray
                    ? [
                        moment(dateArray[0]).format('YYYY-MM-DD'),
                        moment(dateArray[1]).format('YYYY-MM-DD')
                      ]
                    : []
                );
              }}
              style={{ marginBottom: 8, display: 'block' }}
            />

            <Button
              type="link"
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.opened_at || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: (a, b) => a.opened_at - b.opened_at,
      sortOrder: attrs.sortInfo.columnKey === 'opened_at' && attrs.sortInfo.order,
      render: (data) => data && moment.unix(data).format('MM-DD-YYYY')
    },

    {
      visible: false,
      title: 'Last Delivery',
      dataIndex: 'last_delivery_at',
      key: 'last_delivery_at',

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <RangePicker
              onChange={(dateArray) => {
                // dateArray = [moment, moment]
                // We need to convert to a value that is ready for our api
                setSelectedKeys(
                  dateArray
                    ? [
                        moment(dateArray[0]).format('YYYY-MM-DD'),
                        moment(dateArray[1]).format('YYYY-MM-DD')
                      ]
                    : []
                );
              }}
              style={{ marginBottom: 8, display: 'block' }}
            />

            {/* <Input
              ref={(node) => {
                // this.searchInput = node;
              }}
              placeholder={`Search`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              // onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            /> */}

            <Button
              type="link"
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Ok
            </Button>
            <Button
              type="link"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: attrs.filters.last_delivery_at || null,
      onFilter: (value, record) => {
        // We return true here to return all the records returned from server
        // This function is used to do client-side filtering as well so those options could go here
        return true;
      },
      sorter: (a, b) => a.last_delivery_at - b.last_delivery_at,
      sortOrder: attrs.sortInfo.columnKey === 'last_delivery_at' && attrs.sortInfo.order,
      render: (data) => data && moment.unix(data).format('MM-DD-YYYY')
    },

    {
      title: 'Route',
      dataIndex: 'route',
      key: 'route',
      // TODO get these from the db and populate
      filters: routes
        ? routes.map((route) => ({ text: route.description, value: route.name }))
        : [{ text: 'Temp', value: 'Value' }],
      filteredValue: attrs.filters.route || null
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      filters: [
        {
          text: 'Bedford',
          value: '1'
        },
        {
          text: 'New Cumberland',
          value: '2'
        }
      ],
      filteredValue: attrs.filters.region || null
    },

    {
      visible: false,
      title: 'Manually Geocoded',
      dataIndex: 'delivery_detail.manually_geocoded',
      key: 'delivery_detail.manually_geocoded',
      filters: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      filteredValue: attrs.filters['delivery_detail.manually_geocoded'] || null,
      render: (data) => (data ? 'Yes' : 'No')
    },

    {
      visible: false,
      title: 'Company Asset',
      dataIndex: 'company_asset',
      key: 'company_asset',
      filters: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      filteredValue: attrs.filters.company_asset || null,
      render: (value) => (value === true ? 'Yes' : 'No')
    },

    {
      visible: false,
      title: 'Company Asset ID',
      dataIndex: 'company_asset_id',
      key: 'company_asset_id',
      render: (value) => value
    },

    {
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  // Load Routes
  useEffect(() => {
    async function fetchRoutes() {
      const res = await attrs.fetch(`/routes`, {
        pageSize: 0,
        columnKey: 'name',
        order: 'ascend',
        store_id: attrs.filters.region || null
      });
      setRoutes(res.data.records);
    }
    fetchRoutes();
  }, [attrs.filters.region]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const toggleScheduleDeliveryModal = (e, site = null) => {
    e && e.preventDefault();
    setResourceId(site);
    setDisplayScheduleDeliveryModal((modal) => !modal);
  };

  const onScheduleDeliveryModalSave = () => {
    toggleScheduleDeliveryModal();
    attrs.fetchResourceList({
      pagination: attrs.pagination,
      sorter: attrs.sortInfo,
      filters: attrs.filters
    });
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList({
      pagination: attrs.pagination,
      sorter: attrs.sortInfo,
      filters: attrs.filters
    });
  };

  const handleSearch = (selectedKeys, confirm) => {
    // attrs.setFilters({ ...attrs.filters, [dataIndex]: selectedKeys });
    // console.log(selectedKeys, dataIndex);
    // console.log('What is attrs?', attrs.filters);
    confirm();

    // this.setState({
    //   searchText: selectedKeys[0],
    //   searchedColumn: dataIndex,
    // });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const data = state.data
    ? state.data.map((item) => ({
        ...item,
        actions: (
          <div>
            {item.delivery_detail && (
              <div>
                <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
                  <EditOutlined />
                </a>
                <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
                <a href="#!" onClick={toggleScheduleDeliveryModal.bind(this, null, item)}>
                  <ScheduleOutlined />
                </a>
              </div>
            )}
          </div>
        )
      }))
    : [];

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
        width="95%"
      >
        <SiteEditContainer handleCancel={toggleModal} id={resourceId} onSave={onModalSave} />
      </ResourceModal>

      <ResourceModal
        title={`Schedule Delivery`}
        visible={displayScheduleDeliveryModal}
        toggle={toggleScheduleDeliveryModal}
        width="95%"
      >
        <TicketCreateContainer
          handleCancel={toggleScheduleDeliveryModal}
          site={resourceId}
          onSave={onScheduleDeliveryModalSave}
        />
      </ResourceModal>
      <SiteListMap sites={data} />
      <ResourceList columns={attrs.columns} data={data} {...attrs} />
    </React.Fragment>
  );
}
