import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Divider, message } from 'antd';
import Loader from '../../components/Loader/Loader';
import useResourceEdit from '../../hooks/useResourceEdit';
import ResourceFormActions from '../../components/ResourceFormActions/ResourceFormActions';
import PurchaseOrderForm from '../../components/forms/PurchaseOrderForm/PurchaseOrderForm';

export default function PurchaseOrderEditContainer({
  id,
  handleCancel = () => {},
  onSave = () => {}
}) {
  const [state, attrs] = useResourceEdit({
    path: '/purchase_orders',
    name: 'purchase order',
    id,
    onSave
  });
  const { loading, data } = state;
  const { handleSubmit, setForm } = attrs;
  const [profitCenters, setProfitCenters] = useState();
  const [stores, setStores] = useState();
  const [purchaseOrderStatuses, setPurchaseOrderStatuses] = useState();

  // Run First Load
  useEffect(() => {
    axios.get(`/profit_centers`).then((res) => setProfitCenters(res.data.records));
    axios.get(`/stores`).then((res) => setStores(res.data.records));
    axios
      .get(`/purchase_orders/statuses?columnKey=id&order=ascend`)
      .then((res) => setPurchaseOrderStatuses(res.data.records));
    return () => {
      setProfitCenters();
    };
  }, []);

  if (loading) {
    return <Loader display={true} />;
  }

  return (
    <React.Fragment>
      <PurchaseOrderForm
        data={data}
        profitCenters={profitCenters}
        stores={stores}
        purchaseOrderStatuses={purchaseOrderStatuses}
        ref={(ref) => setForm(ref)}
      />
      <ResourceFormActions id={id} handleCancel={handleCancel} handleSubmit={handleSubmit} />
    </React.Fragment>
  );
}
