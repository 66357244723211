import React from 'react';
import { Card } from 'antd';

import style from './Stat.css';
const { Meta } = Card;

function Stat(props) {
  const { title, value } = props;
  return (
    <Card className={style.stat} size="small">
      <Meta
        className={style.meta}
        style={{ textAlign: 'right' }}
        title={value}
        description={title}
      />
    </Card>
  );
}

Stat.propTypes = {};

export default Stat;
