import { combineReducers } from 'redux';
import auth from '../reducers/auth/reducer';
import ui from '../reducers/ui/reducer';
import entities from '../reducers/entities';
import pages from '../reducers/pages';

const rootReducer = combineReducers({
  auth,
  ui,
  entities,
  pages
});

export default rootReducer;
