import React from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Input, Select } from 'antd';
import styles from './TruckForm.module.css';

const { Option } = Select;
const { TextArea } = Input;

function TruckForm({ form, data = {}, stores = [] }) {
  const { store_id = null, code = null, description = null, active = null } = data;
  const { getFieldDecorator } = form;

  return (
    <Form className={styles.form} layout="vertical">
      <Row>
        <Form.Item label="Code">
          {getFieldDecorator('code', {
            initialValue: code || '',
            rules: [{ required: true }]
          })(<Input placeholder="code" />)}
        </Form.Item>

        <Form.Item label="Description">
          {getFieldDecorator('description', {
            initialValue: description || ''
          })(<TextArea rows={3} placeholder="Description" />)}
        </Form.Item>

        <Form.Item label="Active">
          {getFieldDecorator('active', {
            initialValue: active !== null ? active.toString() : 'true',
            rules: [{ required: true }]
          })(
            <Select placeholder="Active">
              <Option value="true">True</Option>
              <Option value="false">False</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Store">
          {getFieldDecorator('store_id', {
            initialValue: store_id || '',
            rules: [{ required: true }]
          })(
            <Select placeholder="Select Store">
              {stores.map((s) => (
                <Option key={s.id} value={s.id}>
                  {s.id} - {s.city}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Row>
    </Form>
  );
}

TruckForm.propTypes = {
  form: PropTypes.object,
  data: PropTypes.object
};

const WrappedTruckForm = Form.create({ name: 'TruckForm' })(TruckForm);
export default WrappedTruckForm;
