import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Form, Input, Select, DatePicker } from 'antd';
import styles from './EquipmentLeaseForm.module.css';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

function EquipmentLeaseForm({
  form,
  data = {},
  equipments = [],
  equipmentLeaseTypes = [],
  cycles = []
}) {
  const {
    id = null,
    equipment_id = null,
    factor_customer_id = null,
    factor_tank_id = null,
    start_at = null,
    end_at = null,
    replacement_cost = null,
    equipment_lease_type_id = null,
    rent_cycle_id = null,
    rent_cycle_interval = null,
    rent_price = null,
    invoiced_last_at = null,
    invoiced_through_at = null,
    minimum_gallons_for_rent_credit = null,
    minimum_gallons_rent_credit_price = null,
    rent_accrued = null,
    is_signed = null
  } = data;

  const { getFieldDecorator } = form;
  const dateFormat = 'YYYY-MM-DD';

  const isEdit = id > 0 ? true : false;
  const isProcessed = invoiced_last_at === null || invoiced_last_at === '' ? false : true;

  let stage = 1;
  let purchase_cost = 0;

  // Get purchase cost of selected equipment id
  if (form.getFieldValue('equipment_id') && equipments) {
    const selectedEquipment =
      equipments[equipments.findIndex((e) => e.id == form.getFieldValue('equipment_id'))];
    purchase_cost = selectedEquipment ? selectedEquipment.purchase_cost : 0;
  }

  // Use stages to show pieces of the form as it's filled out
  if (
    (form.getFieldValue('equipment_id') || equipment_id) &&
    (form.getFieldValue('factor_customer_id') || factor_customer_id) &&
    (form.getFieldValue('factor_tank_id') || factor_tank_id) &&
    (form.getFieldValue('equipment_lease_type_id') || equipment_lease_type_id)
  ) {
    stage = 4;
  } else if (
    (form.getFieldValue('equipment_id') || equipment_id) &&
    (form.getFieldValue('factor_customer_id') || factor_customer_id) &&
    (form.getFieldValue('factor_tank_id') || factor_tank_id)
  ) {
    stage = 3;
  } else if (form.getFieldValue('equipment_id') || equipment_id) {
    stage = 2;
  } else {
    stage = 1;
  }

  return (
    <Form className={styles.form} layout="vertical">
      <Row>
        <Col span={10}>
          <Form.Item label="Select Equipment">
            {getFieldDecorator('equipment_id', {
              initialValue: equipment_id || '',
              rules: [{ required: true }]
            })(
              <Select
                showSearch
                placeholder="Select equipment"
                optionFilterProp="children"
                disabled={isEdit}
                filterOption={(input, option) => {
                  // console.log(input, option) &&
                  const search = input ? input.toLowerCase() : '';
                  return (
                    option.props.children &&
                    option.props.children.toLowerCase().indexOf(search) >= 0
                  );
                }}
              >
                {equipments.map((equipment) => (
                  <Option value={equipment.id}>{equipment.model}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          {(stage == 2 || stage == 3 || stage == 4) && (
            <>
              <Form.Item label="Replacement Cost">
                {getFieldDecorator('replacement_cost', {
                  initialValue: replacement_cost || Number(purchase_cost).toFixed(2),
                  rules: [{ required: true }]
                })(<Input type="number" placeholder="1500.00" />)}
              </Form.Item>
              <Form.Item label="Customer Number">
                {getFieldDecorator('factor_customer_id', {
                  initialValue: factor_customer_id || '',
                  rules: [{ required: true }]
                })(<Input disabled={isEdit} type="number" placeholder="25632555" />)}
              </Form.Item>

              <Form.Item label="Customer Tank Number">
                {getFieldDecorator('factor_tank_id', {
                  initialValue: factor_tank_id || '',
                  rules: [{ required: true }]
                })(<Input disabled={isEdit} type="number" placeholder="1" />)}
              </Form.Item>
            </>
          )}
          {/* <Form.Item label="Description">
            {getFieldDecorator('description', {
              initialValue: description || ''
            })(<TextArea rows={3} placeholder="420lb Propane Cylinder Tank" />)}
          </Form.Item> */}

          {(stage == 3 || stage == 4) && (
            <>
              <Form.Item label="Lease Type">
                {getFieldDecorator('equipment_lease_type_id', {
                  initialValue: equipment_lease_type_id || '',
                  rules: [{ required: true, message: 'Lease Type is Required' }]
                })(
                  <Select disabled={isProcessed} placeholder="Select Least Type">
                    {equipmentLeaseTypes.map((e) => (
                      <Option key={e.id} value={e.id}>
                        {e.title}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </>
          )}

          {stage == 4 &&
            form.getFieldValue('equipment_lease_type_id') !== 3 &&
            form.getFieldValue('equipment_lease_type_id') !== 4 && (
              <>
                <Form.Item label="Rent Cycle">
                  {getFieldDecorator('rent_cycle_id', {
                    initialValue: rent_cycle_id || '',
                    rules: []
                  })(
                    <Select disabled={isProcessed} placeholder="Select Rent Cycle">
                      {cycles.map((c) => (
                        <Option key={c.id} value={c.id}>
                          {c.title}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Rent Cycle Interval">
                  {getFieldDecorator('rent_cycle_interval', {
                    initialValue: rent_cycle_interval || '',
                    rules: []
                  })(<Input disabled={isProcessed} type="number" placeholder="1" />)}
                </Form.Item>
              </>
            )}
          {stage == 4 && (
            <>
              <Form.Item label="Start On">
                {getFieldDecorator('start_at', {
                  initialValue: start_at ? moment(moment.unix(start_at), dateFormat) : null,
                  rules: [{ required: true }]
                })(
                  <DatePicker
                    disabled={isProcessed}
                    format={dateFormat}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>

              {isEdit && (
                <Form.Item label="End On">
                  {getFieldDecorator('end_at', {
                    initialValue: end_at ? moment(moment.unix(end_at), dateFormat) : null
                  })(<DatePicker format={dateFormat} style={{ width: '100%' }} />)}
                </Form.Item>
              )}
              <Form.Item label="Rent Price">
                {getFieldDecorator('rent_price', {
                  initialValue: rent_price || 0.0,
                  rules: [{ required: true }]
                })(<Input disabled={isProcessed} type="number" placeholder="365.00" />)}
              </Form.Item>
            </>
          )}
        </Col>

        <Col span={10} offset={2}>
          {stage == 4 &&
            (form.getFieldValue('equipment_lease_type_id') == 2 ||
              form.getFieldValue('equipment_lease_type_id') == 5) && (
              <>
                <Form.Item label="Minimum Gallons for Credit">
                  {getFieldDecorator('minimum_gallons_for_rent_credit', {
                    initialValue: minimum_gallons_for_rent_credit || '',
                    rules: []
                  })(<Input disabled={isProcessed} type="number" placeholder="96" />)}
                </Form.Item>

                <Form.Item label="Minimum Gallons Credit Price">
                  {getFieldDecorator('minimum_gallons_rent_credit_price', {
                    initialValue: minimum_gallons_rent_credit_price || '',
                    rules: []
                  })(<Input disabled={isProcessed} type="number" placeholder="365.00" />)}
                </Form.Item>
              </>
            )}

          {stage == 4 &&
            form.getFieldValue('equipment_lease_type_id') !== 3 &&
            form.getFieldValue('equipment_lease_type_id') !== 4 && (
              <>
                <Form.Item label="Invoiced Last">
                  {getFieldDecorator('invoiced_last_at', {
                    initialValue: invoiced_last_at
                      ? moment(moment.unix(invoiced_last_at), dateFormat)
                      : null
                  })(
                    <DatePicker disabled={isEdit} format={dateFormat} style={{ width: '100%' }} />
                  )}
                </Form.Item>

                <Form.Item label="Invoiced Through">
                  {getFieldDecorator('invoiced_through_at', {
                    initialValue: invoiced_through_at
                      ? moment(moment.unix(invoiced_through_at), dateFormat)
                      : null
                  })(<DatePicker format={dateFormat} style={{ width: '100%' }} />)}
                </Form.Item>
              </>
            )}
          {stage == 4 && (
            <>
              <Form.Item label="Signed?">
                {getFieldDecorator('is_signed', {
                  initialValue: is_signed !== null ? is_signed.toString() : '',
                  rules: [{ required: true }]
                })(
                  <Select placeholder="Signed?">
                    <Option value="true">Yes</Option>
                    <Option value="false">No</Option>
                  </Select>
                )}
              </Form.Item>
            </>
          )}
        </Col>
      </Row>
    </Form>
  );
}

EquipmentLeaseForm.propTypes = {
  form: PropTypes.object,
  data: PropTypes.object
};

const WrappedEquipmentLeaseForm = Form.create({ name: 'EquipmentLeaseForm' })(EquipmentLeaseForm);
export default WrappedEquipmentLeaseForm;
