import React from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Input, Select, DatePicker } from 'antd';
import styles from './SalesSiteCommissionReportForm.module.css';
import moment from 'moment';

const { RangePicker } = DatePicker;

function SalesSiteCommissionReportForm({ form, data = {} }) {
  const { type = 'fuel', salesman = 'vstraub', start_at = null, end_at = null } = data;
  const { getFieldDecorator } = form;
  const dateFormat = 'YYYY-MM-DD';

  const { Option } = Select;

  return (
    <Form className={styles.form} layout="vertical">
      <Row>
        <Form.Item label="Report Type">
          {getFieldDecorator('type', {
            initialValue: type !== null ? type.toString() : 'fuel',
            rules: [{ required: true }]
          })(
            <Select placeholder="Report Type">
              <Option value="fuel">Fuel Commission</Option>
              <Option value="parts">Parts Commission</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Salesman">
          {getFieldDecorator('salesman', {
            initialValue: salesman || '',
            rules: [{ required: true }]
          })(<Input placeholder="Salesman" />)}
        </Form.Item>

        <Form.Item label="Date Range">
          {getFieldDecorator('start_at', {
            initialValue: start_at ? moment(start_at) : null,
            rules: [{ required: true }]
          })(<RangePicker format={dateFormat} style={{ width: '100%' }} />)}
        </Form.Item>
      </Row>
    </Form>
  );
}

SalesSiteCommissionReportForm.propTypes = {
  drivers: PropTypes.array,
  trucks: PropTypes.array,
  dispatch: PropTypes.object
};

const WrappedSalesSiteCommissionReportForm = Form.create({ name: 'SalesSiteCommissionReportForm' })(
  SalesSiteCommissionReportForm
);
export default WrappedSalesSiteCommissionReportForm;
