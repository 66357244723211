// Define Dispatch Actions
export const SET_TRIP = 'SET_TRIP';
export const SET_DISPATCH = 'SET_DISPATCH';
export const SET_DISPATCH_ID = 'SET_DISPATCH_ID';
export const SET_TRIP_ID = 'SET_TRIP_ID';
export const SET_LOADING = 'SET_LOADING';
// export const SET_TICKETS = 'SET_TICKETS';
export const SET_ITEMS = 'SET_ITEMS';
export const SET_TOTAL_GALLONS = 'SET_TOTAL_GALLONS';
export const SET_TOTAL_TIME = 'SET_TOTAL_TIME';
export const SET_TOTAL_DISTANCE = 'SET_TOTAL_DISTANCE';
export const SET_TOTAL_TICKETS = 'SET_TOTAL_TICKETS';
export const SET_TICKET_RESULTS = 'SET_TICKET_RESULTS';
export const SET_SELECTED_TICKET_RESULTS = 'SET_SELECTED_TICKET_RESULTS';
export const ADD_SELECTED_TICKET_RESULTS = 'ADD_SELECTED_TICKET_RESULTS';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_STORES = 'SET_STORES';
export const SET_TRUCKS = 'SET_TRUCKS';
export const SET_DRIVERS = 'SET_DRIVERS';
export const SET_ROUTES = 'SET_ROUTES';
export const SET_ROUTE_SHAPE = 'SET_ROUTE_SHAPE';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_SALES_TYPES = 'SET_SALES_TYPES';
export const SET_ORIGIN_LOCATION_ID = 'SET_ORIGIN_LOCATION_ID';
export const SET_DESTINATION_LOCATION_ID = 'SET_DESTINATION_LOCATION_ID';
export const ADD_SELECTED_TICKET_RESULTS_TO_TICKETS = 'ADD_SELECTED_TICKET_RESULTS_TO_TICKETS';
// export const REMOVE_TICKET = 'REMOVE_TICKET';
export const REMOVE_ITEM = 'REMOVE_ITEM';
// export const ADD_TICKET = 'ADD_TICKET';
export const ADD_ITEM = 'ADD_ITEM';

export const initialState = {
  dispatchId: null,
  tripId: null,
  loading: false,
  dispatch: {},
  trip: {},
  items: [],
  totalGallons: 0,
  totalGallonsByProduct: {},
  totalTime: 0,
  totalDistance: 0,
  routeShape: [],
  totalTickets: 0,
  ticketResults: [],
  selectedTicketResults: [],
  locations: [],
  routes: [],
  products: [],
  stores: [],
  trucks: [],
  drivers: [],
  salesTypes: ['AT', 'WC'],
  originLocationId: null,
  destinationLocationId: null
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_DISPATCH:
      return { ...state, dispatch: payload };
    case SET_TRIP:
      return setTrip(payload, state);
    case ADD_SELECTED_TICKET_RESULTS_TO_TICKETS:
      return addSelectedTicketResultsToTickets(state);
    case REMOVE_ITEM: {
      const items = state.items.filter((i) => i.id !== payload);

      const ticketItems = items.filter((i) => i.ticket);
      const totalTickets = ticketItems.length;
      const totalGallons = ticketItems
        .reduce((sum, i) => i.ticket.delivery_gallons + sum, 0)
        .toFixed(2);
      const totalGallonsByProduct = calculateTotalGallonsByProduct(items);

      return { ...state, totalTickets, totalGallons, totalGallonsByProduct, items };
    }
    case ADD_ITEM: {
      const { id, type } = payload;
      // We need to model an item
      const newItem = {
        id: Date.now() + '_new', // Need a unique id
        type,
        reset_totals: false
      };

      if (type === 'ticket') {
        newItem.ticket = state.ticketResults.find((ticket) => ticket.id === id);
      } else {
        newItem.location = state.locations.find((location) => location.id === id);
      }

      // Remove Ticket from Results
      const ticketResults = state.ticketResults.filter((t) => t.id !== id);
      const selectedTicketResults = state.selectedTicketResults.filter((t) => t.id !== id);

      // Add Item
      const items = [...state.items, newItem];

      // Recalculate Totalizers
      const ticketItems = items.filter((i) => i.ticket);
      const totalTickets = ticketItems.length;
      const totalGallons = ticketItems
        .reduce((sum, i) => i.ticket.delivery_gallons + sum, 0)
        .toFixed(2);
      const totalGallonsByProduct = calculateTotalGallonsByProduct(items);

      return {
        ...state,
        ticketResults,
        selectedTicketResults,
        items,
        totalTickets,
        totalGallons,
        totalGallonsByProduct
      };
    }
    case SET_DISPATCH_ID:
      return { ...state, dispatchId: payload };
    case SET_TRIP_ID:
      return { ...state, tripId: payload };
    case SET_LOADING:
      return { ...state, loading: payload };
    case SET_ITEMS:
      return { ...state, items: payload };
    case SET_TOTAL_GALLONS:
      return { ...state, totalGallons: payload };
    case SET_TOTAL_TIME:
      return { ...state, totalTime: payload };
    case SET_TOTAL_DISTANCE:
      return { ...state, totalDistance: payload };
    case SET_TOTAL_TICKETS:
      return { ...state, totalTickets: payload };
    case SET_TICKET_RESULTS:
      return { ...state, ticketResults: payload };
    case SET_SELECTED_TICKET_RESULTS:
      if (payload === state.selectedTicketResults) return state;
      return { ...state, selectedTicketResults: payload };
    case ADD_SELECTED_TICKET_RESULTS:
      if (payload === state.selectedTicketResults) return state;
      return { ...state, selectedTicketResults: [...state.selectedTicketResults, ...payload] };
    case SET_LOCATIONS:
      return { ...state, locations: payload };
    case SET_STORES:
      return { ...state, stores: payload };
    case SET_TRUCKS:
      return { ...state, trucks: payload };
    case SET_DRIVERS:
      return { ...state, drivers: payload };
    case SET_ROUTES:
      return { ...state, routes: payload };
    case SET_ROUTE_SHAPE:
      return { ...state, routeShape: payload };
    case SET_PRODUCTS:
      return { ...state, products: payload };
    case SET_SALES_TYPES:
      return { ...state, salesTypes: payload };
    case SET_ORIGIN_LOCATION_ID:
      return { ...state, originLocationId: payload };
    case SET_DESTINATION_LOCATION_ID:
      return { ...state, destinationLocationId: payload };
    default:
      return state;
  }
};

const setTrip = (trip, state) => {
  // const totalGallonsByProduct = calculateTotalGallonsByProduct(trip.tickets);
  return {
    ...state,
    trip,
    // totalGallonsByProduct,
    items: trip.items,
    tickets: trip.tickets,
    totalGallons: trip.total_gallons,
    totalTime: trip.total_time_in_seconds,
    totalDistance: trip.total_distance_in_miles,
    totalTickets: trip.total_tickets
  };
};

export const calculateTotalGallonsByProduct = (ticketItems = []) => {
  const totalGallonsByProduct = {};
  for (const ticketItem of ticketItems) {
    const { ticket } = ticketItem;
    if (ticket && ticket.product.product_components.length > 0) {
      // We have a blended product
      const deliveryGallons = ticket.delivery_gallons;
      for (const product of ticket.product.product_components) {
        // We need to take the total delivery gallons and figure and split between the blend
        const productDeliveryGallons = product.quantity * ticket.delivery_gallons;
        const productId = product.component_product_id;
        totalGallonsByProduct[productId] = totalGallonsByProduct[productId]
          ? totalGallonsByProduct[productId] + productDeliveryGallons
          : productDeliveryGallons;
      }
    } else if (ticket) {
      // We have a single product
      totalGallonsByProduct[ticket.product.id] = totalGallonsByProduct[ticket.product.id]
        ? totalGallonsByProduct[ticket.product.id] + ticket.delivery_gallons
        : ticket.delivery_gallons;
    }
  }
  return totalGallonsByProduct;
};

// TODO CONVERT TO ITEMS
const addSelectedTicketResultsToTickets = (state) => {
  const { selectedTicketResults } = state;

  // Reduce tickets to just an array of ids
  const selectedTicketIds = selectedTicketResults.reduce((ids, t) => [...ids, t.id], []);

  // Remove the selected tickets from ticketResults
  const remainingTicketResults = state.ticketResults.filter(
    (t) => !selectedTicketIds.includes(t.id)
  );

  const newItems = selectedTicketResults.map(({ id }) => {
    const newItem = {
      id: Date.now() + '_new', // Need a unique id
      type: 'ticket',
      reset_totals: false
    };

    newItem.ticket = state.ticketResults.find((ticket) => ticket.id === id);
    return newItem;
  });

  const updatedItems = [...state.items, ...newItems];

  const ticketItems = updatedItems.filter((i) => i.ticket);
  const totalTickets = ticketItems.length;
  const totalGallons = ticketItems
    .reduce((sum, i) => i.ticket.delivery_gallons + sum, 0)
    .toFixed(2);
  const totalGallonsByProduct = calculateTotalGallonsByProduct(updatedItems);

  // const totalTickets = updatedTickets.length;
  // const totalGallons = updatedTickets.reduce((sum, t) => t.delivery_gallons + sum, 0).toFixed(2);

  return {
    ...state,
    items: updatedItems,
    selectedTicketResults: [],
    ticketResults: remainingTicketResults,
    totalTickets,
    totalGallons,
    totalGallonsByProduct
  };
};
