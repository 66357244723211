import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Icon, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import ResourceModal from '../../components/ResourceModal/ResourceModal';

export default function FuelDiscountListContainer() {
  let name = 'Fuel Discount Redemption';
  const path = '/fuel_discount_redemptions';
  const [state, attrs] = useResourceList(path, name);

  const data = state.data;

  useEffect(() => {
    const columns = [
      {
        title: 'Customer Id',
        dataIndex: 'customer_id',
        key: 'customer_id',
        sorter: (a, b) => a.customer_id - b.customer_id,
        sortOrder: attrs.sortInfo.columnKey === 'customer_id' && attrs.sortInfo.order
      },
      {
        title: 'Discount Code',
        dataIndex: 'fuel_discount.code',
        key: 'fuel_discount_id',
        sorter: (a, b) => a.code - b.code,
        sortOrder: attrs.sortInfo.columnKey === 'fuel_discount_id' && attrs.sortInfo.order
      },
      {
        title: 'Account Number',
        dataIndex: 'account_number',
        key: 'account_number',
        sorter: (a, b) => a.account_number.localeCompare(b.account_number),
        sortOrder: attrs.sortInfo.columnKey === 'account_number' && attrs.sortInfo.order
      },
      {
        title: 'Factor Site Id',
        dataIndex: 'factor_site_id',
        key: 'factor_site_id',
        sorter: (a, b) => a.factor_site_id - b.factor_site_id,
        sortOrder: attrs.sortInfo.columnKey === 'factor_site_id' && attrs.sortInfo.order
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: (a, b) => a.created_at - b.created_at,
        sortOrder: attrs.sortInfo.columnKey === 'created_at' && attrs.sortInfo.order,
        render: (data) => moment.unix(data).format('YYYY-MM-DD')
      },
      {
        width: 150,
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions'
      }
    ];
    attrs.setColumns(columns);
  }, data);

  return (
    <React.Fragment>
      <ResourceList data={data} {...attrs} />
    </React.Fragment>
  );
}
