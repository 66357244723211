import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar, Tag, Popover, Menu, Dropdown, Badge } from 'antd';
import { ProfileOutlined, MoreOutlined } from '@ant-design/icons';
import { IoFlag } from 'react-icons/io5';
import Ticket from '../../../../../components/Ticket/Ticket';
import styles from './TicketListItem.module.css';
import Item from 'antd/lib/list/Item';

function TicketListItem(props) {
  const { data, index, handleRemoveItem = () => {}, totalGallonsByProduct = {}, products } = props;
  // const { id, ticket_number, delivery_gallons, product = {}, site = {} } = data;
  // const { customer, delivery_detail = {} } = site;
  // const { address_line_1, address_line_2 } = delivery_detail;
  // const { code } = product;

  const productColor = (code) => {
    if (code === '10') {
      return 'red';
    } else if (code === '08') {
      return 'magenta';
    } else {
      return null;
    }
  };

  const handleGallonsByProduct = (totalGallonsByProduct) => {
    let tags = [];
    for (let [key, value] of Object.entries(totalGallonsByProduct)) {
      const product = products.find((product) => product.id == key);
      tags.push(
        <Tag key={key} color={`#${product.hex_code}`}>
          {product.title}: {value && value.toFixed(2)}
        </Tag>
      );
    }
    return tags;
  };

  const menu = (id, site, ticket) => (
    <Menu>
      {site && (
        <Menu.Item key="0">
          <Link to={`/sites/${site.id}/edit`} target="_blank">
            Edit Location
          </Link>
        </Menu.Item>
      )}

      {ticket && (
        <Menu.Item key="1">
          <Link to={`/tickets/${ticket.id}/edit`} target="_blank">
            Edit Ticket
          </Link>
        </Menu.Item>
      )}

      <Menu.Item key="2">
        <a href="#!" onClick={handleRemoveItem.bind(this, id)}>
          Remove {ticket ? 'Ticket' : 'Location'}
        </a>
      </Menu.Item>
    </Menu>
  );

  const ticketItem = (data) => {
    const { id, ticket } = data;
    const { ticket_number, delivery_gallons, product = {}, site = {} } = ticket;
    const { customer, delivery_detail = {} } = site;
    const { address_line_1, address_line_2, latitude, longitude } = delivery_detail;
    const { code } = product;
    return (
      <div>
        <Avatar className={`${styles.avatar} sortHandle`} size={50}>
          {index + 1}
        </Avatar>
        <div className={styles.right}>
          {(!latitude || !longitude) && (
            <Popover content={<p>GPS coordinates are missing.</p>} title="Error Details">
              <Tag color="red">Error</Tag>
            </Popover>
          )}

          {data.ticket && data.ticket.notes && (
            <IoFlag style={{ marginBottom: -1, paddingRight: 2 }} color="red" />
          )}

          <Popover
            placement="topRight"
            content={<Ticket data={data.ticket} />}
            title={`Ticket Details: ${ticket_number}`}
            trigger="click"
          >
            <ProfileOutlined />
          </Popover>

          <Dropdown overlay={menu.bind(this, id, site, ticket)} trigger={['click']}>
            <a href="#!">
              <MoreOutlined />
            </a>
          </Dropdown>
          <br />
          {delivery_gallons}
          <br />
          <Tag style={{ marginRight: 0 }} color={productColor(code)}>
            {code}{' '}
          </Tag>
        </div>
        <div className={styles.right}></div>

        <p>
          {customer && customer.first_name} {customer && customer.last_name}
          <br />
          {address_line_1}
          <br />
          {address_line_2}
        </p>
      </div>
    );
  };

  const locationItem = (data) => {
    const { id, location } = data;
    return (
      <div>
        <Avatar className={`${styles.location} sortHandle`} size={50}>
          <i className="fa fa-building" />
        </Avatar>
        <div className={styles.right}>
          <Dropdown overlay={menu.bind(this, id)} trigger={['click']}>
            <a href="#!">
              <MoreOutlined />
            </a>
          </Dropdown>
        </div>
        <p>
          <b>{location.title}</b>
          <br />
          {handleGallonsByProduct(totalGallonsByProduct)}
        </p>
      </div>
    );
  };

  return (
    // use a Popover for more details
    <div className={`${styles.ticketListItem} TicketListItem`}>
      {data.type === 'ticket' && ticketItem(data)}
      {data.type === 'location' && locationItem(data)}
    </div>
  );
}

TicketListItem.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  handleRemoveItem: PropTypes.func
};

export default TicketListItem;
