import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { message, Row, Col, Form, Input, Button } from 'antd';
import DivIcon from '../../components/DivIcon/DivIcon';
import Body from '../../components/Body/Body';
import Content from '../../components/Content/Content';

import { Marker, Popup, Tooltip } from 'react-leaflet';
import CustomSearchHandler from './CustomSearchHandler';
import { googleHybrid } from '../../components/ReactMap/mapLayers';
import Loader from '../../components/Loader/Loader';
import useResourceEdit from '../../hooks/useResourceEdit';
import ResourceFormActions from '../../components/ResourceFormActions/ResourceFormActions';
import StoreForm from '../../components/forms/StoreForm/StoreForm';
import ReactMap from '../../components/ReactMap/ReactMap';

const { TextArea } = Input;

export default function SiteEditContainer({ id, handleCancel = () => {}, onSave = () => {} }) {
  const [state, attrs] = useResourceEdit({ path: '/sites', name: 'Site', id });
  const { loading, data = {} } = state;
  const { handleSubmit, setForm } = attrs;
  const { account_number, delivery_detail = {}, customer = {} } = data;
  const [latitude, setLatitude] = useState(40.0599152);
  const [longitude, setLongitude] = useState(-78.5138748);
  const [notes, setNotes] = useState();

  console.log("What is the data I'm dealing with?", data);

  const handleSave = () => {
    axios
      .patch(`/delivery_details/${delivery_detail.id}`, {
        latitude,
        longitude,
        notes,
        manually_geocoded: true
      })
      .then((res) => {
        message.success(`Site successfully updated`);
        onSave();
      });
  };

  const handleGeoLocation = () => {
    if ('geolocation' in navigator) {
      console.log('Available');
      message.info('Location Services are available for this application.');
      navigator.geolocation.getCurrentPosition(
        function(position) {
          message.info('Inside Geolocations');
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (err) => console.log(err),
        { enableHighAccuracy: true }
      );
    } else {
      console.log('Not Available');
      message.info('Location Services are disabled for this application.');
    }
  };

  useEffect(() => {
    if (delivery_detail) {
      const { latitude, longitude, notes } = delivery_detail;
      latitude && setLatitude(latitude);
      longitude && setLatitude(longitude);
      notes && setNotes(notes);
    }
  }, [delivery_detail]);

  const position = latitude && longitude ? [latitude, longitude] : [40.0599152, -78.5138748];

  if (loading) {
    return <Loader display={true} />;
  }

  return (
    <React.Fragment>
      <Body>
        <ReactMap height={400} center={position} zoom={19} defaultTile={googleHybrid}>
          <CustomSearchHandler
            position="topleft"
            provider="BingMap"
            providerOptions={{
              providerKey: 'Aoq1_lqEOomqubdzA-N9NZuBo-l4V2Syw4OsCBGZu3nt7jE93omrhGSffmegMv08'
            }}
            zoom={1}
            showMarker={false}
            closeResultsOnClick={true}
            inputPlaceholder="Search for a place..."
            callback={(latLng, info, raw) => {
              setLongitude(latLng.lng);
              setLatitude(latLng.lat);
            }}
          />

          <Marker
            position={position}
            draggable
            icon={DivIcon({ icon: 'fa-home' })}
            onMoveend={(e) => {
              const coords = e.target.getLatLng();
              setLatitude(coords.lat);
              setLongitude(coords.lng);
            }}
          />
        </ReactMap>
        {/* <StoreForm data={data} stores={stores} ref={(ref) => setForm(ref)} /> */}

        <Row style={{ marginTop: 22 }}>
          <Col span={8}>
            <h4>Customer</h4>
            <p>
              #{account_number}
              <br />
              {customer.first_name} {customer.last_name}
              <br />
              {delivery_detail && delivery_detail.address_line_1} <br />
              {delivery_detail && delivery_detail.address_line_2}
            </p>
          </Col>
          <Col span={16}>
            <h4>Notes</h4>
            <p>{delivery_detail && delivery_detail.special_instructions_1}</p>
            <p>{delivery_detail && delivery_detail.special_instructions_2}</p>
          </Col>
        </Row>

        <Row style={{ marginTop: 22 }}>
          <Form.Item label="Latitude">
            <Input
              placeholder="Latitude"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Longitude">
            <Input
              placeholder="Longitude"
              value={longitude}
              onChange={(e) => setLatitude(e.target.value)}
            />
          </Form.Item>

          <Button onClick={handleGeoLocation}>Use My Location</Button>

          <Form.Item label="Notes" style={{ marginTop: 20 }}>
            <TextArea
              rows={4}
              placeholder="Notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Form.Item>
        </Row>
        <ResourceFormActions id={id} handleCancel={handleCancel} handleSubmit={handleSave} />
      </Body>
    </React.Fragment>
  );
}
