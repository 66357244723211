import React from 'react';
import { Tag } from 'antd';

// https://flatuicolors.com/palette/se
export const COLORS = {
  highlighterPink: '#ef5777',
  darkPeriwinkle: '#575fcf',
  megaMan: '#4bcffa',
  freshTurquoise: '#34e7e4',
  mintyGreen: '#0be881',
  sizzlingRed: '#f53b57',
  freeSpeechBlue: '#3c40c6',
  spiroDiscoBall: '#0fbcf9',
  jadeDust: '#00d8d6',
  greenTeal: '#05c46b',
  narenjiOrange: '#ffc048',
  yrielYellow: '#ffdd59',
  sunsetOrange: '#ff5e57',
  hintOfElusiveBlue: '#d2dae2',
  goodNight: '#485460',
  chromeYellow: '#ffa801',
  vibrantYellow: '#ffd32a',
  redOrange: '#ff3f34',
  londonSquare: '#808e9b',
  blackPearl: '#1e272e'
};

export const getTicketStatusColor = (statusId) => {
  switch (statusId) {
    case 1:
    case 2:
      return '#fff';
    case 3:
    case 4:
      return '#00ff88';
    case 5:
    case 8:
      return '#ff0000';
    case 6:
      return '#fff700';
    default:
      return '#fff';
  }
};

export const getTicketStatusTag = (status_id) => {
  switch (status_id) {
    case 1:
    case 2:
      return <Tag color="blue">Open</Tag>;
    case 3:
      return <Tag color="green">Delivered</Tag>;
    case 4:
      return <Tag color="green">Posted</Tag>;
    case 5:
      return <Tag color="red">Ticket Removed</Tag>;
    case 6:
      return <Tag color="yellow">Active Delivery</Tag>;
    case 7:
      return <Tag color="red">Special</Tag>;
    case 8:
      return <Tag color="red">Undeliverable</Tag>;
    case 9:
      return <Tag color="yellow">Arrived</Tag>;
    case 10:
      return <Tag color="green">Dispatched In Simple</Tag>;
    default:
      return '';
  }
};
