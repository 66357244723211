import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Button, Popconfirm, Layout, Divider, message } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  CarOutlined,
  ProfileOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import PurchaseOrderItemEditContainer from '../PurchaseOrderItemEdit/PurchaseOrderItemEditContainer';
import styles from './EquipmentLeaseList.module.css';

const { Content } = Layout;

export default function EquipmentLeaseListContainer({ equipmentId, equipment = {} }) {
  let name = 'Lease History';
  const path = '/equipment_leases';
  const parentParams = {
    columnKey: 'start_at',
    order: 'descend',
    equipment_id: equipmentId,
    pageSize: 0
  };
  const [state, attrs] = useResourceList(path, name, parentParams);
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);

  const columns = [
    {
      visible: true,
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (value) => (value === true ? 'Yes' : 'No')
    },
    {
      title: 'Start Date',
      dataIndex: 'start_at',
      key: 'start_at',
      sorter: (a, b) => a.start_at - b.start_at,
      sortOrder: attrs.sortInfo.columnKey === 'start_at' && attrs.sortInfo.order,
      render: (data) => data && moment.unix(data).format('M/D/YYYY')
    },

    {
      title: 'End Date',
      dataIndex: 'end_at',
      key: 'end_at',
      sorter: (a, b) => a.end_at - b.end_at,
      sortOrder: attrs.sortInfo.columnKey === 'end_at' && attrs.sortInfo.order,
      render: (data) => data && moment.unix(data).format('M/D/YYYY')
    },
    {
      title: 'Customer',
      dataIndex: 'site',
      key: 'active_lease',
      render: (data) => {
        // Sometimes the customer data doesn't exist so I want to show customer number

        if (data && data.customer) {
          return (
            <>
              {data.customer.full_name}
              <small> ({data.customer.account_number})</small>
            </>
          );
        } else if (data && data.active) {
          return `Customer #${data.factor_customer_id}`;
        } else {
          return null;
        }
      }
    },
    {
      title: 'Lease Type',
      dataIndex: 'lease_type',
      key: 'lease_type',
      sorter: (a, b) => a.lease_type - b.lease_type,
      sortOrder: attrs.sortInfo.columnKey === 'lease_type' && attrs.sortInfo.order,
      render: (data) => data && data.title
    },
    {
      title: 'Rent Cycle',
      dataIndex: 'rent_cycle',
      key: 'rent_cycle',
      sorter: (a, b) => a.rent_cycle - b.rent_cycle,
      sortOrder: attrs.sortInfo.columnKey === 'rent_cycle' && attrs.sortInfo.order,
      render: (data) => data && data.title
    },
    {
      title: 'Rent Cycle Interval',
      dataIndex: 'rent_cycle_interval',
      key: 'rent_cycle_interval',
      sorter: (a, b) => a.rent_cycle_interval - b.rent_cycle_interval,
      sortOrder: attrs.sortInfo.columnKey === 'rent_cycle_interval' && attrs.sortInfo.order,
      render: (data) => data
    },
    {
      title: 'Rent Price',
      dataIndex: 'rent_price',
      key: 'rent_price',
      sorter: (a, b) => a.rent_price - b.rent_price,
      sortOrder: attrs.sortInfo.columnKey === 'rent_price' && attrs.sortInfo.order,
      render: (data) => !data !== null && Number(data).toFixed(2)
    },
    {
      visible: true,
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList();
  };

  const data = state.data
    ? state.data.map((item) => ({
        ...item,

        actions: (
          <div>
            <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
              <EditOutlined />
            </a>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={attrs.removeResource.bind(this, item.id)}
            >
              <a href="#!">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </div>
        )
      }))
    : [];

  return (
    <React.Fragment>
      <ResourceList data={data} hidePagination={true} {...attrs} />
    </React.Fragment>
  );
}
