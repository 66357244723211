import React, { useState, useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ResourceList from '../../components/ResourceList/ResourceList';
import useResourceList from '../../hooks/useResourceList';
import ResourceModal from '../../components/ResourceModal/ResourceModal';
import StoreEditContainer from '../StoreEdit/StoreEditContainer';

export default function StoreListContainer() {
  let name = 'Store';
  const path = '/stores';
  const [state, attrs] = useResourceList(path, name);
  const [resourceId, setResourceId] = useState(null); // used to track modal titles and id of item we are editing
  const [displayModal, setDisplayModal] = useState(false);

  const columns = [
    {
      title: 'Store',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: attrs.sortInfo.columnKey === 'id' && attrs.sortInfo.order
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: attrs.sortInfo.columnKey === 'name' && attrs.sortInfo.order
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => a.address.localeCompare(b.address),
      sortOrder: attrs.sortInfo.columnKey === 'address' && attrs.sortInfo.order
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      sorter: (a, b) => a.city.localeCompare(b.city),
      sortOrder: attrs.sortInfo.columnKey === 'city' && attrs.sortInfo.order
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      sorter: (a, b) => a.state.localeCompare(b.state),
      sortOrder: attrs.sortInfo.columnKey === 'state' && attrs.sortInfo.order
    },
    {
      title: 'Zip',
      dataIndex: 'zip',
      key: 'zip',
      sorter: (a, b) => a.zip - b.zip,
      sortOrder: attrs.sortInfo.columnKey === 'zip' && attrs.sortInfo.order
    },
    {
      width: 150,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  useEffect(() => {
    attrs.setColumns(columns);
  }, [attrs.sortInfo]);

  const toggleModal = (e, resourceId = null) => {
    e && e.preventDefault();
    setResourceId(resourceId);
    setDisplayModal((modal) => !modal);
  };

  const onModalSave = () => {
    toggleModal();
    attrs.fetchResourceList();
  };

  const data = state.data
    ? state.data.map((item) => ({
        ...item,
        actions: (
          <div>
            <a href="#!" onClick={toggleModal.bind(this, null, item.id)}>
              <EditOutlined />
            </a>
            <span style={{ opacity: 0.15, paddingLeft: 5, paddingRight: 5 }}>|</span>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={attrs.removeResource.bind(this, item.id)}
            >
              <a href="#!">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </div>
        )
      }))
    : [];

  return (
    <React.Fragment>
      <ResourceModal
        title={`${resourceId ? 'Edit' : 'Create'} ${name}`}
        visible={displayModal}
        toggle={toggleModal}
      >
        <StoreEditContainer handleCancel={toggleModal} id={resourceId} onSave={onModalSave} />
      </ResourceModal>

      <ResourceList
        data={data}
        extra={[
          <Button key="1" type="primary" onClick={toggleModal}>
            Create {name}
          </Button>
        ]}
        {...attrs}
      />
    </React.Fragment>
  );
}
