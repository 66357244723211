import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Divider, message } from 'antd';
import { CSVLink } from 'react-csv';
import Loader from '../../components/Loader/Loader';
import SalesSiteCommissionReportForm from '../../components/forms/SalesSiteCommissionReportForm/SalesSiteCommissionReportForm';

import styles from '../../components/ResourceFormActions/ResourceFormActions.module.css';

export default function SalesSiteEditContainer({ handleCancel = () => {}, onSave = () => {} }) {
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [csvData, setCSVData] = useState(null);

  const handleSubmit = (e, onSuccess = () => {}) => {
    form.getForm().validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        setLoading(true);
        e.preventDefault();
        const { type, salesman, start_at } = values;
        // Determine which endpoint

        axios
          .get(`/sales_sites/${type === 'fuel' ? 'fuel_commissions' : 'part_commissions'}`, {
            params: {
              salesman,
              start_at: start_at.map((date) => date.format('YYYY-MM-DD'))
            }
          })
          .then((res) => {
            setCSVData({
              filename: `${
                type == 'fuel' ? 'Fuel' : 'Parts'
              } Commission Report ${start_at[0].format('YYYY-MM-DD')} through ${start_at[1].format(
                'YYYY-MM-DD'
              )}`,
              data: res.data
            });
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            if (err.response && err.response.data && err.response.data.message) {
              message.error(err.response.data.message);
            } else {
              message.error(`An error occurred while generating the report.`);
            }
          });
      }
    });
  };

  if (loading) {
    return <Loader display={true} />;
  }

  if (csvData) {
    return (
      <React.Fragment>
        <CSVLink data={csvData.data} filename={csvData.filename} target="_blank">
          Download {csvData.filename}
        </CSVLink>
        <Divider />
        <div className={styles.actions}>
          <Button
            className={styles.buttonPadding}
            key="back"
            onClick={() => {
              setCSVData(null);
            }}
          >
            Reset
          </Button>
        </div>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <SalesSiteCommissionReportForm ref={(ref) => setForm(ref)} />
      <Divider />
      <div className={styles.actions}>
        <Button
          className={styles.buttonPadding}
          key="back"
          onClick={() => {
            handleCancel() && setCSVData(null);
          }}
        >
          Cancel
        </Button>
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Generate
        </Button>
      </div>
    </React.Fragment>
  );
}
